import { Fragment } from "react";
import { Helmet } from "react-helmet";
import { NavBarNew, Footer } from '../../components';
import {  BlogsComponent } from '../../NavLinksComponent';
import { blogsSEO } from '../../common';

function Blogs() {
  return (
    <Fragment>
      <Helmet>
        <meta name="description" content={blogsSEO.description} />
        <meta
          name="keywords"
          content={blogsSEO.keywords.join(", ")}
        />
      </Helmet>
      <div className='Blogs'>
      <NavBarNew />
      <BlogsComponent />
      <Footer />
    </div>

    </Fragment>
  );
}

export default Blogs;
