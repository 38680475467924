import React, { useEffect } from "react";
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';

import "./styles/not-found.css";

const NotFound = () => {
	useEffect(() => {
		document.title = '404 not found';
	}, []);

	return (
		<React.Fragment>
			<div className="not-found page-content">
				<div className="content-wrapper">
					<div className="notfound-container">
						<div className="notfound-message">
							<div className="notfound-title">
								Oops! <SentimentVeryDissatisfiedIcon className="sad-icon"/>
							</div>
							<div className="not-found-message">
								We can't seem to find the page you're looking
								for.
								<br />
								The requested URL "{window.location.href}" was
								not found on this server.
							</div>
							<a href={`${process.env.PUBLIC_URL}/`} className="not-found-link">
								Go back to the home page
							</a>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default NotFound;
