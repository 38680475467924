import React, { Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Button from "@mui/material/Button";
import { marathiTemplates, englishTemplates, hindiTemplates } from "../../common";

// Transliteration
import { CMarathi, CEnglish, CHindi } from '../../common';

import "./styles/change-template.css";

const importTransliterationData = (Language) => {
  switch (Language) {
    case 'Marathi':
      return CMarathi
    case 'English':
      return CEnglish
    case 'Hindi':
      return CHindi
    default:
      throw new Error(`Unsupported language: ${Language}`);
  }
};

const importTemplateList = (Language) => {
  switch (Language) {
    case 'Marathi':
      return marathiTemplates
    case 'English':
      return englishTemplates
    case 'Hindi':
      return hindiTemplates
    default:
      throw new Error(`Unsupported language: ${Language}`);
  }
};

function ChangeTemplateComponent() {
  const navigate = useNavigate();
  const location = useLocation();
  const { Language } = location.state || {};
  const language = Language.toLowerCase();

  const transliterationData = importTransliterationData(Language);
  const BiodataTemplates = importTemplateList(Language);

  const handleChangeDesign = (imageName) => {
    // Navigate to /biodata and pass image name in state
    navigate("/preview-biodata", {
      state: { templateImage: imageName, Language: Language },
    });
  };

  return (
    <Fragment>
      <div className="change-template">
        <div className="change-template-title container">
            {transliterationData.changeTempHeading}
          </div>
        <div className="biodata-template-list container">
          {BiodataTemplates.map((template) => (
            <div key={template.id} className="template">
              <div className="shadow">
                <picture>
                  <img
                    width="707"
                    height="1000"
                    className="img-fluid"
                    src={`./images/biodata_templates/${language}/${template.template_name}`}
                    alt="biodatawala"
                    loading="lazy"
                    id={template.id}
                    onClick={() => handleChangeDesign(template.id)}
                  />
                </picture>
              </div>
              <div className="pt-3 change-design-btn">
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handleChangeDesign(template.id)}
                >
                  { transliterationData.buttonText }
                </Button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Fragment>
  );
}

export default ChangeTemplateComponent;
