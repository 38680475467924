import { Fragment } from "react";
import { Helmet } from "react-helmet";
import { NavBarNew, Footer } from '../../components';
import {  ServicesComponent} from '../../NavLinksComponent';
import { servicesSEO } from '../../common';

function Services() {
  return (
    <Fragment>
      <Helmet>
        <meta name="description" content={servicesSEO.description} />
        <meta
          name="keywords"
          content={servicesSEO.keywords.join(", ")}
        />
      </Helmet>
      <div className='Services'>
        <NavBarNew />
        <ServicesComponent />
        <Footer />
      </div>
    </Fragment>
    
  );
}

export default Services;
