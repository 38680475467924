import React from 'react';
import "./styles/terms-and-conditions.css";

function RefundPolicyComponent() {

    return (
        <div className="components-wrapper">
            <section className="component-section">
                <div className="container tnc-container">
                    <div className="component-row gap">
                        <h2 className="heading-description">Refund Policy</h2>
                    </div>
                    <p className="about-para">
                        Thank you for using biodata generator, a free online service provided by Biodatawala.com to create marriage biodata in PDF format.
                    </p>
                    <p className="about-para">
                    Our service is offered at no cost to users. We do not charge any fees for creating or downloading biodata PDFs. As a token of appreciation, users may choose to send a voluntary tip through the provided QR code. These tips are entirely optional and are intended to support the ongoing development and maintenance of our free service.
                    </p>
                    <p className="about-para">
                        Given the nature of our service and the voluntary nature of any tips received, we do not offer refunds for tips sent. Tips are non-refundable and non-reversible.
                    </p>
                    <p className="about-para">
                        If you have any questions or concerns about this policy, please contact us at <a href="mailto:help@biodatawala.com"> help@biodatawala.com</a>
                    </p>
                    <p className="about-para">
                        Thank you for your understanding and support.
                    </p>
                </div>
            </section>
        </div>
    );
}

export default RefundPolicyComponent;