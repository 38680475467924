import React, { forwardRef, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./styles/biodata-view.css";
import { colorMap } from "./ColorMap";

function getColors(templateImage) {
  // Check if the key exists in the colorMap
  if (colorMap[templateImage]) {
    const headingHex = colorMap[templateImage].headingHex;
    const fontHex = colorMap[templateImage].fontHex;
    
    return { headingHex, fontHex };
  } else {
    // Return default if image doesn't exist.
    const headingHex = '#a02f28';
    const fontHex = '#000000';
    return { headingHex, fontHex };
  }
}

export default forwardRef(function BiodataCanvas({ templateData, ...props }, ref) {
  const navigate = useNavigate();
  const canvasRef = useRef(null);

  useEffect(() => {
    if (!templateData.current || !templateData.current.templateImage) {
      navigate("/");
    } else {
      const {
        heading: { iconGodPhoto, textGodName, titleText },
        personalDetails,
        horoscopeDetails,
        educationAndIncome,
        familyDetails,
        contactDetails,
        templateImage,
        userPhoto
      } = templateData.current;
      const { headingHex, fontHex } = getColors(templateImage);

      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");

      const loadImage = (src) => {
        return new Promise((resolve) => {
          if (!src) {
            resolve(null);
            return;
          }
          const img = new Image();
          img.onload = () => resolve(img);
          img.onerror = () => resolve(null);
          img.src = src;
        });
      };

      Promise.all([
        loadImage(`${process.env.PUBLIC_URL}/images/preview/${templateData.current.templateImage}.png`),
        loadImage(`${process.env.PUBLIC_URL}/images/godIcons/${iconGodPhoto}`),
        loadImage(userPhoto)
      ]).then(([bgImg, godImg, userImg]) => {
        canvas.width = bgImg.naturalWidth;
        canvas.height = bgImg.naturalHeight;
        ctx.drawImage(bgImg, 0, 0);

        if (godImg) {
          var aspectRatio = godImg.width / godImg.height;
          var desiredWidth = textGodName ? 90 : 100;
          var godTopMargin = textGodName ? 100 : 120;
          var scaledHeight = desiredWidth / aspectRatio;
          var roundedWidth = Math.round(desiredWidth);
          var roundedHeight = Math.round(scaledHeight);
          var posX = (canvas.width - roundedWidth) / 2;
          ctx.drawImage(godImg, posX, godTopMargin, roundedWidth, roundedHeight);
        }

        if (userImg) {
          ctx.drawImage(userImg, 650, 370, 209, 280);
          ctx.strokeStyle = headingHex;
          ctx.lineWidth = 4;
          ctx.strokeRect(649, 369, 212, 283);
        }

        const headingDetails = [textGodName, titleText];
        ctx.fillStyle = headingHex;
        ctx.font = 'bold 22px Poppins';
        let startY = 0
        if (godImg) {
          startY += 245;
        } else {
          startY += 145;
        }
        
        const lineHeight = 45;

        headingDetails.forEach((line, index) => {
          const textWidth = ctx.measureText(line).width;
          const centerX = (canvas.width / 2) - (textWidth / 2);
          startY += (index * lineHeight)
          ctx.fillText(line, centerX, startY);
        });

        const wrapText = (text, x, y, maxWidth, lineHeight) => {
          const words = text.split(' ');
          let line = '';
          let lines = [];
          words.forEach(word => {
            const testLine = line + word + ' ';
            const testWidth = ctx.measureText(testLine).width;
            if (testWidth > maxWidth && line !== '') {
              lines.push(line);
              line = word + ' ';
            } else {
              line = testLine;
            }
          });
          lines.push(line);
          lines.forEach((line, index) => {
            ctx.fillText(line, x, y + index * lineHeight);
          });
          return lines.length;
        };

        const drawTextBlock = (key, value, startY) => {
          if (key && value) {
            ctx.font = "650 16px 'Poppins'";
            ctx.fillStyle = fontHex;
            ctx.fillText(`${key} `, 210, startY);
            ctx.fillText(':', 400, startY);

            const maxWidth = canvas.width - 650;
            const lines = wrapText(value, 430, startY, maxWidth, 20);

            return lines;
          }
          return 0;
        };

        const countDetailsLines = (details) => {
          let lineCount = 0;
          Object.keys(details).forEach(key => {
            if (key.includes('Key') && details[`${key.split('Key')[0]}Value`]) {
              const value = details[`${key.split('Key')[0]}Value`];
              const textWidth = ctx.measureText(value).width;
              const lines = Math.ceil(textWidth / (canvas.width - 650));
              lineCount += lines;
            }
          });
          return lineCount;
        };

        const hasValues = (obj) => {
          return Object.keys(obj).some(key => key.endsWith('Value') && obj[key] !== null && obj[key] !== undefined && obj[key] !== '');
        };

        const totalLines = countDetailsLines(personalDetails) +
          countDetailsLines(horoscopeDetails) +
          countDetailsLines(educationAndIncome) +
          countDetailsLines(familyDetails) +
          countDetailsLines(contactDetails);

        let availableHeight;
        let imgAbsent;
        if (godImg){
          imgAbsent=0;
        } else {
          imgAbsent=100;
        }
        if (!hasValues(familyDetails)) {
          availableHeight = canvas.height - 455;
        } else {
          availableHeight = canvas.height - 525+imgAbsent;
        }

        const lineHeightDynamic = availableHeight / (totalLines + 1);

        let yOffset = startY+ 60;

        const drawDetailsWithDynamicSpacing = (details) => {
          Object.keys(details).forEach((key) => {
            if (key.includes('Key')) {
              let entity = key.split('Key')[0];
              if (entity === "dob") {
                let day = details[`${entity}Day`];
                let month = details[`${entity}Month`];
                let year = details[`${entity}Year`];
                let dob_val = `${day}  ${month}  ${year}`;
                const lines = drawTextBlock(details[`${key}`], dob_val, yOffset);
                yOffset += lineHeightDynamic * lines;
              } else {
                const lines = drawTextBlock(details[`${key}`], details[`${entity}Value`], yOffset);
                yOffset += lineHeightDynamic * lines;
              }
            }
          });
        };

        drawDetailsWithDynamicSpacing(personalDetails);
        drawDetailsWithDynamicSpacing(horoscopeDetails);
        drawDetailsWithDynamicSpacing(educationAndIncome);

        if (hasValues(familyDetails)) {
          ctx.font = "bold 22px 'Poppins'";
          ctx.fillStyle = headingHex;
          if (familyDetails.familyDetailHeading) {
            const textWidth = ctx.measureText(familyDetails.familyDetailHeading).width;
            const centerX = (canvas.width / 2) - (textWidth / 2);
            yOffset += 15;
            ctx.fillText(familyDetails.familyDetailHeading, centerX, yOffset);
            yOffset += 40;
          }
          drawDetailsWithDynamicSpacing(familyDetails);
          yOffset += lineHeightDynamic * 0.5;
        }

        if (contactDetails.mobileNoValue || contactDetails.addressValue || contactDetails.addressLine2Value) {
          ctx.font = "bold 22px 'Poppins'";
          ctx.fillStyle = headingHex;
          if (contactDetails.contactHeading) {
            const textWidth = ctx.measureText(contactDetails.contactHeading).width;
            const centerX = (canvas.width / 2) - (textWidth / 2);
            ctx.fillText(contactDetails.contactHeading, centerX, yOffset);
            yOffset += 35;
          }

          const drawContactDetails = (details) => {
            let startY = yOffset;
            Object.keys(details).forEach((key) => {
              if (key.includes('Key')) {
                let entity = key.split('Key')[0];
                if (details[`${entity}Value`]) {
                  ctx.font = "650 17px 'Poppins'";
                  ctx.fillStyle = fontHex;
                  const text = `${details[`${key}`]}: ${details[`${entity}Value`]}`;
                  const textWidth = ctx.measureText(text).width;
                  const centerX = (canvas.width / 2) - (textWidth / 2);
                  const lines = wrapText(text, centerX, startY, canvas.width - 100, lineHeightDynamic);
                  startY += lineHeightDynamic * lines;
                }
              }
            });
          };

          drawContactDetails(contactDetails);
        }
      }).catch((error) => {
        console.error("Failed to load images", error);
      });
    }
  }, [navigate, templateData]);

  return <canvas ref={canvasRef} width={1061} height={1500}></canvas>;
});
