import React from 'react';
import { GitHub as GitHubIcon , X as XIcon, Instagram as InstagramIcon, LinkedIn as LinkedInIcon, YouTube as YouTubeIcon } from '@mui/icons-material';
import "./styles/founder-component.css";


function FounderComponent() {
    return (
        <div className="founder-wrapper">
            <section className="founder-section">
                <div className="container">
                    <div className="component-row gap">
                        <div className="textImgCol col">
                            <div className="about-clip-art">
                                <picture>
                                    <div className="founder-image-wrapper">
                                        <img
                                            src="vaibhav.jpg"
                                            alt="Vaibhav"
                                            className="about-image"
                                        />
                                    </div>
                                </picture>
                            </div>
                        </div>
                        <div className="textImgCol col">
                            <h1 className="page-title">Founder</h1>
                            <h2 className="about-heading-description">
                                Vaibhav Wagaskar
                            </h2>
                            <p className="about-para"> I've worked on a variety of projects over the 
                                3 years and I'm proud of the progress I've made. Many of my projects
                                are open-source and available for others to explore, learn and 
                                contribute to. If you're interested in any of the projects I've 
                                worked on, please feel free to check out the code and suggest any 
                                improvements or enhancements you might have in mind. Collaborating 
                                with others is a great way to learn and grow, and I'm always open to 
                                new ideas and feedback.
                            </p>
                            <div className="social-icons d-flex pt-2">
                                <a className="btn btn-square rounded-circle d-flex align-items-center justify-content-center me-2" href="https://www.linkedin.com/in/vaibhavwagaskar4/" style={{ width: '50px', height: '50px' }} target='_blank' rel="noreferrer"><LinkedInIcon /></a>
                                <a className="btn btn-square rounded-circle d-flex align-items-center justify-content-center me-2" href="https://www.youtube.com/@aifunction9325" style={{ width: '35px', height: '50px' }} target='_blank' rel="noreferrer"><YouTubeIcon /></a>
                                <a className="btn btn-square rounded-circle d-flex align-items-center justify-content-center me-2" href="https://x.com/vsw_04/" style={{ width: '35px', height: '50px' }} target='_blank' rel="noreferrer"><XIcon /></a>
                                <a className="btn btn-square rounded-circle d-flex align-items-center justify-content-center me-2" href="https://www.instagram.com/vsw_04/" style={{ width: '50px', height: '50px' }} target='_blank' rel="noreferrer"><InstagramIcon /></a>
                                <a className="btn btn-square rounded-circle d-flex align-items-center justify-content-center me-2" href="https://www.github.com/cartosat" style={{ width: '50px', height: '50px' }} target='_blank' rel="noreferrer"><GitHubIcon /></a>
                                
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </div>
    );
}

export default FounderComponent;