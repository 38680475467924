import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import GTranslateIcon from '@mui/icons-material/GTranslate';
import { useLocation  } from 'react-router-dom';
import { BodyMarathi, BodyHindi, BodyEnglish } from '../../common';
import { Button } from '@mui/material';

import './styles/body.css'; 
import TranslationComponent from '../../components/TranslationComponent';

const importTransliterationData = (Language) => {
    switch (Language) {
      case 'Marathi':
        return BodyMarathi
      case 'Hindi':
        return BodyHindi
      case 'English':
        return BodyEnglish;
      default:
        throw new Error(`Unsupported language: ${Language}`);
    }
};

  
const Body = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const location = useLocation();
    const { templateImage, Language } = location.state || {};
    const transliterationData = importTransliterationData(Language)

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    return (
        <div className="body-sm">
            <div className="container text-center pt-5 pb-2">
                <div className="fs-6 txt pb-3">
                    { transliterationData.transliterationHeading1 }
                    <br/>{ transliterationData.transliterationHeading2 }
                </div>
            </div>
            {Language && Language !== 'English' && (
                <div className="container text-center pt-1 pb-4 d-none d-lg-block">
                    <div className="fs-6 txt pb-3">{transliterationData.transliterationHeading4}</div>
                    <Button 
                        variant="contained"
                        onClick={openModal}
                        className="btn btn-sm btn-outline-secondary rounded-pill"
                    >
                        {transliterationData.transliterationButtonText} <GTranslateIcon sx={{ marginLeft: '5px' }} />
                    </Button>
                    <Modal
                        open={modalIsOpen}
                        onClose={closeModal}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        hideBackdrop={true}
                        style={{
                            top: '5.5rem',
                            left: '47rem',
                            position: 'fixed',
                            width: '504px',
                            height: 'fit-content',
                            backgroundColor: 'white',
                            border: '1px solid #000',
                            borderRadius: '8px',
                            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                        }}
                    >
                        <TranslationComponent onClose={closeModal} transliterationData={ transliterationData } />
                    </Modal>
                </div>
            )}
        </div>
    );
};

export default Body;
