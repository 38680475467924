
export const PMarathi = {
    mrpText: {
        mrpTextL1: "वॉटरमार्कशिवाय बायोडाटा डाउनलोड करा",
        mrpTextL2: "किंमत ₹ 0/- रुपये.",
        mrpTextL3: "खालील बटन दाबल्यास बायोडाटाची PDF डाउनलोड होईल",

    },
    upiDonation: {
        headingText: "तुम्हाला बायोडाटा आवडला असेल तर...",
        tipText: "आम्हाला भेटीच्या स्वरूपात ₹10, ₹20, ₹50, ₹100 रुपये खालील QR वर पाठवू शकता."
    },
    buttonText: {
        downloadText: "डाउनलोड करा",
        shareLinkText: "शेअर करा",
        generatingText: "तयार होत आहे",
        editButtonText: "तपशील एडिट करा",
        changeTemplateText: "टेम्पलेट बदला"
    }
}
export const PEnglish = {
    mrpText: {
        mrpTextL1: "Download biodata without watermark",
        mrpTextL2: "Price ₹0/-",
        mrpTextL3: "Press the button below to download the PDF of the biodata",

    },
    upiDonation: {
        headingText: "If you liked the biodata...",
        tipText: "You can send ₹10, ₹20, ₹50, ₹100 as a token of appreciation using the QR code below."
    },
    buttonText: {
        downloadText: "DOWNLOAD",
        shareLinkText: "Share",
        generatingText: "Generating...",
        editButtonText: "EDIT DETAILS",
        changeTemplateText: "CHANGE TEMPLATE"
    }
}
export const PHindi = {
    mrpText: {
        mrpTextL1: "वॉटरमार्क के बिना बायोडाटा डाउनलोड करें",
        mrpTextL2: "कीमत ₹ 0/-",
        mrpTextL3: "नीचे दिए गए बटन को दबाने पर बायोडाटा की PDF डाउनलोड हो जाएगी"
    },
    upiDonation: {
        headingText: "यदि आपको बायोडाटा पसंद आया हो तो...",
        tipText: "आप ₹10, ₹20, ₹50, ₹100 की राशि उपहार के रूप में नीचे दिए गए QR कोड पर भेज सकते हैं।"
    },
    buttonText: {
        downloadText: "डाउनलोड करें",
        shareLinkText: "शेअर करें",
        generatingText: "तैयार हो रहा है",
        editButtonText: "विवरण एडिट करें",
        changeTemplateText: "टेम्पलेट बदलें"
    }
}

export const CMarathi = {
    buttonText: "डिझाइन वापरा",
    changeTempHeading: "खालील उपलब्ध पर्यायांमधून तुम्हाला आवडणारा कोणताही टेम्पलेट निवडा."
}

export const CEnglish = {
    buttonText: "Use Design",
    changeTempHeading: "Select any template you like from the available options below."
}

export const CHindi = {
    buttonText: "डिज़ाइन चुनें",
    changeTempHeading: "नीचे दिए गए विकल्पों में से अपनी पसंद का कोई भी टेम्पलेट चुनें।"
}