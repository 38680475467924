import React, { useState, forwardRef, useImperativeHandle, useCallback } from "react";

const FamilyData =  forwardRef(({ templateData, transliterationData }, ref) => {
    const [familyDetails, setFamilyDetails] = useState({
        ...templateData.current.familyDetails,
    });

    // The component instance will be extended
    // with whatever you return from the callback passed
    // as the second argument
    useImperativeHandle(ref, () => ({
        set(familyDetails) {
            setFamilyDetails(familyDetails);
        },

        reset(familyDetails) {
            setFamilyDetails(familyDetails);
        },
    }));

    const handleChange = useCallback(
        (property) => (event) => {
            const value = event.target.value;

            templateData.current.familyDetails[property] = value;
            setFamilyDetails((prevState) => ({ ...prevState, [property]: value }));
        },
        [templateData]
    );

    return (
        <React.Fragment>
            <div className="row pt-3">
                <div className="col-md-4 col-0"></div>
                <div className="col-md-4 col-12">
                    <select
                        className="form-select text-center mt-2 fw-bold"
                        aria-label="heading 2"
                        name="hh3"
                        id="ihh3"
                        onChange={handleChange("familyDetailHeading")}
                    >
                        {transliterationData.familyDetailHeadingOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="col-md-4 col-0"></div>
            </div>
            <div className="row pt-3">
                <div className="col-md-6 mb-4">
                    <input
                        className="mycss3"
                        name="ht21"
                        id="iht21"
                        placeholder={transliterationData.additionalKeyPlaceHolder}
                        maxLength="16"
                        value={familyDetails.fatherNameKey}
                        onChange={handleChange("fatherNameKey")}
                    />
                    <input
                        className="form-control"
                        name="hd21"
                        id="ihd21"
                        placeholder={transliterationData.fatherNameValuePlaceHolder}
                        maxLength="46"
                        value={familyDetails.fatherNameValue}
                        onChange={handleChange("fatherNameValue")}
                    />
                </div>
                <div className="col-md-6 mb-4">
                    <input
                        className="mycss3"
                        name="ht22"
                        id="iht22"
                        placeholder={transliterationData.additionalKeyPlaceHolder}
                        maxLength="16"
                        value={familyDetails.fatherOccupationKey}
                        onChange={handleChange("fatherOccupationKey")}
                    />
                    <input
                        className="form-control"
                        name="hd22"
                        id="ihd22"
                        placeholder={transliterationData.fatherOccupationValuePlaceHolder}
                        maxLength="46"
                        value={familyDetails.fatherOccupationValue}
                        onChange={handleChange("fatherOccupationValue")}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 mb-4">
                    <input
                        className="mycss3"
                        name="ht23"
                        id="iht23"
                        placeholder={transliterationData.additionalKeyPlaceHolder}
                        maxLength="16"
                        value={familyDetails.motherNameKey}
                        onChange={handleChange("motherNameKey")}
                    />
                    <input
                        className="form-control"
                        name="hd23"
                        id="ihd23"
                        placeholder={transliterationData.motherNameValuePlaceHolder}
                        maxLength="46"
                        value={familyDetails.motherNameValue}
                        onChange={handleChange("motherNameValue")}
                    />
                </div>
                <div className="col-md-6 mb-4">
                    <input
                        className="mycss3"
                        name="ht24"
                        id="iht24"
                        placeholder={transliterationData.additionalKeyPlaceHolder}
                        maxLength="16"
                        value={familyDetails.noSisterKey}
                        onChange={handleChange("noSisterKey")}
                    />
                    <input
                        className="form-control"
                        name="hd24"
                        id="ihd24"
                        placeholder={transliterationData.noSisterValuePlaceHolder}
                        maxLength="46"
                        value={familyDetails.noSisterValue}
                        onChange={handleChange("noSisterValue")}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 mb-4">
                    <input
                        className="mycss3"
                        name="ht25"
                        id="iht25"
                        placeholder={transliterationData.additionalKeyPlaceHolder}
                        maxLength="16"
                        value={familyDetails.noBrotherKey}
                        onChange={handleChange("noBrotherKey")}
                    />
                    <input
                        className="form-control"
                        name="hd25"
                        id="ihd25"
                        placeholder={transliterationData.noBrotherValuePlaceHolder}
                        maxLength="46"
                        value={familyDetails.noBrotherValue}
                        onChange={handleChange("noBrotherValue")}
                    />
                </div>
                <div className="col-md-6 mb-4">
                    <input
                        className="mycss3"
                        name="ht26"
                        id="iht26"
                        placeholder={transliterationData.additionalKeyPlaceHolder}
                        maxLength="16"
                        value={familyDetails.maternalUncleKey}
                        onChange={handleChange("maternalUncleKey")}
                    />
                    <input
                        className="form-control"
                        name="hd26"
                        id="ihd26"
                        placeholder={transliterationData.maternalUncleValuePlaceHolder}
                        maxLength="46"
                        value={familyDetails.maternalUncleValue}
                        onChange={handleChange("maternalUncleValue")}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 mb-4">
                    <input
                        className="mycss3"
                        name="ht27"
                        id="iht27"
                        placeholder={transliterationData.additionalKeyPlaceHolder}
                        maxLength="16"
                        value={familyDetails.relativesNamesKey}
                        onChange={handleChange("relativesNamesKey")}
                    />
                    <input
                        className="form-control"
                        name="hd27"
                        id="ihd27"
                        placeholder={transliterationData.relativesNamesValuePlaceHolder}
                        maxLength="100"
                        value={familyDetails.relativesNamesValue}
                        onChange={handleChange("relativesNamesValue")}
                    />
                </div>
                <div className="col-md-6 mb-4">
                    <input
                        className="mycss3"
                        name="ht28"
                        id="iht28"
                        placeholder={transliterationData.additionalKeyPlaceHolder}
                        maxLength="16"
                        value={familyDetails.extraHeading2Key}
                        onChange={handleChange("extraHeading2Key")}

                    />
                    <input
                        className="form-control"
                        name="hd28"
                        id="ihd28"
                        maxLength="46"
                        value={familyDetails.extraHeading2Value}
                        onChange={handleChange("extraHeading2Value")}
                    />
                </div>
            </div>
            {/*  */}
            <div className="row">
                <div className="col-md-6 mb-4">
                    <input
                        className="mycss3"
                        name="ht29"
                        id="iht29"
                        placeholder={transliterationData.additionalKeyPlaceHolder}
                        maxLength="16"
                        value={familyDetails.extraHeading3Key}
                        onChange={handleChange("extraHeading3Key")}

                    />
                    <input
                        className="form-control"
                        name="hd29"
                        id="ihd29"
                        maxLength="46"
                        value={familyDetails.extraHeading3Value}
                        onChange={handleChange("extraHeading3Value")}
                    />
                </div>
                <div className="col-md-6 mb-4">
                    <input
                        className="mycss3"
                        name="ht30"
                        id="iht30"
                        placeholder={transliterationData.additionalKeyPlaceHolder}
                        maxLength="16"
                        value={familyDetails.extraHeading4Key}
                        onChange={handleChange("extraHeading4Key")}

                    />
                    <input
                        className="form-control"
                        name="hd30"
                        id="ihd30"
                        maxLength="46"
                        value={familyDetails.extraHeading4Value}
                        onChange={handleChange("extraHeading4Value")}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 mb-4">
                    <input
                        className="mycss3"
                        name="ht31"
                        id="iht31"
                        placeholder={transliterationData.additionalKeyPlaceHolder}
                        maxLength="16"
                        value={familyDetails.extraHeading5Key}
                        onChange={handleChange("extraHeading5Key")}

                    />
                    <input
                        className="form-control"
                        name="hd31"
                        id="ihd31"
                        maxLength="46"
                        value={familyDetails.extraHeading5Value}
                        onChange={handleChange("extraHeading5Value")}
                    />
                </div>
                <div className="col-md-6 mb-4">
                    <input
                        className="mycss3"
                        name="ht32"
                        id="iht32"
                        placeholder={transliterationData.additionalKeyPlaceHolder}
                        maxLength="16"
                        value={familyDetails.expectationsKey}
                        onChange={handleChange("expectationsKey")}
                    />
                    <input
                        className="form-control"
                        name="hd32"
                        id="ihd32"
                        maxLength="46"
                        value={familyDetails.expectationsValue}
                        onChange={handleChange("expectationsValue")}
                    />
                </div>
            </div>

        </React.Fragment>
    );
});
export default FamilyData;