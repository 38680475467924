import React from 'react';
import "./styles/terms-and-conditions.css";

function ShippingDeliveryComponent() {

    return (
        <div className="components-wrapper">
            <section className="component-section">
                <div className="container tnc-container">
                    <div className="component-row gap">
                        <h2 className="heading-description">Shipping and Delivery</h2>
                    </div>
                    <p className="about-para">
                        Thank you for using biodata generator, a free online service provided by Biodatawala.com to create marriage biodata in PDF format.
                    </p>
                    <p className="about-para">
                        All content provided by Biodatawala is digital. We do not ship any physical copies of the biodata PDFs, nor are we associated with any type of physical delivery services. Once you have completed creating your biodata, it will be available for download directly from our website.                    </p>
                    <p className="about-para">
                        As our service is entirely digital, there are no shipping fees or delivery times to consider. You will have immediate access to your biodata PDF upon completion.                    </p>
                    <p className="about-para">
                        If you experience any issues accessing your digital biodata, please contact us at <a href="mailto:help@biodatawala.com"> help@biodatawala.com</a> and we will be happy to assist you.
                    </p>
                    <p className="about-para">
                        Thank you for your understanding and support.
                    </p>
                </div>
            </section>
        </div>
    );
}

export default ShippingDeliveryComponent;