import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import BlogComponent from "./BlogComponent";

import "./styles/blogs-list.css";

const BlogsList = forwardRef((props, ref) => {
  const targetRef = useRef();

  useImperativeHandle(ref, () => ({
    scrollTo: () => {
      if (targetRef.current) {
        targetRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }));

  return (
    <div className="blogs-list">
      <h2 ref={targetRef} className="blog-h2"> Blogs</h2>
      <BlogComponent/>
    </div>
  );
});
export default BlogsList;
