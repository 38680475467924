import React, { useState, useEffect, useRef } from "react";
import "./styles/translation-component.css";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ClearIcon from '@mui/icons-material/Clear';
import CloseIcon from '@mui/icons-material/Close';

async function fetchSuggestions(text, languageCode) {
    const apiUrl = "https://inputtools.google.com/request";
    const requestUrl = `${apiUrl}?text=${encodeURIComponent(
        text
    )}&itc=${languageCode}-t-i0-und&num=5&cp=1&cs=1&ie=utf-8&oe=utf-8`;

    try {
        const response = await fetch(requestUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify([text, `${languageCode}-t-i0-und`, "5"]), // Fetching up to 5 suggestions
        });

        const data = await response.json();

        // Process the response
        if (data && data[0] === "SUCCESS") {
            const suggestions = data[1][0][1];
            return suggestions; // Return array of suggestions
        } else {
            throw new Error(
                "Translation not found or API response format unexpected."
            );
        }
    } catch (error) {
        console.error("Error fetching suggestions:", error);
        return []; // Handle error gracefully
    }
}

const TranslationComponent = ({ onClose, transliterationData }) => {
    const LangCode = transliterationData.languageCode;
    const [inputText, setInputText] = useState("");
    const [parentText, setParentText] = useState("");
    const [suggestions, setSuggestions] = useState([]);
    const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(-1);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [showInputBox, setShowInputBox] = useState(false);
    const [copySuccess, setCopySuccess] = useState(false); // State to manage copy success notification
    const parentInputRef = useRef(null);
    const suggestionsRef = useRef(null);

    const allowedKeysRegex = /^[a-zA-Z0-9]$/;

    const handleParentTextChange = (e) => {
        const keyPressed = e.key;

        // Check if the pressed key is a single character (alphabet or digit)
        if (allowedKeysRegex.test(keyPressed)) {
            // Check if any of the special keys are pressed in combination (Ctrl, Shift, Alt, Meta)
            if (!(e.ctrlKey || e.altKey || e.metaKey)) {
                setShowInputBox(true);
            }
        } else {
            setShowInputBox(false);
        }
    };

    const handleInputChange = async (e) => {
        const text = e.target.value;
        setInputText(text);

        // Check if any of the special keys are pressed
        if (e.ctrlKey || e.altKey || e.metaKey) {
            return; // Do not proceed if any of these keys are pressed
        }

        if (text.trim()) {
            try {
                const fetchedSuggestions = await fetchSuggestions(text, LangCode);
                setSuggestions(fetchedSuggestions); // Set all suggestions
                setShowSuggestions(true);
                setSelectedSuggestionIndex(0); // Reset the selected index
            } catch (error) {
                console.error("Translation error:", error);
                setSuggestions([]);
                setShowSuggestions(false);
            }
        } else {
            setSuggestions([]);
            setShowSuggestions(false);
        }
    };

    const handleSuggestionClick = (suggestion) => {
        setParentText((prevText) => prevText + suggestion + " ");
        setInputText("");
        setSuggestions([]);
        setShowSuggestions(false); // Hide suggestions after click
        setShowInputBox(false);
        if (parentInputRef.current) {
            parentInputRef.current.focus();
        }
    };

    const handleKeyDown = (e) => {
        if (showSuggestions) {
            if (e.key === "ArrowDown") {
                e.preventDefault();
                setSelectedSuggestionIndex((prevIndex) =>
                    Math.min(prevIndex + 1, suggestions.length - 1)
                );
            } else if (e.key === "ArrowUp") {
                e.preventDefault();
                setSelectedSuggestionIndex((prevIndex) => Math.max(prevIndex - 1, 0));
            } else if (e.key === "Enter") {
                e.preventDefault();
                if (selectedSuggestionIndex === -1 && suggestions.length > 0) {
                    handleSuggestionClick(suggestions[0]); // Pick the first suggestion by default
                } else if (selectedSuggestionIndex >= 0) {
                    handleSuggestionClick(suggestions[selectedSuggestionIndex]);
                }
            } else if (e.key === " ") {
                // Handle spacebar press
                if (suggestions.length > 0) {
                    e.preventDefault(); // Prevent default spacebar behavior (moving page down)
                    setParentText((prevText) => prevText + suggestions[0] + " ");
                    setInputText("");
                    setSuggestions([]);
                    setShowSuggestions(false);
                    setShowInputBox(false); // Close the child input box
                    if (parentInputRef.current) {
                        parentInputRef.current.focus(); // Focus back on parent input
                    }
                }
            }
        }
    };

    const handleCopyText = () => {
        navigator.clipboard.writeText(parentText);
        setCopySuccess(true);

        // Automatically hide copy success message after 3 seconds
        setTimeout(() => {
            setCopySuccess(false);
        }, 3000);
    };

    const handleClearText = () => {
        setParentText("");
    };

    useEffect(() => {
        if (selectedSuggestionIndex >= 0 && suggestionsRef.current) {
            suggestionsRef.current.children[selectedSuggestionIndex].scrollIntoView({
                behavior: "smooth",
                block: "nearest",
            });
        }
    }, [selectedSuggestionIndex]);

    useEffect(() => {
        if (!showInputBox && parentInputRef.current) {
            parentInputRef.current.focus();
        }
    }, [showInputBox]);

    return (
        <div className="translator-container">
            <CloseIcon 
                onClick={onClose}
                className="close-btn"
            />
            <h2>Indic Typing</h2>

            <input
                className="parent-text-box"
                ref={parentInputRef}
                value={parentText}
                onChange={(e) => setParentText(e.target.value)}
                onKeyDown={handleParentTextChange}
                placeholder={ transliterationData.modalText.textboxPlaceHolder }
            />

            {showInputBox && (
                <div className="input-suggestions">
                    <input
                        className="suggestion-input"
                        type="input"
                        value={inputText}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        placeholder="Enter text in English"
                        autoFocus
                    />
                    <div className="suggestion-list" ref={suggestionsRef}>
                        {showSuggestions &&
                            suggestions.map((suggestion, index) => (
                                <div
                                    key={index}
                                    id={`suggestion${index}`}
                                    className={`suggestion-div${index === selectedSuggestionIndex ? " active" : ""
                                        }`}
                                    onClick={() => handleSuggestionClick(suggestion)}
                                >
                                    {suggestion}
                                </div>
                            ))}
                    </div>
                    
                    <div className="loading-box hidden">
                        <div className="lds-ellipsis">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </div>
            )}

            <div className="button-container">
                <button className="btn btn-outline-secondary rounded-pill" onClick={handleCopyText}>
                    <ContentCopyIcon sx={{ width: '18px', height: '18px'}} /> { transliterationData.modalText.copyButtonText }
                </button>
                <button className="btn btn-outline-secondary rounded-pill" onClick={handleClearText}>
                    <ClearIcon sx={{ width: '18px', height: '18px'}} />  { transliterationData.modalText.clearButtonText }
                </button>
            </div>
            
            {copySuccess && (
                <div className="copy-success-message">
                    { transliterationData.modalText.textCopied }
                </div>
            )}
        </div>
    );
};

export default TranslationComponent;
