import React, { useState, forwardRef, useImperativeHandle, useCallback } from "react";

export default forwardRef(function HoroscopeData({ templateData, transliterationData}, ref) {
    const [horoscopeDetails, setHoroscopeDetails] = useState({
        ...templateData.current.horoscopeDetails,
    });

    // The component instance will be extended
    // with whatever you return from the callback passed
    // as the second argument
    useImperativeHandle(ref, () => ({
        set(horoscopeDetails) {
            setHoroscopeDetails(horoscopeDetails);
        },

        reset(horoscopeDetails) {
            setHoroscopeDetails(horoscopeDetails);
        },
    }));

    const handleChange = useCallback(
        (property) => (event) => {
            const value = event.target.value;

            templateData.current.horoscopeDetails[property] = value;
            setHoroscopeDetails((prevState) => ({ ...prevState, [property]: value }));
        },
        [templateData]
    );

    return (
        <React.Fragment>
            {/* Raashi, Gun, Naadi, Unchi, Varn, Raktgat */}
            <div className="row">
                <div className="col-md-3 mb-4">
                    <select
                        className="form-select mycss"
                        aria-label="navras nav"
                        name="ht5"
                        id="iht5"
                        value={horoscopeDetails.signNameKey}
                        onChange={handleChange("signNameKey")}
                    >
                        {transliterationData.signNameKeyOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                    <input
                        className="form-control"
                        name="hd5"
                        id="ihd5"
                        placeholder={transliterationData.signNameValuePlaceHolder}
                        maxLength="29"
                        value={horoscopeDetails.signNameValue}
                        onChange={handleChange("signNameValue")}
                    />
                </div>

                <div className="col-md-3 mb-4">
                    <select
                        className="form-select mycss"
                        aria-label="jat"
                        name="ht6"
                        id="iht6"
                        value={horoscopeDetails.religionCasteKey}
                        onChange={handleChange("religionCasteKey")}
                    >
                        {transliterationData.religionCasteKeyOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                    <input
                        className="form-control"
                        name="hd6"
                        id="ihd6"
                        placeholder={transliterationData.religionCasteValuePlaceHolder}
                        maxLength="29"
                        value={horoscopeDetails.religionCasteValue}
                        onChange={handleChange("religionCasteValue")}
                    />
                </div>
                <div className="col-md-2 mb-4">
                    <label className="form-label">{horoscopeDetails.signKey}</label>
                    <select
                        className="form-select"
                        aria-label="rashi"
                        name="hd11"
                        id="ihd11"
                        value={horoscopeDetails.signValue}
                        onChange={handleChange("signValue")}
                    >
                        <option value="">&nbsp;</option>
                        {transliterationData.signValueOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="col-md-2 mb-4">
                    <label className="form-label">{horoscopeDetails.ganKey}</label>
                    <select
                        className="form-select"
                        aria-label="gan"
                        name="hd12"
                        id="ihd12"
                        value={horoscopeDetails.ganValue}
                        onChange={handleChange("ganValue")}
                    >
                        <option value="">&nbsp;</option>
                        {transliterationData.ganValueOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="col-md-2 mb-4">
                    <label className="form-label">{horoscopeDetails.naadiKey}</label>
                    <select
                        className="form-select"
                        aria-label="nadi"
                        name="hd13"
                        id="ihd13"
                        value={horoscopeDetails.naadiValue}
                        onChange={handleChange("naadiValue")}
                    >
                        <option value="">&nbsp;</option>
                        {transliterationData.naadiValueOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <div  className="optionalFieldText md-3 mb-4">
                {transliterationData.optionalFieldsInfoText}
            </div>
            {/* Family God/Godess Info, Nakshatra, Gotra */}
            <div className="row">
                <div className="col-md-3 mb-4">
                    <input
                        className="mycss3"
                        name="ht7"
                        id="iht7"
                        placeholder={transliterationData.additionalKeyPlaceHolder}
                        maxLength="16"
                        value={horoscopeDetails.familyGodKey}
                        onChange={handleChange("familyGodKey")}
                    />
                    <input
                        className="form-control"
                        name="hd7"
                        id="ihd7"
                        maxLength="46"
                        value={horoscopeDetails.familyGodValue}
                        onChange={handleChange("familyGodValue")}
                    />
                </div>
                <div className="col-md-3 mb-4">
                    <input
                        className="mycss3"
                        name="ht8"
                        id="iht8"
                        placeholder={transliterationData.additionalKeyPlaceHolder}
                        maxLength="16"
                        value={horoscopeDetails.gotraKey}
                        onChange={handleChange("gotraKey")}
                    />
                    <input
                        className="form-control"
                        list="gotra"
                        name="hd8"
                        id="ihd8"
                        maxLength="29"
                        value={horoscopeDetails.gotraValue}
                        onChange={handleChange("gotraValue")}
                    />
                    <datalist id="gotra">
                        {transliterationData.gotraValueOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </datalist>
                </div>

                <div className="col-md-3 mb-4">
                    <input
                        className="mycss3"
                        name="ht9"
                        id="iht9"
                        placeholder={transliterationData.additionalKeyPlaceHolder}
                        maxLength="16"
                        value={horoscopeDetails.devakKey}
                        onChange={handleChange("devakKey")}
                    />
                    <input
                        className="form-control"
                        name="hd9"
                        id="ihd9"
                        maxLength="29"
                        value={horoscopeDetails.devakValue}
                        onChange={handleChange("devakValue")}
                    />
                </div>

                <div className="col-md-3 mb-4">
                    <input
                        className="mycss3"
                        name="ht10"
                        id="iht10"
                        placeholder={transliterationData.additionalKeyPlaceHolder}
                        maxLength="16"
                        value={horoscopeDetails.nakshyatraKey}
                        onChange={handleChange("nakshyatraKey")}
                    />
                    <input
                        className="form-control"
                        list="nakshtra"
                        name="hd10"
                        id="ihd10"
                        maxLength="29"
                        value={horoscopeDetails.nakshyatraValue}
                        onChange={handleChange("nakshyatraValue")}
                    />
                    <datalist id="nakshtra">
                        {transliterationData.nakshatraValueOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </datalist>
                </div>
            </div>
        </React.Fragment>
    );
});