import { NavBarNew, Footer } from '../../components';
import { ShippingDeliveryComponent } from '../../PoliciesComponent'

function ShippingDelivery() {
  return (
    <div className='ShippingDelivery'>
      <NavBarNew />
      <ShippingDeliveryComponent />
      <Footer />
    </div>
  );
}

export default ShippingDelivery;
