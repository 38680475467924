import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { cloneDeep } from "lodash";
import { BiodataCanvas } from "../";
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
// Transliteration
import { LpMarathi, LpEnglish, LpHindi } from '../../common';

// Routes
import { API_ENDPOINT } from '../../common';

import "./styles/link-view.css";

const importTransliterationData = (Language) => {
  switch (Language) {
    case 'Marathi':
      return LpMarathi;
    case 'English':
      return LpEnglish;
    case 'Hindi':
      return LpHindi;
    default:
      throw new Error(`Unsupported language: ${Language}`);
  }
};

function LinkView() {
  const { id } = useParams(); // Get the ID from the URL
  const [data, setData] = useState(null);
  const templateData = useRef();
  const transliterationData = useRef();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const fetchCalledRef = useRef(false);

  useEffect(() => {  
    if (fetchCalledRef.current) return; // Prevent second fetch in Strict Mode
    fetchCalledRef.current = true;
  
    async function fetchData() {
      try {
        const response = await fetch(
          `${API_ENDPOINT}/share_biodata/${id}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const result = await response.json();
        setData(result);
        templateData.current = cloneDeep(result);
        transliterationData.current = importTransliterationData(templateData.current.Language)

      } catch (error) {
        setError(error);
        console.error("Error fetching data:", error); // Debugging: Check error 
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, [id]);
  

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  const handleCreateMy = () => {
    navigate('/');
  };

  return (
    <div className="link-canvas-container container">
      <div className="link-view-greeting">
        <h2>{transliterationData.current.headingTxt.grTextL1} <a href="/">BiodataWala.com</a>. {transliterationData.current.headingTxt.grTextL2}</h2>
        <Button
          className="create-my-btn"
          variant="contained"
          color="secondary"
          onClick={handleCreateMy}
        >
          {transliterationData.current.ButtonText}
        </Button>
      </div>
      <BiodataCanvas templateData={templateData} />
    </div>
  );
}

export default LinkView;
