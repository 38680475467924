import { NavBarNew, Footer } from '../../components';
import { RefundPolicyComponent } from '../../PoliciesComponent'

function RefundPolicy() {
  return (
    <div className='RefundPolicy'>
      <NavBarNew />
      <RefundPolicyComponent />
      <Footer />
    </div>
  );
}

export default RefundPolicy;
