import React, { useState, useEffect, useRef, Suspense, lazy } from 'react';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CircularProgress from '@mui/material/CircularProgress'; // Material-UI loading spinner
import "./styles/homeBody.css";

// Lazy load the components
const MarathiBiodatas = lazy(() => import('../LanguageBiodata').then(module => ({ default: module.MarathiBiodatas })));
const EnglishBiodatas = lazy(() => import('../LanguageBiodata').then(module => ({ default: module.EnglishBiodatas })));
const HindiBiodatas = lazy(() => import('../LanguageBiodata').then(module => ({ default: module.HindiBiodatas })));

function HomeBody() {
    const [selectedLanguage, setSelectedLanguage] = useState('Marathi');
    const [showScrollButton, setShowScrollButton] = useState(false);
    const [componentMounted, setComponentMounted] = useState(false);
    const [loading, setLoading] = useState(false); // State to track loading

    // Refs for components
    const langComponentRef = useRef({
        Marathi: null,
        English: null,
        Hindi: null
    });

    // Ref for language-selector
    const languageSelectorRef = useRef(null);

    const handleScroll = (language) => {
        if (languageSelectorRef.current) {
            languageSelectorRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        // Ensure that the component is rendered to scroll into view after loading
        if (langComponentRef.current[language] && typeof langComponentRef.current[language].scrollIntoView === 'function') {
            langComponentRef.current[language].scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const checkScrollTop = () => {
        if (!showScrollButton && window.pageYOffset > 300) {
            setShowScrollButton(true);
        } else if (showScrollButton && window.pageYOffset <= 300) {
            setShowScrollButton(false);
        }
    };

    // Function to detect if images are loading
    const handleImageLoad = () => {
        const images = langComponentRef.current[selectedLanguage]?.querySelectorAll('img');
        if (images && images.length > 0) {
            const promises = Array.from(images).map((img) => {
                return new Promise((resolve) => {
                    if (img.complete) {
                        resolve(); // If image is already loaded
                    } else {
                        img.onload = resolve; // On image load
                        img.onerror = resolve; // If image fails to load, consider it as resolved
                    }
                });
            });
            Promise.all(promises).then(() => {
                setLoading(false); // Once all images are loaded, stop loading spinner
            });
        } else {
            // No images to load
            setLoading(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', checkScrollTop);
        return () => {
            window.removeEventListener('scroll', checkScrollTop);
        };
    });

    useEffect(() => {
        setComponentMounted(true);
    }, []);

    // Set loading to true when language is switched and then wait for images to load
    useEffect(() => {
        setLoading(true);
        const timeout = setTimeout(handleImageLoad, 300);
        return () => clearTimeout(timeout);
    }, [selectedLanguage]);

    return (
        <div className="homepage-body-container" id="homepage-body-container">
            <div className='language-button-sm'>
                <div className='container'>
                    <div className='language-selector' ref={languageSelectorRef}>
                        <h2>Choose Language</h2>
                        <div className='language-button-container'>
                            <button 
                                type="button"
                                className={`lang-btn py-2 me-2 ${selectedLanguage === 'Marathi' ? 'active' : ''}`}
                                onClick={() => {
                                    setSelectedLanguage('Marathi');
                                    if (componentMounted) {
                                        handleScroll('Marathi');
                                    }
                                }}
                            >
                                मराठी
                            </button>
                            <button 
                                type="button"
                                className={`lang-btn py-2 me-2 ${selectedLanguage === 'English' ? 'active' : ''}`}
                                onClick={() => {
                                    setSelectedLanguage('English');
                                    if (componentMounted) {
                                        handleScroll('English');
                                    }
                                }}
                            >
                                English
                            </button>
                            <button 
                                type="button"
                                className={`lang-btn py-2 me-2 ${selectedLanguage === 'Hindi' ? 'active' : ''}`}
                                onClick={() => {
                                    setSelectedLanguage('Hindi');
                                    if (componentMounted) {
                                        handleScroll('Hindi');
                                    }
                                }}
                            >
                                हिंदी
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container'>
                {/* Show loading spinner only in this second container */}
                {loading ? (
                    <div className="loading-container">
                        <CircularProgress />
                        <p>Loading...</p>
                    </div>
                ) : (
                    <Suspense fallback={<div>Loading component...</div>}>
                        {selectedLanguage === 'Marathi' && (
                            <MarathiBiodatas ref={el => (langComponentRef.current.Marathi = el)} />
                        )}
                        {selectedLanguage === 'English' && (
                            <EnglishBiodatas ref={el => (langComponentRef.current.English = el)} />
                        )}
                        {selectedLanguage === 'Hindi' && (
                            <HindiBiodatas ref={el => (langComponentRef.current.Hindi = el)} />
                        )}
                    </Suspense>
                )}

                {showScrollButton && (
                    <button 
                        className="scroll-to-top-btn" 
                        onClick={handleScrollToTop}
                    >
                        <ArrowUpwardIcon className="icon"/>
                    </button>
                )}
            </div>
        </div>
    );
}

export default HomeBody;
