export const homeSEO = {
	description:
		"Welcome to Biodatawala – your free and easy solution to create professional marriage biodata in minutes. Choose from our range of beautifully designed templates and customize them to suit your preferences.",
	keywords: ["Free marriage biodata", "Create marriage biodata online", "Marriage biodata generator", "Online biodata maker", "बायोडाटा", "ऑनलाइन लग्नासाठी बायोडाटा तयार करा", "लग्नासाठी बायोडाटा", "ऑनलाइन बायोडाटा मेकर", "फ्री शादी बायोडाटा जनरेटर"],

}

export const aboutSEO = {
	description:
		"Biodatawala वर, आम्हाला विश्वास आहे की लग्न बायोडाटा तयार करणे सोपे, सुलभ आणि मोफत असले पाहिजे. आमचे उद्दिष्ट म्हणजे लोकांना व्यावसायिक बायोडाटा तयार करण्यात मदत करणे, तेही अत्यंत सोप्या पद्धतीने आणि विनामूल्य. आमचे प्लॅटफॉर्म वापरण्यास सोपे आहे, आणि बायोडाटा तयार करण्याची प्रक्रिया अगदी सोपी होते.",
	keywords: ["ऑनलाइन विवाह बायोडाटा कैसे बनाएं", "शादी के लिए फ्री बायोडाटा टेम्पलेट डाउनलोड", "बिना सॉफ़्टवेयर के विवाह बायोडाटा बनाएं", "ऑनलाइन लग्नासाठी बायोडाटा तयार करा", "फ्री ऑनलाइन बायोडाटा", "सर्वोत्तम लग्न बायोडाटा फॉरमॅट्स"],
}

export const contactSEO = {
	description:
		"अरेंज्ड मॅरेज असो किंवा वैयक्तिक परिचय, Biodatawala आपला बायोडाटा आकर्षक बनवते. मराठी, हिंदी, किंवा इंग्रजीमध्ये आपला बायोडाटा तयार करणे सुरू करा! Easy wedding biodata creation tool Generate biodata in minutes online",
	keywords: ["Create Hindu marriage biodata", "Christian marriage biodata template", "Jain marriage biodata online", "Buddhist biodata for marriage", "Muslim marriage biodata maker", "Hindu matrimonial biodata template download"],
}

export const servicesSEO = {
	description:
		"Christian biodata generator for wedding Free wedding biodata for Hindu, Christian, Jain, Muslim Custom marriage biodata builder Top free biodata templates for wedding",
	keywords: ["हिंदू विवाह बायोडाटा बनाए", "जैन विवाह बायोडाटा ", "बौद्ध बायोडाटा", "मुस्लिम शादी के लिए बायोडाटा", "हिंदू, ईसाई, जैन, मुस्लिम फ्री बायोडाटा", "सभी धर्मों के लिए विवाह बायोडाटा टूल"],
}

export const blogsSEO = {
	page: "blogs",
	description:
		"हिंदू लग्नासाठी बायोडाटा टेम्प्लेट डाउनलोड करा निःशुल्क धार्मिक विवाह बायोडाटा अरेंज्ड मॅरेजसाठी मोफत बायोडाटा टेम्प्लेट्स फ्री लग्न बायोडाटा जनरेटर",
	keywords: ["हिंदू लग्नासाठी बायोडाटा तयार करा", "ख्रिश्चन विवाह बायोडाटा टेम्प्लेट", "जैन लग्न बायोडाटा", "बौद्ध विवाहासाठी बायोडाटा", "हिंदू, ख्रिश्चन, जैन, मुस्लिम मोफत बायोडाटा"],
}
