import React, { useState, forwardRef, useImperativeHandle, useCallback } from "react";

export default forwardRef(function PersonalData({ templateData, transliterationData }, ref) {
    const [personalDetails, setPersonalDetails] = useState({
        ...templateData.current.personalDetails,
    });
    const [errors, setErrors] = useState({
        nameValue: "",
        dobDay: "",
        dobMonth: "",
        dobYear: "",
        birthTimeValue: "",
        birthPlaceValue: "",
        heightValue: "",
        bloodGroupValue: ""
    });

    // The component instance will be extended
    // with whatever you return from the callback passed
    // as the second argument
    useImperativeHandle(ref, () => ({
        set(personalDetails) {
            setPersonalDetails(personalDetails);
        },

        reset(personalDetails) {
            setPersonalDetails(personalDetails);
        },
        validate: () => {
            let tempErrors = { ...errors };
        
            // Name field regex validation
            const nameRegex = /^[\p{L}\p{M}]+(?:[\s'][\p{L}\p{M}]+)*\s?$/u;
            tempErrors.nameValue =
                personalDetails.nameValue && nameRegex.test(personalDetails.nameValue)
                ? ""
                : transliterationData.nameValueError;
        
            // DOB date
            const dobDayRegex = /^(?:[1-9]|[12][0-9]|3[01])$/;
            tempErrors.dobDay =
                personalDetails.dobDay && dobDayRegex.test(personalDetails.dobDay)
                ? ""
                : transliterationData.dobDateError;
            
            // DOB month
            const dobMonthRegex = /^[\p{L}\p{M}]+(?:[\s'][\p{L}\p{M}]+)*$/u;
            tempErrors.dobMonth =
                personalDetails.dobMonth && dobMonthRegex.test(personalDetails.dobMonth)
                ? ""
                : transliterationData.dobMonthError;

            // DOB year
            const dobYearRegex = /^(19[89][0-9]|20[0-9][0-9]|2010)$/;
            tempErrors.dobYear =
                personalDetails.dobYear && dobYearRegex.test(personalDetails.dobYear)
                ? ""
                : transliterationData.dobYearError;

            // Birth time
            tempErrors.birthTimeValue =
                Boolean(personalDetails.birthTimeValue.trim())
                    ? ""
                    : transliterationData.birthTimeError;
            
            // Birth place
            tempErrors.birthPlaceValue =
                Boolean(personalDetails.birthPlaceValue.trim())
                    ? ""
                    : transliterationData.birthPlaceError;

            // Height
            tempErrors.heightValue =
                Boolean(personalDetails.heightValue.trim())
                    ? ""
                    : transliterationData.heightError;

            // blood group
            tempErrors.bloodGroupValue =
                Boolean(personalDetails.bloodGroupValue.trim())
                    ? ""
                    : transliterationData.bloodGroupError;

                
            setErrors(tempErrors);
        
            return Object.values(tempErrors).every((x) => x === "");
        },
    }));

    const handleChange = useCallback(
        (property) => (event) => {
            const value = event.target.value;

            templateData.current.personalDetails[property] = value;
            setPersonalDetails((prevState) => ({ ...prevState, [property]: value }));

            // Perform inline validation
            let tempErrors = { ...errors };
            if (property === "nameValue") {
                const nameRegex = /^[\p{L}\p{M}]+(?:[\s'][\p{L}\p{M}]+)*\s?$/u;
                tempErrors.nameValue =
                    value && nameRegex.test(value)
                        ? ""
                        : transliterationData.nameValueError;
            }
            
            setErrors(tempErrors);
        },
        [templateData, errors, transliterationData]
    );

    return (
        <React.Fragment>
            {/* Name Start */}
            <div className="row pt-4">
                <div className="col-md-6 mb-4">
                    <select
                        className="form-select mycss"
                        aria-label="Name Label"
                        name="ht1"
                        id="iht1"
                        value={personalDetails.nameKey} 
                        onChange={handleChange("nameKey")}
                    >
                        {transliterationData.nameKeyOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                    <input
                        required
                        className={`form-control ${errors.nameValue ? "is-invalid" : ""}`}
                        name="hd1"
                        id="nameValue"
                        placeholder={transliterationData.nameValuePlaceHolder}
                        maxLength="46"
                        value={personalDetails.nameValue}
                        onChange={handleChange("nameValue")}
                    />
                    <div className="invalid-feedback">{errors.nameValue}</div>
                </div>

                {/* DOB Heading */}
                <div className="col-md-6 mb-4">
                    <select
                        className="form-select mycss"
                        aria-label="Date of birth Label"
                        name="ht2"
                        id="iht2"
                        value={personalDetails.dobKey}
                        onChange={handleChange("dobKey")}
                    >
                        {transliterationData.dobKeyOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}

                    </select>

                    {/* DOB selector */}
                    <div className="input-group" style={{ display: "flex", gap: "8px" }}>
                        <div className="flex-grow-1">
                            <select
                                required
                                className={`form-select ${errors.dobDay ? "is-invalid" : ""}`}
                                aria-label="Day"
                                name="hd2a"
                                id="dobDay"
                                value={personalDetails.dobDay}
                                onChange={handleChange("dobDay")}
                            >
                                {transliterationData.dobDayOptions.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                            <div className="invalid-feedback">{errors.dobDay}</div>
                        </div>
                        {/* Month Selector */}
                        <div className="flex-grow-1">
                            <select
                                required
                                className={`form-select ${errors.dobMonth ? "is-invalid" : ""}`}
                                aria-label="Month"
                                name="hd2b"
                                id="dobMonth"
                                value={personalDetails.dobMonth}
                                onChange={handleChange("dobMonth")}
                            >
                                {transliterationData.dobMonthOptions.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                            <div className="invalid-feedback">{errors.dobMonth}</div>
                        </div>
                        {/* Year Selector */}
                        <div className="flex-grow-1">
                            <select
                                required
                                className={`form-select ${errors.dobYear ? "is-invalid" : ""}`}
                                aria-label="year"
                                name="hd2c"
                                id="dobYear"
                                value={personalDetails.dobYear}
                                onChange={handleChange("dobYear")}
                            >
                                {transliterationData.dobYearOptions.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                            <div className="invalid-feedback">{errors.dobYear}</div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Birth Details */}
            <div className="row">
                <div className="col-md-3 mb-4">
                    <select
                        required
                        className="form-select mycss"
                        aria-label="Birth Place Label"
                        name="ht3"
                        id="iht3"
                        value={personalDetails.birthTimeKey}
                        onChange={handleChange("birthTimeKey")}
                    >
                        {transliterationData.birthTimeKeyOption.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                    <input
                        required
                        className={`form-control ${errors.birthTimeValue ? "is-invalid" : ""}`}
                        name="hd3"
                        id="birthTimeValue"
                        placeholder={transliterationData.birthTimeValuePlaceHolder}
                        maxLength="39"
                        value={personalDetails.birthTimeValue}
                        onChange={handleChange("birthTimeValue")}
                    />
                    <div className="invalid-feedback">{errors.birthTimeValue}</div>
                </div>

                <div className="col-md-3 mb-4">
                    <select
                        required
                        className="form-select mycss"
                        aria-label="Birth Place Label"
                        name="ht4"
                        id="iht4"
                        value={personalDetails.birthPlaceKey}
                        onChange={handleChange("birthPlaceKey")}
                    >
                        {transliterationData.birthPlaceKeyOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                    <input
                        required
                        className={`form-control ${errors.birthPlaceValue ? "is-invalid" : ""}`}
                        name="hd4"
                        id="birthPlaceValue"
                        placeholder={transliterationData.birthPlaceValuePlaceHolder}
                        maxLength="46"
                        value={personalDetails.birthPlaceValue}
                        onChange={handleChange("birthPlaceValue")}
                    />
                    <div className="invalid-feedback">{errors.birthPlaceValue}</div>
                </div>

                {/* Height */}
                <div className="col-md-2 mb-4">
                    <label className="form-label">{transliterationData.heightKey}</label>
                    <select
                        required
                        className={`form-select ${errors.heightValue ? "is-invalid" : ""}`}
                        aria-label="Height"
                        name="hd14"
                        id="heightValue"
                        value={personalDetails.heightValue}
                        onChange={handleChange("heightValue")}
                    >
                        <option value="">
                            &nbsp;
                        </option>
                        {transliterationData.heightValueOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                    <div className="invalid-feedback">{errors.heightValue}</div>
                </div>
                {/* Color */}
                <div className="col-md-2 mb-4">
                    <select
                        className="form-select mycss"
                        aria-label="varn label"
                        name="ht15"
                        id="iht15"
                        value={personalDetails.colorKey}
                        onChange={handleChange("colorKey")}
                    >
                        {transliterationData.colorKeyOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                    <select
                        className="form-select"
                        aria-label="varn"
                        name="hd15"
                        id="ihd15"
                        value={personalDetails.colorValue}
                        onChange={handleChange("colorValue")}
                    >
                        <option value="">
                            &nbsp;
                        </option>
                        {transliterationData.colorValueOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                </div>
                {/* Blood Group */}
                <div className="col-md-2 mb-4">
                    <label className="form-label">{transliterationData.bloodGroupKey}</label>
                    <select
                        className={`form-select ${errors.bloodGroupValue ? "is-invalid" : ""}`}
                        aria-label="raktgat"
                        name="hd16"
                        id="bloodGroupValue"
                        value={personalDetails.bloodGroupValue}
                        onChange={handleChange("bloodGroupValue")}
                    >
                        <option value="">
                            &nbsp;
                        </option>
                        <option value="O+">O+</option>
                        <option value="O-">O-</option>
                        <option value="A+">A+</option>
                        <option value="A-">A-</option>
                        <option value="B+">B+</option>
                        <option value="B-">B-</option>
                        <option value="AB+">AB+</option>
                        <option value="AB-">AB-</option>
                    </select>
                    <div className="invalid-feedback">{errors.bloodGroupValue}</div>
                </div>
            </div>
        </React.Fragment>
    );
});
