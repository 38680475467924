import React, { useState, forwardRef } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CloseIcon from '@mui/icons-material/Close';

import "./styles/god-icon.css";

const iconSets = {
    Hindu: [
      "Ganesha1.png",
      "Ganesha2.png",
      "Ganesha3.png",
      "Ganesha4.png"
    ],
    Jain: [
      "Jain1.png",
      "Jain2.png",
      "Jain3.png",
      "Jain4.png"
    ],
    Buddhist: [
      "Buddha1.png",
      "Buddha2.png",
      "Buddha3.png",
      "Buddha4.png"
    ],
    Christian: [
      "Christian1.png",
      "Christian2.png",
      "Christian3.png",
      "Christian4.png"
    ],
    Muslim: [
      "Muslim1.png",
      "Muslim2.png",
      "Muslim3.png",
      "Muslim4.png",
    ]
  };
export default forwardRef(function GodIconModal({ onClose, transliterationData, onIconChange }, ref) {
  const [currentTab, setCurrentTab] = useState('Hindu');


  const changeGodIcon = (imgName) => () => {
    const imgPath = `${currentTab.toLowerCase()}/${imgName}`;
    onIconChange(imgPath);
    onClose();
  };

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <React.Fragment>
      {/* <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="changeganesha" aria-hidden="true"> */}
      <div className="modal-dialog">
      <div className="modal-content">
          <div className="modal-header">
            <Tabs
              value={currentTab}
              onChange={handleTabChange}
              aria-label="icon tabs"
            >
              <Tab className="religion-heading" label={transliterationData.godIconModal.hindu} value="Hindu" />
              <Tab className="religion-heading" label={transliterationData.godIconModal.jain} value="Jain" />
              <Tab className="religion-heading" label={transliterationData.godIconModal.buddhist} value="Buddhist" />
              <Tab className="religion-heading" label={transliterationData.godIconModal.christian} value="Christian" />
              <Tab className="religion-heading" label={transliterationData.godIconModal.muslim}value="Muslim" />
            </Tabs>
            <CloseIcon type="button" className="btn-modal-close" onClick={onClose} />
          </div>
          <div className="modal-body">
            <div className="container">
              <div className="god-icon-container">
                {iconSets[currentTab].map((icon, index) => (
                  <div className="col" key={index}>
                    <img
                      width="100"
                      height="100"
                      className="img-fluid"
                      src={`images/godIcons/${currentTab.toLowerCase()}/${icon}`}
                      alt={currentTab}
                      loading="lazy"
                      onClick={changeGodIcon(icon)}
                    />
                  </div>
                ))}
              </div>
              <div className="god-button-container">
                {iconSets[currentTab].map((icon, index) => (
                  <div className="col" key={index}>
                    <button
                      type="button"
                      className="btn selectBappa"
                      onClick={changeGodIcon(icon)}
                    >
                      <AddCircleOutlineIcon
                        sx={{
                          width: "30px",
                          height: "30px",
                          "&:hover": { color: "green" },
                        }}
                      />
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </React.Fragment>
  );
});
