import React, { useState, forwardRef, useImperativeHandle, useRef, useEffect } from "react";
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import Modal from 'react-bootstrap/Modal';
import ReactCropper from 'react-cropper';
import Slider from 'rc-slider';
import heic2any from "heic2any";
import 'cropperjs/dist/cropper.css';
import 'rc-slider/assets/index.css';
import './styles/upload-img.css';

const UploadImg = forwardRef(({ templateData, transliterationData }, ref) => {
    useImperativeHandle(ref, () => ({
        set(userPhoto) {
            templateData.current.userPhoto = userPhoto;
            setCroppedImage(userPhoto);
        },
        reset(userPhoto) {
            templateData.current.userPhoto = userPhoto;
            setCroppedImage(userPhoto);
        },
    }));

    const [showModal, setShowModal] = useState(false);
    const [croppedImage, setCroppedImage] = useState(templateData.current.userPhoto); // State to store cropped image URL
    const [imageSrc, setImageSrc] = useState(null);
    const [zoomValue, setZoomValue] = useState(1); // Default zoom is 100% (1x)
    const [rotateValue, setRotateValue] = useState(0);
    const [loading, setLoading] = useState(false); // State for loading

    const cropperRef = useRef(null);

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            setLoading(true);
            if (file.type === 'image/heic' || file.name.endsWith('.heic')) {
                // Convert HEIC to JPEG
                try {
                    const convertedBlob = await heic2any({
                        blob: file,
                        toType: "image/jpeg",
                    });
                    const convertedFile = new File([convertedBlob], file.name.replace(".heic", ".jpeg"), {
                        type: "image/jpeg",
                    });

                    const reader = new FileReader();
                    reader.onload = () => {
                        setImageSrc(reader.result);
                        setShowModal(true);
                        setLoading(false);
                    };
                    reader.readAsDataURL(convertedFile);
                } catch (error) {
                    console.error("Error converting HEIC file:", error);
                    setLoading(false);
                }
            } else {
                // Handle other image formats
                const reader = new FileReader();
                reader.onload = () => {
                    setImageSrc(reader.result);
                    setShowModal(true);
                    setLoading(false);
                };
                reader.readAsDataURL(file);
            }
        }
    };

    const handleCrop = () => {
        if (cropperRef.current && cropperRef.current.cropper) {
            const croppedCanvas = cropperRef.current.cropper.getCroppedCanvas({
                width: 262,
                height: 350,
                imageSmoothingEnabled: true,
                imageSmoothingQuality: 'high',
            });
            if (croppedCanvas) {
                croppedCanvas.toBlob((blob) => {
                    const croppedImageUrl = URL.createObjectURL(blob);
                    setCroppedImage(croppedImageUrl);

                    // Convert blob to base64
                    const reader = new FileReader();
                    reader.readAsDataURL(blob);
                    reader.onloadend = () => {
                        const base64data = reader.result;
                        templateData.current.userPhoto = base64data;
                    };

                    setShowModal(false);
                });
            }
        }
    };

    const handleModalClose = () => {
        setShowModal(false);
    };

    const handleButtonClick = () => {
        const fileInput = document.getElementById('formFile');
        fileInput.click();
    };

    const handleRemoveImage = () => {
        templateData.current.userPhoto = null;
        setCroppedImage(null);
    };

    useEffect(() => {
        if (cropperRef.current && cropperRef.current.cropper) {
            cropperRef.current.cropper.zoomTo(zoomValue);
        }
    }, [zoomValue]);

    useEffect(() => {
        if (cropperRef.current && cropperRef.current.cropper) {
            cropperRef.current.cropper.rotateTo(rotateValue);
        }
    }, [rotateValue]);

    const handleCropperReady = () => {
        if (cropperRef.current && cropperRef.current.cropper) {
            cropperRef.current.cropper.setAspectRatio(262 / 350);

            const containerData = cropperRef.current.cropper.getContainerData();
            const imageData = cropperRef.current.cropper.getImageData();
            const aspectRatio = imageData.width / imageData.height;

            let cropWidth = 262;
            let cropHeight = 350;

            if (aspectRatio < 262 / 350) {
                cropWidth = imageData.width * (350 / imageData.height);
            } else {
                cropHeight = imageData.height * (262 / imageData.width);
            }

            const left = (containerData.width - cropWidth) / 2;
            const top = (containerData.height - cropHeight) / 2;

            cropperRef.current.cropper.setCropBoxData({
                width: cropWidth,
                height: cropHeight,
                left: left,
                top: top,
            });
            cropperRef.current.cropper.setDragMode('move');
        }
    };

    return (
        <React.Fragment>
            <div className="row text-center">
                <div className="optionalFieldText mt-3 mb-2">
                    {transliterationData.photoInfoText}
                </div>
                <div className="col-md-4 col-0"></div>
                
                <div className="col-md-4">
                    
                    <div className="mb-3">
                        <label className="form-label">{transliterationData.userPhotoHeading}</label>
                        <div>
                            <input className="form-control item-img file" type="file" id="formFile" onChange={handleFileChange} />
                            <button type="button" className="btn btn-outline-secondary btn-sm" onClick={handleButtonClick} id="btnphoto">
                                <AddAPhotoIcon sx={{ width: '40px', height: '40px' }} />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 col-0"></div>
            </div>

            {/* Loading bar */}
            {loading && (
                <div className="row">
                    <div className="col-md-4 col-0"></div>
                    <div className="col-md-4 text-center">
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                        <p>{transliterationData.photoLoadingText}</p>
                    </div>
                    <div className="col-md-4 col-0"></div>
                </div>
            )}

            <Modal show={showModal} onHide={handleModalClose} dialogClassName="custom-modal">
                <Modal.Header closeButton>
                    <Modal.Title>Crop Image</Modal.Title>
                </Modal.Header>
                <Modal.Body className="custom-modal-body">
                    <div className="cropper-wrapper">
                        <ReactCropper
                            ref={cropperRef}
                            src={imageSrc}
                            guides={true}
                            zoomable={true}
                            zoomOnTouch={true}
                            zoomOnWheel={true}
                            viewMode={1}
                            ready={handleCropperReady} 
                            style={{ width: '100%', height: '100%' }}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer className="custom-modal-footer">
                    <div className="w-100">
                        <div className="float-left mb-4 d-block" style={{ width: 200, marginRight: '65px' }}>
                            <label className="me-2 mb-0">Zoom</label>
                            <Slider 
                                min={0} step={0.1} max={4}
                                marks={{ '0': '0x', '1': '1x', '2': '2x', '3': '3x', '4': '4x' }} 
                                value={zoomValue} 
                                onChange={(value) => {
                                    setZoomValue(value);
                                }}
                            />
                        </div>
                        <div className="float-left mb-3 d-block" style={{width: 200}}>
                            <label className="me-2 mb-0">Rotate</label>
                            <Slider 
                                min={-180} max={180}
                                marks={{'-180': '-180°', '0': '0°', '180': '180°'}}
                                value={rotateValue}
                                onChange={(value) => {
                                    setRotateValue(value);
                                }}
                            />
                        </div>
                    </div>
                    <button className="btn btn-primary mt-3" onClick={handleCrop}>
                        Crop
                    </button>
                    <button className="btn btn-secondary mt-3" onClick={handleModalClose}>
                        Cancel
                    </button>
                </Modal.Footer>
            </Modal>

            {croppedImage && (
                <div className="row mt-3">
                    <div className="col-md-4 col-0"></div>
                    <div className="col-md-4 text-center">
                        <div className="d-flex justify-content-between align-items-center mb-2">
                            <label className="form-label mb-0">Image Preview</label>
                            <button className="btn btn-danger btn-sm" onClick={handleRemoveImage}>
                                Remove Image
                            </button>
                        </div>
                        <div>
                            <img src={croppedImage} className="img-responsive img-thumbnail border" style={{ width: '262px', height: '350px' }} alt="Cropped Pic" />
                        </div>
                    </div>
                    <div className="col-md-4 col-0"></div>
                </div>
            )}
        </React.Fragment>
    );
});

export default UploadImg;
