import React from 'react';
import "./styles/terms-and-conditions.css";

function TermsConditionComponent() {

    return (
        <div className="components-wrapper">
            <section className="component-section">
                <div className="container tnc-container">
                    <div className="component-row gap">
                        <h2 className="heading-description">Terms And Conditions</h2>
                    </div>
                    <p className="about-para">
                        Discover our free marriage biodata service, crafted to capture your unique story
                        with elegance and ease. We offer a variety of templates designed to suit diverse
                        preferences and regional languages, ensuring your biodata reflects your cultural
                        heritage. Create personalized biodata effortlessly, celebrating your journey
                        together with a touch of tradition and modern flair. Start crafting your perfect
                        biodata today, free of cost.
                    </p>
                </div>
            </section>
        </div>
    );
}

export default TermsConditionComponent;