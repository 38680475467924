import { NavBarNew, Footer, NotFound } from '../components';


function Error404() {
  return (
    <div className='CreateBiodata'>
      <NavBarNew />
      <NotFound />
      <Footer />
    </div>
  );
}

export default Error404;
