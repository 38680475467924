import React, { useState, Fragment } from "react";
import { AlertNew } from "../components";
import "./styles/contact-us.css";

// Routes
import { API_ENDPOINT } from "../common";
const SEND_MESSAGE = `${API_ENDPOINT}/send-message`;

function ContactUsComponent() {
  const [alert, setAlert] = useState({ open: false, type: "", children: "" });

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    subject: "",
    message: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    mobile: "",
    subject: "",
    message: "",
  });

  const validate = () => {
    let tempErrors = { ...errors };

    // Name field regex validation
    const nameRegex = /^[A-Za-z\s]+$/;
    tempErrors.name =
      formData.name && nameRegex.test(formData.name)
        ? ""
        : "Name should contain only letters and spaces.";

    // Email field validation
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    tempErrors.email =
      formData.email && emailRegex.test(formData.email)
        ? ""
        : "Valid email is required.";

    // Mobile field validation
    const mobileRegex = /^(?:\+91|91)?[6789]\d{9}$/;
    tempErrors.mobile =
      formData.mobile && mobileRegex.test(formData.mobile)
        ? ""
        : "Mobile number must be valid 10 digits.";

    if (formData.subject) {
      const length = formData.subject.length;
      if (length < 3) {
          tempErrors.subject = "Subject is too short.";
      } else if (length > 35) {
          tempErrors.subject = "Subject is too long.";
      } else {
          tempErrors.subject = "";
      }
    }

    if (formData.message) {
      const length = formData.message.length;
      if (length < 8) {
          tempErrors.message = "Message is too short.";
      } else if (length > 150) {
          tempErrors.message = "Message is too long.";
      } else {
          tempErrors.message = "";
      }
    }

    setErrors(tempErrors);

    return Object.values(tempErrors).every((x) => x === "");
  };
  
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });

    // Validate on field change
    let tempErrors = { ...errors };
    if (id === "name") {
      const nameRegex = /^[A-Za-z\s]+$/;
      tempErrors.name =
        value && nameRegex.test(value)
          ? ""
          : "Name should contain only letters and spaces.";
    }
    if (id === "email") {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      tempErrors.email =
        value && emailRegex.test(value) ? "" : "Valid email is required.";
    }
    if (id === "mobile") {
      const mobileRegex = /^(?:\+91|91)?[6789]\d{9}$/;
      tempErrors.mobile =
        value && mobileRegex.test(value)
          ? ""
          : "Mobile number must be valid 10 digits.";
    }

    if (id === "subject") {
      const length = value.length;
      if (length < 3) {
          tempErrors.subject = "Subject is too short.";
      } else if (length > 35) {
          tempErrors.subject = "Subject is too long.";
      } else {
          tempErrors.subject = "";
      }
    }


    if (id === "message") {
      const length = value.length;
      if (length < 8) {
          tempErrors.message = "Message is too short.";
      } else if (length > 150) {
          tempErrors.message = "Message is too long.";
      } else {
          tempErrors.message = "";
      }
    }

    setErrors(tempErrors);
  };
  console.log(errors)

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      try {
        fetch(SEND_MESSAGE, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(formData),
        }).then((response) => {
          if (!response.ok) {
            setAlert({ open: true, type: "error", children: "Failed to send message."});
          } else {
            setAlert({ open: true, type: "success", children: "Message sent successfully!"});
            setFormData({ name: '', email: '', mobile: '', subject: '', message: '' });
          }
        });
      } catch (error) {
        alert("An error occurred. Please try again later.");
      }
    }
  };

  return (
    <Fragment>
      <AlertNew {...alert} duration={3000} onClose={() => setAlert({ ...alert, open: false })}/>
      <div className="components-wrapper">
        <section className="component-section">
          <div className="container">
            <div className="component-row gap">
              <div className="textImgCol col">
                <h1 className="page-title">Contact Us</h1>
                <h2 className="contact-heading-description">
                  {" "}
                  Need Assistance? Connect with Us Today! ☎️
                </h2>
                <p className="contact-para">
                  We value your feedback and queries. For assistance or
                  suggestions regarding our free marriage biodata maker, email
                  us at
                  <a href="mailto:help@biodatawala.com">
                    {" "}
                    help@biodatawala.com
                  </a>{" "}
                  or fill out the form below. If you face any problem in
                  downloading your biodata, let us know. Our team will respond
                  within 24 hours. ⏰
                </p>
              </div>
              <div className="textImgCol col">
                <div className="contact-us-clip-art">
                  <picture>
                    <img
                      decoding="async"
                      loading="lazy"
                      src="contact-us.png"
                      className="contact-us-clip-art"
                      alt="biodata couple"
                    />
                  </picture>
                </div>
              </div>
            </div>
            <div className="user-query-form">
              <div className="col-10 col-md-8 col-lg-7">
                <form onSubmit={handleSubmit}>
                  <div className="row g-3">
                    <div className="col-sm-6">
                      <div className="form-floating">
                        <input
                          type="text"
                          className={`form-control ${
                            errors.name ? "is-invalid" : ""
                          }`}
                          id="name"
                          placeholder="Your Name"
                          value={formData.name}
                          onChange={handleChange}
                        />
                        <label htmlFor="name">Your Name</label>
                        <div className="invalid-feedback">{errors.name}</div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-floating">
                        <input
                          type="email"
                          className={`form-control ${
                            errors.email ? "is-invalid" : ""
                          }`}
                          id="email"
                          placeholder="Your Email"
                          value={formData.email}
                          onChange={handleChange}
                        />
                        <label htmlFor="email">Your Email</label>
                        <div className="invalid-feedback">{errors.email}</div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-floating">
                        <input
                          type="text"
                          className={`form-control ${
                            errors.mobile ? "is-invalid" : ""
                          }`}
                          id="mobile"
                          placeholder="Your Mobile"
                          value={formData.mobile}
                          onChange={handleChange}
                        />
                        <label htmlFor="mobile">Your Mobile</label>
                        <div className="invalid-feedback">{errors.mobile}</div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-floating">
                        <input
                          type="text"
                          className={`form-control ${
                            errors.subject ? "is-invalid" : ""
                          }`}
                          id="subject"
                          placeholder="Subject"
                          value={formData.subject}
                          onChange={handleChange}
                        />
                        <label htmlFor="subject">Subject</label>
                        <div className="invalid-feedback">{errors.subject}</div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                        <textarea
                          className={`form-control ${
                            errors.message ? "is-invalid" : ""
                          }`}
                          id="message"
                          placeholder="Leave a message here"
                          style={{ height: "100px" }}
                          value={formData.message}
                          onChange={handleChange}
                        />
                        <label htmlFor="message">Message</label>
                        <div className="invalid-feedback">{errors.message}</div>
                      </div>
                    </div>
                    <div className="col-12 send-msg">
                      <button className="send-msg-btn send-msg-btn-style" type="submit">
                        Send Message
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Fragment>
  );
}

export default ContactUsComponent;
