import { NavBarNew, Footer } from '../components';
import { ChangeTemplateComponent } from '../BiodataPreview';


function ChangeTemplate() {
  return (
    <div className='ChangeTemplates'>
      <NavBarNew />
      <ChangeTemplateComponent />
      <Footer />
    </div>
  );
}

export default ChangeTemplate;
