import React from 'react';
import "./styles/terms-and-conditions.css";

function PrivacyPolicyComponent() {

    return (
        <div className="components-wrapper">
            <section className="component-section">
                <div className="container tnc-container">
                    <div className="component-row gap">
                        <h2 className="heading-description">Privacy Policy</h2>
                    </div>
                    <h5>
                        Updated 18th July 2024
                    </h5>
                    <h4>
                    Introduction
                    </h4>
                    <p className="about-para">
                        Welcome to Biodatawala. We are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner. This Privacy Policy outlines how we collect, use, disclose, and protect your information.
                    </p>
                    <h4>
                    Information We Collect
                    </h4>
                    <p className="about-para">
                        1. Personal Information: When you use our service to create a marriage biodata, we may collect personal information such as your name, email address, and other details necessary to generate the biodata.
                    </p>
                    <p className="about-para">
                        2. Non-Personal Information: We may collect non-personal information such as your browser type, IP address, and operating system to help us improve our website and services.
                    </p>
                    <h4>
                        How We Use Your Information
                    </h4>
                    <p className="about-para">
                        1. Service Provision: To generate and provide your marriage biodata in PDF format.
                    </p>
                    <p className="about-para">
                        2. Communication: To contact you regarding your use of our service or to provide updates and information about Biodatawala.
                    </p>
                    <p className="about-para">
                        3. Improvement: To develop and enhance our products and services based on your feedback and usage patterns. 
                    </p>
                    <h4>
                        Sharing Your Information
                    </h4>
                    <p className="about-para">
                        We do not sell, trade, or otherwise transfer your personal information to outside parties. We do not rent or sell your personal information to third parties for their marketing purposes without your explicit consent. From time to time, we may disclose general statistical information about our site and visitors, such as the number of visitors and the types of services used. This information will not include personal details that can identify any individual.
                    </p>
                    <h4>
                        Data Security
                    </h4>
                    <p className="about-para">
                        We implement a variety of security measures to maintain the safety of your personal information. However, please be aware that no method of transmission over the internet, or method of electronic storage, is 100% secure.
                    </p>
                    <h4>
                        Cookies
                    </h4>
                    <p className="about-para">
                        We may use cookies to enhance your experience on our website. Cookies are small files that a site or its service provider transfers to your computer’s hard drive through your web browser that enables the site’s or service provider’s systems to recognize your browser and capture and remember certain information.
                    </p>
                    <h4>
                        Third-Party Links
                    </h4>
                    <p className="about-para">
                        Our website may contain links to third-party sites. These third-party sites have separate and independent privacy policies. We therefore have no responsibility or liability for the content and activities of these linked sites.
                    </p>
                    <h4>
                        Changes to Our Privacy Policy
                    </h4>
                    <p className="about-para">
                        We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our website. You are advised to review this Privacy Policy periodically for any changes.
                    </p>
                    <h4>
                        Contact Us
                    </h4>
                    <p className="about-para">
                        If you have any questions regarding this Privacy Policy, please visit our Contact Us page and fill out the form or email us at <a href="mailto:help@biodatawala.com"> help@biodatawala.com</a>
                    </p>
                    <p className="about-para">
                        Thank you for your understanding and support.
                    </p>
                </div>
            </section>
        </div>
    );
}

export default PrivacyPolicyComponent;