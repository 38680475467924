import { NavBarNew, Footer } from '../../components';
import { PrivacyPolicyComponent } from '../../PoliciesComponent'

function PrivacyPolicy() {
  return (
    <div className='PrivacyPolicy'>
      <NavBarNew />
      <PrivacyPolicyComponent />
      <Footer />
    </div>
  );
}

export default PrivacyPolicy;
