import './App.css';
import  { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { Home, Biodata, BiodataPreview, LinkViewer, ChangeTemplate, Error404 } from './pages';
import { About, Blogs, Contact, Services } from './pages';
import { TermsCondition, PrivacyPolicy, RefundPolicy, ShippingDelivery } from './pages';
import { Helmet } from 'react-helmet';
import ReactGA from "react-ga4";

function BiodataUi() {
  const location = useLocation();

  useEffect(() => {
    // Track page views
    ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
  }, [location]);
  return (
    <div className="BiodataUi">
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap" rel="stylesheet" />
      </Helmet>
      <Routes>
				<Route path="/" element={<Home />} />
				<Route path="/create-biodata" element={<Biodata />} />
        <Route path="/preview-biodata" element={<BiodataPreview />} />
        <Route path="/change-template" element={<ChangeTemplate />} />
        <Route path="/biodatas/:id" element={<LinkViewer />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/services" element={<Services />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="*" element={<Error404 />} />
        {/*  */}
        <Route path="/terms-conditions" element={<TermsCondition />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/refund-policy" element={<RefundPolicy />} />
        <Route path="/shipping-delivery" element={<ShippingDelivery />} />
        
      </Routes>
    </div>
  );
}

export default BiodataUi;
