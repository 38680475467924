import { NavBarNew, Footer } from '../components';
import { PreviewPage } from '../BiodataPreview';


function BiodataPreview() {
  return (
    <div className='PreviewBiodata'>
      <NavBarNew />
      <PreviewPage />
      <Footer />
    </div>
  );
}

export default BiodataPreview;
