import React, { useRef, useState, Fragment, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { isObject, isArray, cloneDeep } from 'lodash';
// Icons and colors
import EditIcon from "@mui/icons-material/Edit";
import RefreshIcon from "@mui/icons-material/Refresh";
import { CircularProgress } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import DownloadIcon from '@mui/icons-material/Download';
import Button from '@mui/material/Button';
import { green } from '@mui/material/colors';
// Transliteration
import { PMarathi, PEnglish, PHindi } from '../../common';
// Components
import { AlertNew } from '../../components'
import { BiodataCanvas } from '../';
import './styles/preview.css';

// Routes
import { API_ENDPOINT } from '../../common';
const DOWNLOAD_BIODATA = `${API_ENDPOINT}/create-biodata`;
const SHARE_BIODATA = `${API_ENDPOINT}/share_biodata/get_link`;

const importTransliterationData = (Language) => {
  switch (Language) {
    case 'Marathi':
      return PMarathi
    case 'English':
      return PEnglish
    case 'Hindi':
      return PHindi
    default:
      throw new Error(`Unsupported language: ${Language}`);
  }
};

function PreviewPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const templateData = useRef(cloneDeep(JSON.parse(sessionStorage.getItem('biodataData'))));
  const { templateImage, Language } = location.state || {};
  if (templateImage) {
    templateData.current.templateImage = templateImage;
    sessionStorage.setItem(
      "biodataData",
      JSON.stringify(templateData.current)
    );
  }

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0); // Small timeout to ensure layout is complete
  }, []);

  const transliterationData = importTransliterationData(Language)
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingLink, setIsLoadingLink] = useState(false);
  const [alert, setAlert] = useState({ open: false, type: '', children: '' });

  const handleEditClick = () => {
    navigate("/create-biodata", {
      state: { templateData: templateData.current , 
        Language: templateData.current.Language,
        templateImage: templateData.current.templateImage
      },
    });
  };

  const handleChangeTemplate = () => {
    navigate("/change-template", {
      state: { templateImage: templateImage, Language: Language },
    });
  };

  const handleDownloadRequest = () => {
    return (event) => {
      event.preventDefault();
      setIsLoading(true);

      var form = new FormData();

      for (const key in templateData.current) {
        if (isObject(templateData.current[key]) && !isArray(templateData.current[key])) {
          for (const subKey in templateData.current[key]) {
            if (templateData.current[key][subKey]) {
              form.append(key + subKey.charAt(0).toUpperCase() + subKey.slice(1), templateData.current[key][subKey]);
            }
          }
        }
        else if (isArray(templateData.current[key])) {
          form.append(key, JSON.stringify(templateData.current[key]));
        }
        else {
          if (templateData.current[key]) {
            form.append(key, templateData.current[key]);
          }
        }
      }

      let fileName;
      fetch(DOWNLOAD_BIODATA, {
        method: 'POST',
        body: form
      })
        .then((response) => {
          if (!response.ok) {
            if (response.status === 400) {
              throw new Error('Bad Request: Please check your input.');
            } else if (response.status === 429) {
              throw new Error('Rate Limit: Too many downloads from your device.');
            } else if (response.status === 500) {
              throw new Error('Internal Server Error: Please try again later.');
            }
            return response.json();
          }

          let contentDisposition = response.headers.get('content-disposition');

          if (contentDisposition) {
            const fileNameMatch = contentDisposition.match(/filename\*?=["']?([^"';]*)["']?/i);
            if (fileNameMatch && fileNameMatch[1]) {
              fileName = decodeURIComponent(fileNameMatch[1]);
            }
          }

          return response.blob().then(blob => ({ blob, fileName }));
        })
        .then(({ blob, fileName }) => {
          if (blob instanceof Error) {
            throw blob;
          }
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = fileName;
          link.click();

          URL.revokeObjectURL(link.href);

          // sessionStorage.removeItem('biodataData')
          setIsLoading(false);
          setAlert({ open: true, type: 'success', children: 'Biodata Successfully Downloaded.' });

        })
        .catch((err) => {
          setIsLoading(false);
          if (err.message.startsWith('Bad Request')) {
            setAlert({ open: true, type: 'error', children: err.message });
          } else if (err.message.startsWith('Rate Limit')) {
            setAlert({ open: true, type: 'error', children: err.message });
          } else if (err.message.startsWith('Internal Server Error')) {
            setAlert({ open: true, type: 'error', children: err.message });
          } else {
            setAlert({ open: true, type: 'error', children: 'An error occurred while downloading.' });
          }

        })
    }
  };

  const handleGenerateLink = () => {
    return (event) => {
      event.preventDefault();
      setIsLoadingLink(true);

      var form = new FormData();

      for (const key in templateData.current) {
        if (isObject(templateData.current[key]) && !isArray(templateData.current[key])) {
          for (const subKey in templateData.current[key]) {
            if (templateData.current[key][subKey]) {
              form.append(key + subKey.charAt(0).toUpperCase() + subKey.slice(1), templateData.current[key][subKey]);
            }
          }
        }
        else if (isArray(templateData.current[key])) {
          form.append(key, JSON.stringify(templateData.current[key]));
        }
        else {
          if (templateData.current[key]) {
            form.append(key, templateData.current[key]);
          }
        }
      }

      fetch(SHARE_BIODATA, {
        method: 'POST',
        body: form,
      })
        .then((response) => {
          if (!response.ok) {
            if (response.status === 409) {
              return response.json();
            } else if (response.status === 400) {
              throw new Error('Bad Request: Please check your input.');
            } else if (response.status === 429) {
              throw new Error('Rate Limit: Too many downloads from your device.');
            } else {
              return 'Internal Server Error: Please try again later.';
            }
          }
          return response.json();
        })
        .then((result) => {
          if (result instanceof Error) {
            throw result;
          } else {
            const biodataURL = result.link;
            navigator.clipboard.writeText(biodataURL);
            if (navigator.share) {
              navigator.share({
                title: 'Checkout Biodata',
                text: 'Use below link to view biodata.',
                url: biodataURL, // Optional: URL to share
              });
            }
          }
          // sessionStorage.removeItem('biodataData')
          setIsLoadingLink(false);
          setAlert({ open: true, type: 'success', children: 'Biodata Link Copied.' });

        })
        .catch((err) => {
          setIsLoadingLink(false);
          if (err.message.startsWith('Bad Request')) {
            setAlert({ open: true, type: 'error', children: err.message });
          } else if (err.message.startsWith('Rate Limit')) {
            setAlert({ open: true, type: 'error', children: err.message });
          } else if (err.message.startsWith('Internal Server Error')) {
            setAlert({ open: true, type: 'error', children: err.message });
          } else {
            setAlert({ open: true, type: 'error', children: 'An error occurred while downloading.' });
          }

        })
    }

  };
  return (
    <Fragment>
      <AlertNew {...alert} duration={5000} onClose={() => setAlert({ ...alert, open: false })} />
      <div className="biodata-preview" id="biodata-preview">
        <div className="page-container container">
          <div className="col-md-2 col-0"></div>
          <div className="col-md-4 pt-2">
            <BiodataCanvas templateData={templateData} />
            <div className="button-container">
              <Button
                variant="contained"
                color="secondary"
                startIcon={<EditIcon />}
                onClick={handleEditClick}
                className="half-width-button"
              >
                {transliterationData.buttonText.editButtonText}
              </Button>

              <Button
                variant="contained"
                color="secondary"
                startIcon={<RefreshIcon sx={{ width: "21px", height: "21px" }} />}
                onClick={handleChangeTemplate}
                className="half-width-button"
              >
                {transliterationData.buttonText.changeTemplateText}
              </Button>
            </div>
          </div>

          <div className="col-md-4 pb-4 pt-1">
            <div className="text-center txt">
              <div>{transliterationData.mrpText.mrpTextL1}</div>
              <div className="mt-2">{transliterationData.mrpText.mrpTextL2}</div>
              <div className="mt-2 mb-3">{transliterationData.mrpText.mrpTextL3}</div>
            </div>
            <div>
            <div className="button-container">
              
                <Button
                  variant="outlined"
                  color="success"
                  sx={{
                    border: '1.5px solid',
                    '&:hover': {
                      backgroundColor: green[800],
                      color: '#fff',
                    },
                  }}
                  startIcon={
                    isLoading ? <CircularProgress size={24} thickness={4} value={100} /> : <DownloadIcon />
                  }
                  onClick={handleDownloadRequest()}
                  disabled={isLoading}  // Disable the button when loading
                >
                  {isLoading ? transliterationData.buttonText.generatingText : transliterationData.buttonText.downloadText}
                </Button>
                <Button
                  variant="outlined"
                  color="info"
                  sx={{
                    border: '1.5px solid',
                    '&:hover': {
                      backgroundColor: '#2e9dd9',
                      color: '#fff',
                    },
                  }}
                  startIcon={
                    isLoadingLink ? <CircularProgress size={24} thickness={4} value={100} /> : <LinkIcon />
                  }
                  onClick={handleGenerateLink()}
                  disabled={isLoadingLink}  // Disable the button when loading
                >
                  {isLoadingLink ? transliterationData.buttonText.generatingText : transliterationData.buttonText.shareLinkText}
                </Button>

              
              </div>

            </div>
            <div className="card rounded-3 mt-3 shadow-sm">
              <div className="card-header sslbg">
                <div
                  className="fs-5 fw-normal mycol"
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  {transliterationData.upiDonation.headingText}
                </div>
              </div>
              <div className="card-body">
                <ul className="txt">
                  <li>{transliterationData.upiDonation.tipText}</li>
                  <br></br>
                </ul>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <img src='upi-qr.jpg' alt="QR Code" width={180} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default PreviewPage;