export const marathiTemplates = [
    { 'id': 'bg1', 'template_name': 'marathi-biodata-maker.jpg' },
    { 'id': 'bg2', 'template_name': 'marriage-biodata-maker-in-marathi.jpg' },
    { 'id': 'bg3', 'template_name': 'marriage-biodata-format-in-marathi.jpg' },
    { 'id': 'bg4', 'template_name': 'biodata-in-marathi.jpg' },
    { 'id': 'bg5', 'template_name': 'biodata-format-marathi.jpg' },
    { 'id': 'bg6', 'template_name': 'muslim-marriage-biodata-format-in-marathi.jpg' },
    { 'id': 'bg7', 'template_name': 'bio-data-for-marriage-in-marathi.jpg' },
    { 'id': 'bg8', 'template_name': 'biodata-for-marriage-in-marathi.jpg' },
    { 'id': 'bg9', 'template_name': 'marriage-biodata-format-marathi-1-with-photo.jpg' },
    { 'id': 'bg10', 'template_name': 'marathi-biodata-format.jpg' },
    { 'id': 'bg11', 'template_name': 'marathi-biodata.jpg' },
    { 'id': 'bg12', 'template_name': 'buddhist-marathi-marriage-biodata.jpg' },
    { 'id': 'bg13', 'template_name': 'lagna-biodata-format-in-marathi.jpg' },
    { 'id': 'bg14', 'template_name': 'lagnacha-biodata.jpg' },
    { 'id': 'bg15', 'template_name': 'marathi-biodata-format-pdf.jpg' },
    { 'id': 'bg16', 'template_name': 'marathi-marriage-biodata-word-format-download.jpg' },
    { 'id': 'bg17', 'template_name': 'online-biodata-maker-for-marriage-in-marathi-free.jpg' },
    { 'id': 'bg18', 'template_name': 'biodata-format-in-marathi.jpg' },
    { 'id': 'bg19', 'template_name': 'online-biodata-maker-for-marriage-in-marathi.jpg' },
    { 'id': 'bg20', 'template_name': 'marriage-biodata-format-marathi-1-without-photo.jpg' },
    { 'id': 'bg21', 'template_name': 'parichay-patra-in-marathi.jpg' },
    { 'id': 'bg22', 'template_name': 'marriage-biodata-format-in-marathi-word-file-download.jpg' },
    { 'id': 'bg23', 'template_name': 'boy-biodata-for-marriage-in-marathi.jpg' },
    { 'id': 'bg24', 'template_name': 'marathi-biodata-for-marriage.jpg' }
]

export const englishTemplates = [
    { 'id': 'bg1', 'template_name': 'bg1.jpg' },
    { 'id': 'bg2', 'template_name': 'bg2.jpg' },
    { 'id': 'bg3', 'template_name': 'bg3.jpg' },
    { 'id': 'bg4', 'template_name': 'bg4.jpg' },
    { 'id': 'bg5', 'template_name': 'bg5.jpg' },
    { 'id': 'bg6', 'template_name': 'bg6.jpg' },
    { 'id': 'bg7', 'template_name': 'bg7.jpg' },
    { 'id': 'bg8', 'template_name': 'bg8.jpg' },
    { 'id': 'bg9', 'template_name': 'bg9.jpg' },
    { 'id': 'bg10', 'template_name': 'bg10.jpg' },
    { 'id': 'bg11', 'template_name': 'bg11.jpg' },
    { 'id': 'bg12', 'template_name': 'bg12.jpg' },
    { 'id': 'bg13', 'template_name': 'bg13.jpg' },
    { 'id': 'bg14', 'template_name': 'bg14.jpg' },
    { 'id': 'bg15', 'template_name': 'bg15.jpg' },
    { 'id': 'bg16', 'template_name': 'bg16.jpg' },
    { 'id': 'bg17', 'template_name': 'bg17.jpg' },
    { 'id': 'bg18', 'template_name': 'bg18.jpg' },
    { 'id': 'bg19', 'template_name': 'bg19.jpg' },
    { 'id': 'bg20', 'template_name': 'bg20.jpg' },
    { 'id': 'bg21', 'template_name': 'bg21.jpg' },
    { 'id': 'bg22', 'template_name': 'bg22.jpg' },
    { 'id': 'bg23', 'template_name': 'bg23.jpg' },
    { 'id': 'bg24', 'template_name': 'bg24.jpg' }
]

export const hindiTemplates = [
    { 'id': 'bg1', 'template_name': 'bg1.jpg' },
    { 'id': 'bg2', 'template_name': 'bg2.jpg' },
    { 'id': 'bg3', 'template_name': 'bg3.jpg' },
    { 'id': 'bg4', 'template_name': 'bg4.jpg' },
    { 'id': 'bg5', 'template_name': 'bg5.jpg' },
    { 'id': 'bg6', 'template_name': 'bg6.jpg' },
    { 'id': 'bg7', 'template_name': 'bg7.jpg' },
    { 'id': 'bg8', 'template_name': 'bg8.jpg' },
    { 'id': 'bg9', 'template_name': 'bg9.jpg' },
    { 'id': 'bg10', 'template_name': 'bg10.jpg' },
    { 'id': 'bg11', 'template_name': 'bg11.jpg' },
    { 'id': 'bg12', 'template_name': 'bg12.jpg' },
    { 'id': 'bg13', 'template_name': 'bg13.jpg' },
    { 'id': 'bg14', 'template_name': 'bg14.jpg' },
    { 'id': 'bg15', 'template_name': 'bg15.jpg' },
    { 'id': 'bg16', 'template_name': 'bg16.jpg' },
    { 'id': 'bg17', 'template_name': 'bg17.jpg' },
    { 'id': 'bg18', 'template_name': 'bg18.jpg' },
    { 'id': 'bg19', 'template_name': 'bg19.jpg' },
    { 'id': 'bg20', 'template_name': 'bg20.jpg' },
    { 'id': 'bg21', 'template_name': 'bg21.jpg' },
    { 'id': 'bg22', 'template_name': 'bg22.jpg' },
    { 'id': 'bg23', 'template_name': 'bg23.jpg' },
    { 'id': 'bg24', 'template_name': 'bg24.jpg' }
]
