import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import "./styles/about-component.css";
import { FounderComponent } from './index'

function AboutComponent() {
    const location = useLocation();
    return (
        <div className="components-wrapper">
            <section className="component-section">
                <div className="container">
                    <div className="component-row gap">
                        <div className="textImgCol col">
                            <h1 className="page-title">About Us</h1>
                            <h2 className="about-heading-description"> Crafting beautiful biodata, celebrating Wedding 👩‍❤️‍👨
                            </h2>
                            <p className="about-para"> Biodatawala is a free online tool for creating marriage biodata with ease.
                                Featuring a variety of rich templates, it ensures your documents are professionally formatted without any cost.
                                Say goodbye to expensive services and complicated softwares. With Biodatawala, you can generate your biodata in minutes.
                                This innovative tool is developed by Vaibhav Wagaskar from Pune, dedicated to providing a seamless and
                                accessible experience for all users.
                            </p>
                            <Link
                                to="/"
                                className={`cbio-btn cbio-btn-style ${location.pathname === '/' ? 'active' : ''}`}
                                rel="noopener noreferrer"
                            >
                                Create Free Biodata
                            </Link>
                        </div>
                        <div className="textImgCol col"> 
                            <div className="about-clip-art">
                                <picture>
                                    <img decoding="async" loading="lazy"
                                        src="CoupleOnSwingC.png"
                                        className="about-clip-art"
                                        alt="biodata couple"
                                    />
                                </picture>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <FounderComponent/>
        </div>
    );
}

export default AboutComponent;