import React, { Fragment, useRef, useState, useEffect } from 'react';
import { cloneDeep } from 'lodash';
import Button from '@mui/material/Button';
import { useNavigate, useLocation  } from 'react-router-dom';
import PreviewIcon from '@mui/icons-material/Preview';
import { initialDataMarathi, initialDataEnglish, initialDataHindi, sampleBioData, sampleBioDataEnglish, sampleBioDataHindi, Marathi, English, Hindi } from '../../common';
import {AlertNew } from '../../components'
import { Heading, PersonalData, HoroscopeData, EducationAndIncomeData, FamilyData, ContactData, UploadImg } from './'

import "./styles/form.css";

// Function to dynamically import initialData based on Language
const importInitialData = (Language) => {
  switch (Language) {
    case 'Marathi':
      return initialDataMarathi
    case 'English':
      return initialDataEnglish
    case 'Hindi':
      return initialDataHindi
    default:
      throw new Error(`Unsupported language: ${Language}`);
  }
};

const importTransliterationData = (Language) => {
  switch (Language) {
    case 'Marathi':
      return Marathi
    case 'English':
      return English
    case 'Hindi':
      return Hindi
    default:
      throw new Error(`Unsupported language: ${Language}`);
  }
};

const importSampleData = (Language) => {
  switch (Language) {
    case 'Marathi':
      return sampleBioData
    case 'English':
      return sampleBioDataEnglish
    case 'Hindi':
      return sampleBioDataHindi
    default:
      throw new Error(`Unsupported language: ${Language}`);
  }
};

function Form() {
  const location = useLocation();
  const { templateImage, Language } = location.state || {};
  const { templateData: initialTemplateData, Language: page3Language, templateImage: page3templateImage } = location.state || {};
  const navigate  = useNavigate ();

  const templateImageFromState = templateImage  || page3templateImage;
  const languageFromState = Language || page3Language;
  const initialData = importInitialData(languageFromState);
  const transliterationData = importTransliterationData(languageFromState)

  const templateData = useRef(cloneDeep(initialTemplateData || initialData));
  const headingRef = useRef();
  const personalDetailsRef = useRef();
  const horoscopeDataRef = useRef();
  const educationAndIncomeRef = useRef();
  const familyDetailsRef = useRef();
  const contactDetailsRef = useRef();
  const userPhotoRef = useRef();

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0); // Small timeout to ensure layout is complete
  }, []);
  
  useEffect(() => {
    // Update form fields with initialTemplateData when available
    if (initialTemplateData) {
        templateData.current = cloneDeep(initialTemplateData);
        // Populate form fields using refs
        headingRef.current.set(templateData.current.heading);
        personalDetailsRef.current.set(templateData.current.personalDetails);
        horoscopeDataRef.current.set(templateData.current.horoscopeDetails);
        educationAndIncomeRef.current.set(templateData.current.educationAndIncome);
        familyDetailsRef.current.set(templateData.current.familyDetails);
        contactDetailsRef.current.set(templateData.current.contactDetails);
        userPhotoRef.current.set(templateData.current.userPhoto);
    }
}, [initialTemplateData]);

  useEffect(() => {
    // Update templateData.current with templateImage when available
    if (templateImage && Language) {
      templateData.current.templateImage = templateImage;
      templateData.current.Language = Language;
    }

  }, [templateImage, Language]);

  useEffect(() => {
    if (!templateImageFromState || !languageFromState) {
      navigate('/');
    }
  }, [templateImageFromState, languageFromState, navigate]);


  const [alert, setAlert] = useState({ open: false, type: '', children: '' });


  const handleReset = () => {
    templateData.current = cloneDeep(initialData);

    headingRef.current.reset(templateData.current.heading);
    personalDetailsRef.current.reset(templateData.current.personalDetails);
    horoscopeDataRef.current.reset(templateData.current.horoscopeDetails);
    educationAndIncomeRef.current.reset(templateData.current.educationAndIncome);
    familyDetailsRef.current.reset(templateData.current.familyDetails);
    contactDetailsRef.current.reset(templateData.current.contactDetails);
    userPhotoRef.current.reset(templateData.current.userPhoto);

  }
  const onSetSampleData = () => {
    templateData.current = cloneDeep(importSampleData(Language));
    templateData.current.templateImage = templateImage;
    templateData.current.Language = Language

    headingRef.current.set(templateData.current.heading);
    personalDetailsRef.current.set(templateData.current.personalDetails);
    horoscopeDataRef.current.set(templateData.current.horoscopeDetails);
    educationAndIncomeRef.current.set(templateData.current.educationAndIncome);
    familyDetailsRef.current.set(templateData.current.familyDetails);
    contactDetailsRef.current.set(templateData.current.contactDetails);
    userPhotoRef.current.set(templateData.current.userPhoto);

  }

  const onSetSessionData = () => {
    let user_data = cloneDeep(templateData.current);
    sessionStorage.setItem('biodataData', JSON.stringify(user_data));
  }

  const handlePreview = () => {
    return (event) => {
      const personalDetailsValidity = personalDetailsRef.current.validate();
      if(personalDetailsValidity) {
        onSetSessionData();
        handleReset();
        navigate('/preview-biodata', {
          state: { templateImage: templateImageFromState, Language: languageFromState }
        });
      }
      else {
        setAlert({ open: true, type: 'error', children: transliterationData.errorMessage });
      }
    }
  };

  return (
    <Fragment>
      <AlertNew {...alert} duration={5000} onClose={() => setAlert({ ...alert, open: false })} />
      <div className="container d-print-none">
        {/* Form starts */}
        <form action="submit" method="post" className="material-form" id="myform">
          {/* <Button variant="contained" size="small" onClick={onSetSampleData}>Set Sample Data</Button> */}
          {/* Biodata Title Selector */}
          <Heading templateData={templateData} transliterationData={transliterationData.heading} ref={headingRef} />

          <PersonalData templateData={templateData} transliterationData={transliterationData.personalDetails} ref={personalDetailsRef} />

          <HoroscopeData templateData={templateData} transliterationData={transliterationData.horoscopeDetails} ref={horoscopeDataRef} />

          <EducationAndIncomeData templateData={templateData} transliterationData={transliterationData.educationAndIncome} ref={educationAndIncomeRef} />

          {/* Family information */}
          <FamilyData templateData={templateData} transliterationData={transliterationData.familyDetails} ref={familyDetailsRef} />

          <ContactData templateData={templateData} transliterationData={transliterationData.contactDetails} ref={contactDetailsRef} />

          {/* Upload Image component */}
          <UploadImg templateData={templateData} transliterationData={transliterationData.userPhoto} ref={userPhotoRef} />

          <div className="row text-center">
            <div className="col pt-4">
              <Button
                variant="contained"
                color="primary"
                startIcon={ <PreviewIcon /> }
                onClick={handlePreview()}
                className="btn btn-outline-primary shadow"
              >
                Preview Biodata
              </Button>
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  );
}

export default Form;
