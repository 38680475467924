export const colorMap = {
    bg1: {
        headingHex: '#AA8112',
        fontHex: '#000000',
    },
    bg2: {
        headingHex: '#6E180C',
        fontHex: '#000000',
    },
    bg6: {
        headingHex: '#F6BE7F',
        fontHex: '#FFFFE0',
    },
    bg7: {
        headingHex: '#FFD700',
        fontHex: '#FFFDD0',
    },
    bg9: {
        headingHex: '#AA8112',
        fontHex: '#000000',
    },
    bg10: {
        headingHex: '#B86B1A',
        fontHex: '#000000',
    },
    bg11: {
        headingHex: '#FFD700',
        fontHex: '#FFFDD0',
    },
    bg12: {
        headingHex: '#012A55',
        fontHex: '#000000',
    },
    bg13: {
        headingHex: '#A7236F',
        fontHex: '#000000',
    },
    bg14: {
        headingHex: '#FFD700',
        fontHex: '#FFFDD0',
    },
    bg15: {
        headingHex: '#FF5544',
        fontHex: '#000000',
    },
    bg16: {
        headingHex: '#D3D3D3',
        fontHex: '#E0FFFF',
    },
    bg17: {
        headingHex: '#BE832F',
        fontHex: '#000000',
    },
    bg18: {
        headingHex: '#710C0D',
        fontHex: '#000000',
    },
    bg19: {
        headingHex: '#E9E559',
        fontHex: '#FFFFE0',
    },
    bg20: {
        headingHex: '#B78217',
        fontHex: '#000000',
    },
    bg21: {
        headingHex: '#C29309',
        fontHex: '#000000',
    },
    bg22: {
        headingHex: '#C81F34',
        fontHex: '#000000',
    },
    bg23: {
        headingHex: '#B6752E',
        fontHex: '#000000',
    },
}