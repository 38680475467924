export const LpMarathi = {
    headingTxt: {
        grTextL1: "खालील बायोडाटा ",
        grTextL2: "या संकेतस्थाळावर बनवला आहे. तुमचा बनवण्यासाठी खालील बटनावर क्लिक करा."
    },
    ButtonText: "तुमचा आत्ताच बनवा"
}
export const LpEnglish = {
    headingTxt: {
        grTextL1: "Below biodata was created using ",
        grTextL2: "Click below button to create yours!"
    },
    ButtonText: "Create Yours Now"
}
export const LpHindi = {
    headingTxt: {
        grTextL1: "नीचे का बायोडाटा ",
        grTextL2: "इस वेबसाइट पर बनाया गया है। अपना बनाने के लिए नीचे दिए गए बटन पर क्लिक करें।"
    },
    ButtonText: "अपना अभी बनाएं"
}