import { Fragment } from "react";
import { Helmet } from "react-helmet";
import { NavBarNew, Footer } from '../components';
import BannerImg from '../components/BannerImg';
import HomeBody from '../components/HomeBody';
import { homeSEO } from '../common';

function Home() {
  return (
    <Fragment>
      <Helmet>
          <meta name="description" content={homeSEO.description} />
          <meta
            name="keywords"
            content={homeSEO.keywords.join(", ")}
          />
        </Helmet>
      <div className='HomePage'>
        <NavBarNew />
        <BannerImg />
        <HomeBody />
        <Footer />
      </div>
    </Fragment>
  );
}

export default Home;
