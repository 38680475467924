import { NavBarNew, Footer } from '../../components';
import { TermsConditionComponent } from '../../PoliciesComponent'

function TermsConditions() {
  return (
    <div className='TermsConditions'>
      <NavBarNew />
      <TermsConditionComponent />
      <Footer />
    </div>
  );
}

export default TermsConditions;
