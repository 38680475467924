import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import "./styles/services.css";

function ServicesComponent() {
    const location = useLocation();
    return (
        <div className="components-wrapper">
            <section className="component-section">
                <div className="container">
                    <div className="component-row gap">
                        <div className="textImgCol col">
                            <h1 className="page-title">Our Services</h1>
                            <h2 className="services-description">Simplify Your 
                                <span className="green-doodle"> Marriage Biodata </span>
                                Creation Experience.
                            </h2>
                            <p className="services-para">
                            Discover our free marriage biodata service, crafted to capture your unique story 
                            with elegance and ease. We offer a variety of templates designed to suit diverse 
                            preferences and regional languages, ensuring your biodata reflects your cultural 
                            heritage. Create personalized biodata effortlessly, celebrating your journey 
                            together with a touch of tradition and modern flair. Start crafting your perfect 
                            biodata today, free of cost.
                            </p>
                            <Link
                                to="/"
                                className={`cbio-btn cbio-btn-style ${location.pathname === '/' ? 'active' : ''}`}
                                rel="noopener noreferrer"
                            >
                                Explore Templates
                            </Link>
                        </div>
                        <div className="textImgCol col">
                            <div className="services-clip-art">
                                <picture>
                                    <img decoding="async" loading="lazy"
                                        src="services.png"
                                        className="services-clip-art"
                                        alt="biodata couple"
                                    />
                                </picture>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default ServicesComponent;