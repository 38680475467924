import React, { useState, Fragment } from "react";
import { AlertNew } from "../components";
import "./styles/footer.css";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faEnvelope, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { YouTube as YouTubeIcon, X as XIcon, Instagram as InstagramIcon, LinkedIn as LinkedInIcon } from '@mui/icons-material';

// Routes
import { API_ENDPOINT } from "../common";
const SUBSCRIBE = `${API_ENDPOINT}/subscribe`;

function Footer() {
    const [alert, setAlert] = useState({ open: false, type: "", children: "" });
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");

    const validateEmail = (email) => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email) ? "" : "Please enter valid email address.";
    };

    const handleChange = (e) => {
        const value = e.target.value;
        setEmail(value);
        // Validate email.
        const validationError = validateEmail(value);
        setError(validationError);
    };

    const handleSubmit = async () => {
        const validationError = validateEmail(email);
        setError(validationError);

        if (!validationError) {
            try {
                const response = await fetch(SUBSCRIBE, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ email }),
                });

                if (!response.ok) {
                    throw new Error("Subscription failed");
                }

                setAlert({ open: true, type: "success", children: "Congratulations, you've successfully subscribed!"});
                setEmail("");
            } catch (error) {
                setAlert({open: true, type: "error", children: "An error occurred. Please try again later." });
            }
        }
        else {
            setAlert({ open: true, type: "error", children: "Subscription Failed." });
        }
    };

    return (
        <Fragment>
            <AlertNew {...alert} duration={3000} onClose={() => setAlert({ ...alert, open: false })} />
            <div className="footer-sm">
                <div className="container-fluid text-light footer mt-4 py-2 wow fadeIn" data-wow-delay="0.1s">
                    <div className="container py-5">
                        <div className="row g-4 justify-content-center">
                            <div className="col-lg-3 col-md-2">
                                <h4 className="text-white mb-4">Our Office</h4>
                                <p className="mb-2"><FontAwesomeIcon icon={faMapMarkerAlt} className="me-3" />Aundh, Pune, India</p>
                                {/* <p className="mb-3"><FontAwesomeIcon icon={faEnvelope} className="me-3" />help@biodatawala.com</p> */}
                                <p className="mail mb-3">
                                    <FontAwesomeIcon icon={faEnvelope} style={{ marginRight: '7px' }} />
                                    <a href="mailto:help@biodatawala.com" style={{ margin: '5px', textDecoration: 'none' }} >
                                        help@biodatawala.com
                                    </a>
                                </p>
                                <div className="d-flex pt-2">
                                    <a className="btn btn-square btn-outline-light rounded-circle d-flex align-items-center justify-content-center me-2"
                                        href="https://www.youtube.com/@aifunction9325" style={{ width: '40px', height: '40px' }}><YouTubeIcon /></a>
                                    <a className="btn btn-square btn-outline-light rounded-circle d-flex align-items-center justify-content-center me-2"
                                        href="https://x.com/vsw_04/" style={{ width: '40px', height: '40px' }}><XIcon /></a>
                                    <a className="btn btn-square btn-outline-light rounded-circle d-flex align-items-center justify-content-center me-2"
                                        href="https://www.instagram.com/biodatawala_official/" style={{ width: '40px', height: '40px' }}><InstagramIcon /></a>
                                    <a className="btn btn-square btn-outline-light rounded-circle d-flex align-items-center justify-content-center me-2"
                                        href="https://www.linkedin.com/in/vaibhavwagaskar4/" style={{ width: '40px', height: '40px' }}><LinkedInIcon /></a>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-2">
                                <h4 className="text-white mb-4">Quick Links</h4>
                                <a className="btn btn-link" href="about">
                                    <FontAwesomeIcon icon={faAngleRight} /> About Us
                                </a>
                                <a className="btn btn-link" href="contact-us">
                                    <FontAwesomeIcon icon={faAngleRight} /> Contact Us
                                </a>
                                <a className="btn btn-link" href="services">
                                    <FontAwesomeIcon icon={faAngleRight} /> Our Services
                                </a>
                                <a className="btn btn-link" href="blogs">
                                    <FontAwesomeIcon icon={faAngleRight} /> Blogs
                                </a>
                            </div>
                            <div className="col-lg-3 col-md-2">
                                <h4 className="text-white mb-4">Support</h4>
                                <a className="btn btn-link" href="terms-conditions">
                                    <FontAwesomeIcon icon={faAngleRight} /> Terms & Condition
                                </a>
                                <a className="btn btn-link" href="privacy-policy">
                                    <FontAwesomeIcon icon={faAngleRight} /> Privacy Policy
                                </a>
                                <a className="btn btn-link" href="refund-policy">
                                    <FontAwesomeIcon icon={faAngleRight} /> Refund Policy
                                </a>
                                <a className="btn btn-link" href="shipping-delivery">
                                    <FontAwesomeIcon icon={faAngleRight} /> Shipping & Delivery Policy
                                </a>
                            </div>
                            <div className="col-lg-4 col-md-2">
                                <h4 className="text-white mb-4">Newsletter</h4>
                                <p>Subscribe for latest templates & Updates</p>
                                <div className="position-relative w-100">
                                    <input 
                                        className={`form-control bg-white border-0 w-100 py-3 ps-4 pe-5 ${
                                            error ? "is-invalid" : ""
                                        }`}
                                        type="text"
                                        placeholder="Your email"
                                        value={email}
                                        onChange={handleChange} />
                                    <div className="invalid-feedback">{error}</div>
                                    <button type="button"
                                        className="signup-btn py-2 position-absolute top-0 end-0 mt-2 me-2"
                                        onClick={handleSubmit}
                                    >
                                        SignUp
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="container-fluid copyright py-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                                &copy; 2024 <a className="border-bottom" href={`${process.env.PUBLIC_URL}/`}>Biodatawala.com</a>, All Rights Reserved.
                            </div>
                            <div className="col-md-6 text-center text-md-end">
                                Designed with ❤️ at <a className="border-bottom" href={`${process.env.PUBLIC_URL}/`}>Biodatawala.com</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default Footer;
