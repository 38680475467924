export const API_ENDPOINT = '/api';

export const initialDataMarathi = {
    heading: {
        iconGodPhoto: 'hindu/Ganesha1.png',
        textGodName: '।। श्री गणेशाय नमः ।।',
        titleText: 'बायोडाटा'
    },
    personalDetails: {
        nameKey: 'नाव',
        nameValue: '',
        dobKey: 'जन्मदिनांक',
        dobDay: '',
        dobMonth: '',
        dobYear: '',
        birthTimeKey: 'जन्मवेळ',
        birthTimeValue: '',
        birthPlaceKey: 'जन्म स्थळ',
        birthPlaceValue: '',
        heightKey: 'उंची',
        heightValue: '',
        colorKey: 'रंग',
        colorValue: '',
        bloodGroupKey: 'रक्तगट',
        bloodGroupValue: '',
    },

    horoscopeDetails: {

        // Horoscopic Info
        signNameKey: 'जन्म नाव',
        signNameValue: '',
        religionCasteKey: 'धर्म-जात',
        religionCasteValue: '',
        signKey: 'राशी',
        signValue: '',
        ganKey: 'गण',
        ganValue: '',
        naadiKey: 'नाडी',
        naadiValue: '',
        // Family Horoscope
        familyGodKey: 'कुलदैवत',
        familyGodValue: '',
        gotraKey: 'गोत्र',
        gotraValue: '',
        devakKey: 'देवक',
        devakValue: '',
        nakshyatraKey: 'नक्षत्र',
        nakshyatraValue: '',

    },
    educationAndIncome: {
        qualificationKey: 'शिक्षण',
        qualificationValue: '',
        workKey: 'नोकरी/व्यवसाय',
        workValue: '',
        earningKey: 'वेतन/उत्पन्न',
        earningValue: '',

        extraHeading1Key: '',
        extraHeading1Value: '',
    },
    familyDetails: {
        familyDetailHeading: 'कौटुंबिक माहिती',
        fatherNameKey: 'वडिलांचे नाव',
        fatherNameValue: '',
        fatherOccupationKey: 'वडिलांचा व्यवसाय',
        fatherOccupationValue: '',
        motherNameKey: 'आईचे नाव',
        motherNameValue: '',
        noSisterKey: 'बहीण',
        noSisterValue: '',
        noBrotherKey: 'भाऊ',
        noBrotherValue: '',
        maternalUncleKey: 'मामा',
        maternalUncleValue: '',
        relativesNamesKey: 'नातेसंबंध',
        relativesNamesValue: '',
        expectationsKey: 'अपेक्षा',
        expectationsValue: '',

        extraHeading2Key: '',
        extraHeading2Value: '',
        extraHeading3Key: '',
        extraHeading3Value: '',
        extraHeading4Key: '',
        extraHeading4Value: '',
        extraHeading5Key: '',
        extraHeading5Value: '',

    },
    contactDetails: {
        contactHeading: 'संपर्क',
        addressKey: 'पत्ता',
        addressValue: '',
        addressLine2Key: '',
        addressLine2Value: '',
        mobileNoKey: 'मो.नं.',
        mobileNoValue: '',

    },
    userPhoto: null,

}
export const initialDataEnglish = {
    heading: {
        iconGodPhoto: 'hindu/Ganesha1.png',
        textGodName: '।। Shree Ganeshay Namh: ।।',
        titleText: 'Biodata'
    },
    personalDetails: {
        nameKey: 'Name',
        nameValue: '',
        dobKey: 'Birth Date',
        dobDay: '',
        dobMonth: '',
        dobYear: '',
        birthTimeKey: 'Birth Time',
        birthTimeValue: '',
        birthPlaceKey: 'Birth Place',
        birthPlaceValue: '',
        heightKey: 'Height',
        heightValue: '',
        colorKey: 'Complexion',
        colorValue: '',
        bloodGroupKey: 'Blood Group',
        bloodGroupValue: '',
    },

    horoscopeDetails: {

        // Horoscopic Info
        signNameKey: 'Birth Name',
        signNameValue: '',
        religionCasteKey: 'Religion Caste',
        religionCasteValue: '',
        signKey: 'Zodiac Sign',
        signValue: '',
        ganKey: 'Gan',
        ganValue: '',
        naadiKey: 'Naadi',
        naadiValue: '',
        // Family Horoscope
        familyGodKey: 'Clan Deity',
        familyGodValue: '',
        gotraKey: 'Lineage(Gotra)',
        gotraValue: '',
        devakKey: 'Devak',
        devakValue: '',
        nakshyatraKey: 'Nakshatra',
        nakshyatraValue: '',

    },
    educationAndIncome: {
        qualificationKey: 'Education',
        qualificationValue: '',
        workKey: 'Job/Business',
        workValue: '',
        earningKey: 'Salary/Income',
        earningValue: '',

        extraHeading1Key: '',
        extraHeading1Value: '',
    },
    familyDetails: {
        familyDetailHeading: 'Family Details',
        fatherNameKey: 'Father\'s Name',
        fatherNameValue: '',
        fatherOccupationKey: 'Father\'s Occupation',
        fatherOccupationValue: '',
        motherNameKey: 'Mother\'s Name',
        motherNameValue: '',
        noSisterKey: 'Sister',
        noSisterValue: '',
        noBrotherKey: 'Brother',
        noBrotherValue: '',
        maternalUncleKey: 'Maternal Uncle',
        maternalUncleValue: '',
        relativesNamesKey: 'Relatives',
        relativesNamesValue: '',
        expectationsKey: 'Expectations',
        expectationsValue: '',

        extraHeading2Key: '',
        extraHeading2Value: '',
        extraHeading3Key: '',
        extraHeading3Value: '',
        extraHeading4Key: '',
        extraHeading4Value: '',
        extraHeading5Key: '',
        extraHeading5Value: '',

    },
    contactDetails: {
        contactHeading: 'Contact Details',
        addressKey: 'Address',
        addressValue: '',
        addressLine2Key: '',
        addressLine2Value: '',
        mobileNoKey: 'Mob.No',
        mobileNoValue: '',

    },
    userPhoto: null,
}
export const initialDataHindi = {
    heading: {
        iconGodPhoto: 'hindu/Ganesha1.png',
        textGodName: '।। श्री गणेशाय नमः ।।',
        titleText: 'जीवन परिचय'
    },
    personalDetails: {
        nameKey: 'नाम',
        nameValue: '',
        dobKey: 'जन्म तिथि',
        dobDay: '',
        dobMonth: '',
        dobYear: '',
        birthTimeKey: 'जन्म समय',
        birthTimeValue: '',
        birthPlaceKey: 'जन्म स्थान',
        birthPlaceValue: '',
        heightKey: 'ऊंचाई',
        heightValue: '',
        colorKey: 'रंग',
        colorValue: '',
        bloodGroupKey: 'रक्त समूह',
        bloodGroupValue: '',
    },
    horoscopeDetails: {
        signNameKey: 'जन्म नाम',
        signNameValue: '',
        religionCasteKey: 'धर्म जाति',
        religionCasteValue: '',
        signKey: 'राशि',
        signValue: '',
        ganKey: 'गण',
        ganValue: '',
        naadiKey: 'नाड़ी',
        naadiValue: '',
        familyGodKey: 'कुल देवता',
        familyGodValue: '',
        gotraKey: 'गोत्र',
        gotraValue: '',
        devakKey: 'देवक',
        devakValue: '',
        nakshyatraKey: 'नक्षत्र',
        nakshyatraValue: '',
    },
    educationAndIncome: {
        qualificationKey: 'शिक्षा',
        qualificationValue: '',
        workKey: 'नौकरी/व्यापार',
        workValue: '',
        earningKey: 'वेतन/आय',
        earningValue: '',

        extraHeading1Key: '',
        extraHeading1Value: '',
    },
    familyDetails: {
        familyDetailHeading: 'परिवार के विवरण',
        fatherNameKey: 'पिता का नाम',
        fatherNameValue: '',
        fatherOccupationKey: 'पिता का पेशा',
        fatherOccupationValue: '',
        motherNameKey: 'मां का नाम',
        motherNameValue: '',
        noSisterKey: 'बहन',
        noSisterValue: '',
        noBrotherKey: 'भाई',
        noBrotherValue: '',
        maternalUncleKey: 'मामा',
        maternalUncleValue: '',
        relativesNamesKey: 'रिश्तेदार',
        relativesNamesValue: '',
        expectationsKey: 'अपेक्षा',
        expectationsValue: '',

        extraHeading2Key: '',
        extraHeading2Value: '',
        extraHeading3Key: '',
        extraHeading3Value: '',
        extraHeading4Key: '',
        extraHeading4Value: '',
        extraHeading5Key: '',
        extraHeading5Value: '',
    },
    contactDetails: {
        contactHeading: 'संपर्क विवरण',
        addressKey: 'पता',
        addressValue: '',
        addressLine2Key: '',
        addressLine2Value: '',
        mobileNoKey: 'मोबाइल नंबर',
        mobileNoValue: '',
    },
    userPhoto:null,
};

export const sampleBioData = {
    heading: {
        iconGodPhoto: 'hindu/Ganesha1.png',
        textGodName: '।। श्री गणेशाय नमः ।।',
        titleText: 'बायोडाटा'
    },
    personalDetails: {
        nameKey: 'नाव',
        nameValue: 'वैभव शरद वागसकर',
        dobKey: 'जन्म तारीख',
        dobDay: '1',
        dobMonth: 'मे',
        dobYear: '1998',
        birthTimeKey: 'जन्मवेळ',
        birthTimeValue: 'सकाळी १०:१५ मी.',
        birthPlaceKey: 'जन्म स्थळ',
        birthPlaceValue: 'घोटवी',
        heightKey: 'उंची',
        heightValue: '5 फूट 9 इंच',
        colorKey: 'रंग',
        colorValue: 'गोरा',
        bloodGroupKey: 'रक्तगट',
        bloodGroupValue: 'O+',

    },
    horoscopeDetails: {

        // Horoscopic Info
        signNameKey: 'नावरस नाव',
        signNameValue: 'केशव',
        religionCasteKey: 'धर्म-जात',
        religionCasteValue: 'हिंदू मराठा',
        nakshyatraKey: 'नक्षत्र',
        nakshyatraValue: 'पुनर्वसु',
        signKey: 'राशी',
        signValue: 'मिथुन',
        ganKey: 'गण',
        ganValue: 'देव',
        naadiKey: 'नाडी',
        naadiValue: 'आद्य',
        // Family Horoscope
        familyGodKey: 'कुलदैवत',
        familyGodValue: 'तुळजा भवानी',
        gotraKey: 'गोत्र',
        gotraValue: 'भारद्वाज',
        devakKey: 'देवक',
        devakValue: 'मोरपंख',

    },
    educationAndIncome: {
        qualificationKey: 'शिक्षण',
        qualificationValue: 'B.E. (Software Engineering)',
        workKey: 'नोकरी/व्यवसाय',
        workValue: 'Software Engineer (Camelot IT Lab)',
        earningKey: 'वेतन/उत्पन्न',
        earningValue: '१२ लाख वार्षिक',
        extraHeading1Key: 'कंपनी',
        extraHeading1Value: 'बायोडाटावाला डॉट कॉम',
    },
    familyDetails: {
        familyDetailHeading: 'कौटुंबिक माहिती',
        fatherNameKey: 'वडिलांचे नाव',
        fatherNameValue: 'शरद देवराम वागसकर',
        fatherOccupationKey: 'वडिलांचा व्यवसाय',
        fatherOccupationValue: 'शेती',
        motherNameKey: 'आईचे नाव',
        motherNameValue: 'मंगल शरद वागसकर',
        noSisterKey: 'बहीण',
        noSisterValue: 'नाही',
        noBrotherKey: 'भाऊ',
        noBrotherValue: '२ अविवाहित',
        maternalUncleKey: 'मामा',
        maternalUncleValue: 'बापुराव सोन्याबापु बारगुजे',
        relativesNamesKey: 'नातेसंबंध',
        relativesNamesValue: 'नागवडे, जगताप, जामदार, घाडगे, काटे, कुदांडे, बारगुजे',
        expectationsKey: 'अपेक्षा',
        expectationsValue: 'मनमिळावू',

        extraHeading2Key: 'भाऊ',
        extraHeading2Value: 'स्वप्निल शरद वागसकर',
        extraHeading3Key: 'बहिणीचे नाव',
        extraHeading3Value: 'मोनिका संतोष वागसकर',
        extraHeading4Key: 'मित्राचे नाव',
        extraHeading4Value: 'गौरव गाडे',
        extraHeading5Key: '',
        extraHeading5Value: '',

    },
    contactDetails: {
        contactHeading: 'संपर्क',
        addressKey: 'पत्ता',
        addressValue: 'मु.पो. सुरोडी, ता. श्रीगोंदा, जि. अ.नगर',
        addressLine2Key: '',
        addressLine2Value: '',
        mobileNoKey: 'मो.नं.',
        mobileNoValue: '9763732295',

    },
    userPhoto: null,
}

export const sampleBioDataEnglish = {
    heading: {
        iconGodPhoto: 'hindu/Ganesha1.png',
        textGodName: '।। Shree Ganeshay Namh: ।।',
        titleText: 'Biodata'
    },
    personalDetails: {
        nameKey: 'Name',
        nameValue: 'Vaibhav Sharad Wagaskar',
        dobKey: 'BirthDate',
        dobDay: '1',
        dobMonth: 'May',
        dobYear: '1998',
        birthTimeKey: 'BirthTime',
        birthTimeValue: 'Morning 10:15AM',
        birthPlaceKey: 'BirthPlace',
        birthPlaceValue: 'Ghotawi',
        heightKey: 'Height',
        heightValue: '5 Feet 9 Inches',
        colorKey: 'Complexion',
        colorValue: 'Fair',
        bloodGroupKey: 'Blood Group',
        bloodGroupValue: 'O+',

    },
    horoscopeDetails: {

        // Horoscopic Info
        signNameKey: 'Navras Name',
        signNameValue: 'Keshav',
        religionCasteKey: 'Religion Caste',
        religionCasteValue: 'Hindu Maratha',
        nakshyatraKey: 'Nakshatra',
        nakshyatraValue: 'Punarvasu',
        signKey: 'Zodiac Sign',
        signValue: 'Gemini',
        ganKey: 'Gan',
        ganValue: 'Dev',
        naadiKey: 'Naadi',
        naadiValue: 'Adya',
        // Family Horoscope
        familyGodKey: 'Clan Deity',
        familyGodValue: 'Tulaja Bhawani',
        gotraKey: 'Lineage(Gotra)',
        gotraValue: 'Bharadwaj',
        devakKey: 'Devak',
        devakValue: 'Morpankh',

    },
    educationAndIncome: {
        qualificationKey: 'Education',
        qualificationValue: 'B.E. (Software Engineering)',
        workKey: 'Job/Business',
        workValue: 'Software Engineer (Camelot IT Lab)',
        earningKey: 'Salary/Income',
        earningValue: '12 Lakhs per Annum',
        extraHeading1Key: 'Venture',
        extraHeading1Value: 'Biodatawala.com',
    },
    familyDetails: {
        familyDetailHeading: 'Family Details',
        fatherNameKey: 'Father\'s Name',
        fatherNameValue: 'Sharad Devram Wagaskar',
        fatherOccupationKey: 'Father\'s Occupation',
        fatherOccupationValue: 'Farming',
        motherNameKey: 'Mother\'s Name',
        motherNameValue: 'Mangal Sharad Wagaskar',
        noSisterKey: 'Sister',
        noSisterValue: 'No',
        noBrotherKey: 'Brother',
        noBrotherValue: '2 Unmarried',
        maternalUncleKey: 'Maternal Uncle',
        maternalUncleValue: 'Bapurao Sonyabapu Barguje',
        relativesNamesKey: 'Relatives',
        relativesNamesValue: 'Nagawade, Jagtap, Jamdar, Ghadage, Kate, Kudande, Barguje',
        expectationsKey: 'Expectation',
        expectationsValue: 'Mannered',

        extraHeading2Key: 'Brother',
        extraHeading2Value: 'Swapnil Sharad Wagaskar',
        extraHeading3Key: 'Sister\'s Name',
        extraHeading3Value: 'Monika Santosh Wagaskar',
        extraHeading4Key: 'Friend\'s Name',
        extraHeading4Value: 'Gaurav Gade',
        extraHeading5Key: '',
        extraHeading5Value: '',

    },
    contactDetails: {
        contactHeading: 'Contact Details',
        addressKey: 'Address',
        addressValue: 'At/Pt Surodi, Tal. Shrigonda, Dist. A.Nagar',
        addressLine2Key: '',
        addressLine2Value: '',
        mobileNoKey: 'Mob.No',
        mobileNoValue: '9763732295',

    },
    userPhoto: null,
}

export const sampleBioDataHindi = {
    heading: {
        iconGodPhoto: 'hindu/Ganesha1.png',
        textGodName: '।। श्री गणेशाय नमः ।।',
        titleText: 'जीवन परिचय'
    },
    personalDetails: {
        nameKey: 'नाम',
        nameValue: 'वैभव शरद वागस्कर',
        dobKey: 'जन्मतिथि',
        dobDay: '1',
        dobMonth: 'मई',
        dobYear: '1998',
        birthTimeKey: 'जन्म समय',
        birthTimeValue: 'सुबह 10:15 बजे',
        birthPlaceKey: 'जन्म स्थान',
        birthPlaceValue: 'घोटवी',
        heightKey: 'ऊंचाई',
        heightValue: '5 फीट 9 इंच',
        colorKey: 'रंग',
        colorValue: 'गोरा',
        bloodGroupKey: 'रक्त समूह',
        bloodGroupValue: 'O+',
    },
    horoscopeDetails: {
        signNameKey: 'नवरस नाम',
        signNameValue: 'केशव',
        religionCasteKey: 'धर्म जाति',
        religionCasteValue: 'हिंदू मराठा',
        nakshyatraKey: 'नक्षत्र',
        nakshyatraValue: 'पुनर्वसु',
        signKey: 'राशि',
        signValue: 'मिथुन',
        ganKey: 'गण',
        ganValue: 'देव',
        naadiKey: 'नाड़ी',
        naadiValue: 'आद्या',
        familyGodKey: 'कुल देवता',
        familyGodValue: 'तुलजा भवानी',
        gotraKey: 'गोत्र',
        gotraValue: 'भारद्वाज',
        devakKey: 'देवक',
        devakValue: 'मोरपंख',
    },
    educationAndIncome: {
        qualificationKey: 'शिक्षा',
        qualificationValue: 'बी.ई. (सॉफ्टवेयर इंजीनियरिंग)',
        workKey: 'नौकरी/व्यापार',
        workValue: 'सॉफ्टवेयर इंजीनियर (कैमलॉट आईटी लैब)',
        earningKey: 'वेतन/आय',
        earningValue: '12 लाख प्रति वर्ष',
        extraHeading1Key: 'उद्यम',
        extraHeading1Value: 'बायोडेटावाला.कॉम',
    },
    familyDetails: {
        familyDetailHeading: 'परिवार के विवरण',
        fatherNameKey: 'पिता का नाम',
        fatherNameValue: 'शरद देवराम वागस्कर',
        fatherOccupationKey: 'पिता का पेशा',
        fatherOccupationValue: 'कृषि',
        motherNameKey: 'मां का नाम',
        motherNameValue: 'मंगल शरद वागस्कर',
        noSisterKey: 'बहन',
        noSisterValue: 'नहीं',
        noBrotherKey: 'भाई',
        noBrotherValue: '2 अविवाहित',
        maternalUncleKey: 'मामा',
        maternalUncleValue: 'बापुराव सोन्याबापू बारगुजे',
        relativesNamesKey: 'रिश्तेदार',
        relativesNamesValue: 'नागवाडे, जगताप, जामदार, घडगे, काटे, कुदाण्डे, बारगुजे',
        expectationsKey: 'अपेक्षा',
        expectationsValue: 'शिष्टाचारी',
        extraHeading2Key: 'भाई',
        extraHeading2Value: 'स्वप्नील शरद वागस्कर',
        extraHeading3Key: 'बहन का नाम',
        extraHeading3Value: 'मोनिका संतोष वागस्कर',
        extraHeading4Key: 'दोस्त का नाम',
        extraHeading4Value: 'गौरव गाडे',
        extraHeading5Key: '',
        extraHeading5Value: '',
    },
    contactDetails: {
        contactHeading: 'संपर्क विवरण',
        addressKey: 'पता',
        addressValue: 'सुरोडी, ताल. श्रीगोंडा, जि. अ.नगर',
        addressLine2Key: '',
        addressLine2Value: '',
        mobileNoKey: 'मोबाइल नंबर',
        mobileNoValue: '9763732295',
    },
    userPhoto: null,
};
