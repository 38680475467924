import React, { useState, forwardRef, useImperativeHandle, useCallback } from "react";

export default forwardRef(function ContactData({ templateData, transliterationData }, ref) {
    const [contactDetails, setContactDetails] = useState({ ...templateData.current.contactDetails});

    // The component instance will be extended
    // with whatever you return from the callback passed
    // as the second argument
    useImperativeHandle(ref, () => ({
        set(contactDetails) {
            setContactDetails(contactDetails);
        },

        reset(contactDetails) {
            setContactDetails(contactDetails);
        },
    }));

    const handleChange = useCallback(
        (property) => (event) => {
            const value = event.target.value;

            templateData.current.contactDetails[property] = value;
            setContactDetails((prevState) => ({ ...prevState, [property]: value }));
        },
        [templateData]
    );

    return (
        <React.Fragment>
            <div className="row pt-3">
                <div className="col-md-4 col-0"></div>
                <div className="col-md-4 col-12">
                    <select
                        className="form-select text-center mt-2 fw-bold"
                        aria-label="heading 3"
                        name="hh4"
                        id="ihh4"
                        value = {contactDetails.contactHeading}
                        onChange={handleChange("contactHeading")}
                    >
                        {transliterationData.contactHeadingOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="col-md-4 col-0"></div>
            </div>
            <div className="row pt-3">
                <div className="col-md-4 mb-4">
                    <input
                        className="mycss3"
                        name="ht33"
                        id="iht33"
                        value={contactDetails.addressKey}
                        placeholder={transliterationData.additionalKeyPlaceHolder}
                        maxLength="16"
                        onChange={handleChange("addressKey")}
                    />
                    <input
                        className="form-control"
                        name="hd33"
                        id="ihd33"
                        placeholder={transliterationData.addressValuePlaceHolder}
                        maxLength="46"
                        value={contactDetails.addressValue}
                        onChange={handleChange("addressValue")}
                    />
                </div>
                <div className="col-md-4 mb-4">
                    <input
                        className="mycss3"
                        name="ht34"
                        id="iht34"
                        placeholder={transliterationData.additionalKeyPlaceHolder}
                        maxLength="16"
                        value={contactDetails.addressLine2Key}
                        onChange={handleChange("addressLine2Key")}
                    />
                    <input
                        className="form-control"
                        name="hd34"
                        id="ihd34"
                        placeholder={transliterationData.addressLine2ValuePlaceHolder}
                        maxLength="46"
                        value={contactDetails.addressLine2Value}
                        onChange={handleChange("addressLine2Value")}
                    />
                </div>
                <div className="col-md-4 mb-4">
                    <input
                        className="mycss3"
                        name="ht35"
                        id="iht35"
                        value={contactDetails.mobileNoKey}
                        placeholder={transliterationData.additionalKeyPlaceHolder}
                        maxLength="16"

                        onChange={handleChange("mobileNoKey")}
                    />
                    <input
                        className="form-control"
                        name="hd35"
                        id="ihd35"
                        placeholder={transliterationData.mobileNoValuePlaceHolder}
                        maxLength="46"
                        value={contactDetails.mobileNoValue}
                        onChange={handleChange("mobileNoValue")}
                    />
                </div>
            </div>

        </React.Fragment>
    );
});