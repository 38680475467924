import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import "./styles/nav-bar-new.css";


function NavbarNew() {
    const location = useLocation();
    return (
        <header className="site-navbar" role="banner">
            <nav className="navbar navbar-expand-lg navbar-light pt-0 pt-lg-0 px-lg-5 wow fadeIn" data-wow-delay="0.1s">
                <div className="container-fluid">
                    <a className="navbar-brand" href={`${process.env.PUBLIC_URL}/`}>
                        <img src={`${process.env.PUBLIC_URL}/images/vaibhav-logo.png`} alt="logo" />
                        <span className="brand-text">BiodataWala</span>
                    </a>

                    {/* Responsive UI toggler. */}
                    <button type="button" className="navbar-toggler" data-bs-toggle="collapse"
                        data-bs-target="#navbarCollapse">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    {/* Nav bar elements */}
                    <div className="collapse navbar-collapse" id="navbarCollapse">
                        <div className="site-navigation ms-auto p-4 p-lg-0" role="navigation">
                            <ul className="site-menu justify-content-end flex-grow-1 pe-3">
                                <li className={location.pathname === '/' ? 'active' : ''}>
                                    <Link to="/"><span>Home</span></Link>
                                </li>
                                <li className={location.pathname === '/about' ? 'active' : ''}>
                                    <Link to="/about"><span>About</span></Link>
                                </li>
                                <li className={location.pathname === '/blogs' ? 'active' : ''}>
                                    <Link to="/blogs"><span>Blogs</span></Link>
                                </li>
                                <li className={location.pathname === '/contact-us' ? 'active' : ''}>
                                    <Link to="/contact-us"><span>Contact</span></Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        </header >
    );
}

export default NavbarNew;
