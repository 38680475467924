import { Fragment } from "react";
import { Helmet } from "react-helmet";
import { NavBarNew, Footer } from '../../components';
import {  ContactUsComponent} from '../../NavLinksComponent';
import { contactSEO } from '../../common';


function Contact() {
  return (
    <Fragment>
      <Helmet>
        <meta name="description" content={contactSEO.description} />
        <meta
          name="keywords"
          content={contactSEO.keywords.join(", ")}
        />
      </Helmet>
      <div className='Contact'>
      <NavBarNew />
      <ContactUsComponent />
      <Footer />
    </div>
    </Fragment>
  );
}

export default Contact;
