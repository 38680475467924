import React, { useState, forwardRef, useImperativeHandle, useCallback } from "react";

export default forwardRef(function EducationAndIncomeData({ templateData, transliterationData }, ref) {
    const [educationAndIncome, setEducationAndIncome] = useState({
        ...templateData.current.educationAndIncome,
    });

    // The component instance will be extended
    // with whatever you return from the callback passed
    // as the second argument
    useImperativeHandle(ref, () => ({
        set(educationAndIncome) {
            setEducationAndIncome(educationAndIncome);
        },

        reset(educationAndIncome) {
            setEducationAndIncome(educationAndIncome);
        },
    }));

    const handleChange = useCallback(
        (property) => (event) => {
            const value = event.target.value;

            templateData.current.educationAndIncome[property] = value;
            setEducationAndIncome((prevState) => ({ ...prevState, [property]: value }));
        },
        [templateData]
    );

    return (
        <React.Fragment>
            {/* Education and Work */}
            <div className="row">
                <div className="col-md-6 mb-4">
                    <input
                        className="mycss3"
                        name="ht17"
                        id="iht17"
                        value={educationAndIncome.qualificationKey}
                        placeholder={transliterationData.additionalKeyPlaceHolder}
                        maxLength="16"
                        onChange={handleChange("qualificationKey")}
                    />

                    <input
                        className="form-control"
                        name="hd17"
                        id="ihd17"
                        placeholder={transliterationData.qualificationValuePlaceHolder}
                        maxLength="46"
                        value={educationAndIncome.qualificationValue}
                        onChange={handleChange("qualificationValue")}
                    />
                </div>
                <div className="col-md-6 mb-4">
                    <input
                        className="mycss3"
                        name="ht18"
                        id="iht18"
                        placeholder={transliterationData.additionalKeyPlaceHolder}
                        maxLength="16"
                        value={educationAndIncome.workKey}
                        onChange={handleChange("workKey")}
                    />
                    <input
                        className="form-control"
                        name="hd18"
                        id="ihd18"
                        placeholder={transliterationData.workValuePlaceHolder}
                        maxLength="46"
                        value={educationAndIncome.workValue}
                        onChange={handleChange("workValue")}
                    />
                </div>
            </div>
            {/* Income or salary */}
            <div className="row">
                <div className="col-md-6 mb-4">
                    <input
                        type="text"
                        className="mycss3"
                        name="ht19"
                        id="iht19"
                        placeholder={transliterationData.additionalKeyPlaceHolder}
                        maxLength="16"
                        value={educationAndIncome.earningKey}
                        onChange={handleChange("earningKey")}
                    />
                    <input
                        className="form-control"
                        name="hd19"
                        id="ihd19"
                        placeholder={transliterationData.earningValuePlaceHolder}
                        maxLength="46"
                        value={educationAndIncome.earningValue}
                        onChange={handleChange("earningValue")}
                    />
                </div>
                <div className="col-md-6 mb-4">
                    <input
                        className="mycss3"
                        name="ht20"
                        id="iht20"
                        placeholder={transliterationData.additionalKeyPlaceHolder}
                        maxLength="16"
                        value={educationAndIncome.extraHeading1Key}
                        onChange={handleChange("extraHeading1Key")}
                    />
                    <input
                        className="form-control"
                        name="hd20"
                        id="ihd20"
                        maxLength="46"
                        value={educationAndIncome.extraHeading1Value}
                        onChange={handleChange("extraHeading1Value")}
                    />
                </div>
            </div>
        </React.Fragment>
    );
});