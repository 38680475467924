import React from "react";
import DateRangeIcon from "@mui/icons-material/DateRange";
import AlarmOnIcon from "@mui/icons-material/AlarmOn";

import "./styles/blog-component.css";

function BlogComponent() {
    return (
        <div className="blog-container">
            <article className="bg-box">
                <div className="featured-image">
                    <img
                        className="blog-poster-img"
                        decoding="async"
                        loading="lazy"
                        fetchpriority="low"
                        alt="Blog post"
                        src="images/blogs/blog1.png"
                        width="660px"
                        
                    />

                    <div className="tags img-tag">
                        <p className="primary-tag">
                            Learn About
                        </p>
                    </div>
                </div>
                <div className="info">
                    <header>
                        <div className="meta inline small">
                            <time className="meta-info" dateTime="2024-05-05">
                                <DateRangeIcon
                                    sx={{ color: "#ff6481", width: "20px", height: "20px", marginRight: "5px" }}
                                />
                                Sept 07, 2024
                            </time>

                            <div className="min-read meta-info">
                                <AlarmOnIcon
                                    sx={{ color: "#ff6481", width: "20px", height: "20px", marginRight: "5px" }}
                                />{" "}
                                0 Min Read
                            </div>
                            {/* <div className="rating-info meta-info">
                                <WorkspacePremiumIcon
                                    sx={{ color: "#ff6481", width: "20px", height: "20px", marginRight: "5px" }}
                                />
                                <span className="name">Rating:</span>
                                <div className="rating star-3">
                                    <span className="star">★</span>
                                    <span className="star">★</span>
                                    <span className="star">★</span>
                                </div>
                            </div> */}
                        </div>
                        <h2 className="blog-heading">
                            <a href={`${process.env.PUBLIC_URL}/`}>
                                How to create marriage biodata with biodatawala
                            </a>
                        </h2>
                    </header>
                    <div className="blog-description">
                        <p>
                        Welcome, it’s great to have you here. We are still working on this page.
                          You can subsribe with us using newsletter option in footer...
                        </p>

                    </div>
                    <footer className="bottom">
                        <div className="meta bottom">
                            <div className="tags">
                                <p className="tag-link-3">Marriage</p>
                                <p className="tag-link-6">Biodata</p>
                            </div>
                            <a
                                href="https://www.linkedin.com/in/vaibhavwagaskar4/"
                                className="author"
                            >
                                <img
                                    className="author-image cover"
                                    loading="lazy"
                                    fetchpriority="low"
                                    decoding="async"
                                    src="images/blogs/vaibhav.jpg"
                                    alt="Vaibhav Wagaskar"
                                />
                                <span className="author-name">Vaibhav Wagaskar</span>
                            </a>
                        </div>
                    </footer>
                </div>
            </article>
        </div>
    );
}
export default BlogComponent;
