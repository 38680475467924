import { NavBarNew, Footer } from '../components';
import { Body, Form } from '../BiodataForm';


function Biodata() {
  return (
    <div className='CreateBiodata'>
      <NavBarNew />
      <Body />
      <Form />
      <Footer />
    </div>
  );
}

export default Biodata;
