export const BodyMarathi = {
    transliterationHeading1:"खालील फॉर्ममध्ये गणेशाच्या फोटो वितिरिक्त तुम्हाला अन्य फोटो निवडायचा असल्यास, पेन्सिलच्या बटनावर क्लिक करून योग्य धर्माचे चिन्ह निवडू शकता.",
    transliterationHeading2:"तसेच, ज्या शीर्षकांना चौकट नाही व फक्त खाली लाईन आहे ते तुम्ही तुमच्या आवश्यकतेनुसार बदलू शकता आणि हवे ते शीर्षक देऊ शकता.",
    transliterationHeading4: "जर लॅपटॉप मध्ये मराठी कीबोर्ड नसेल तर खालील बटन वरती क्लिक करून इंग्लिश टेक्स्ट मराठी मध्ये करू शकता व ते येथे पेस्ट करू शकता.",
    transliterationButtonText: "English to Marathi",
    modalText: {
        textboxPlaceHolder: "येथे क्लिक करून टायपिंग सुरु करा",
        copyButtonText: "कॉपी करा",
        clearButtonText: "हटवा",
        textCopied: "मजकूर यशस्वीपणे कॉपी झाला!"
    },
    languageCode: "mr"
}

export const BodyHindi = {
    transliterationHeading1:"नीचे दिए गए फॉर्म में गणेश जी की फोटो के अलावा अगर आपको कोई अन्य फोटो चुनना है, तो पेंसिल के बटन पर क्लिक करके सही धर्म का चिन्ह चुन सकते हैं।",
    transliterationHeading2:"साथ ही, जिन शीर्षकों में चौकठा नहीं है और केवल नीचे एक लाइन है, उन्हें आप अपनी ज़रूरत के अनुसार बदल सकते हैं और मनचाहा शीर्षक दे सकते हैं।",
    transliterationHeading4: "यदि लैपटॉप में हिंदी कीबोर्ड नहीं है, तो नीचे दिए गए बटन पर क्लिक करके अंग्रेजी टेक्स्ट को हिंदी में बदल सकते हैं और इसे यहाँ पेस्ट कर सकते हैं।",
    transliterationButtonText: "English to Hindi",
    modalText: {
        textboxPlaceHolder: "यहाँ क्लिक करके टाइपिंग शुरू करें",
        copyButtonText: "कॉपी करें",
        clearButtonText: "हटाएँ",
        textCopied: "पाठ सफलतापूर्वक कॉपी किया गया!"
    },
    languageCode: "hi"
}
export const BodyEnglish = {
    transliterationHeading1:"In the form below, if you want to choose a photo other than Ganesh's photo, you can click on the pencil button and select the appropriate religious symbol.",
    transliterationHeading2:"Also, In titles where there is no border and only underline, you can modify them according to your needs and provide your desired title.",
}