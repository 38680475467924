import React, { useState, useEffect, useMemo } from 'react';
import './styles/banner-img.css';

function BannerImg() {
  const phrases = useMemo(() => [
    "Create your Biodata.",
    "तुमचा बायोडाटा बनवा.",
    "अपना बायोडाटा बनवाइए|"
  ], []);
  const [index, setIndex] = useState(0); // State to track current phrase index
  const [currentText, setCurrentText] = useState(''); // State to hold current displayed text
  const [isDeleting, setIsDeleting] = useState(false); // State to track whether deleting or typing

  useEffect(() => {
    let currentPhrase = phrases[index];
    let intervalId;

    const typeText = () => {
      if (!isDeleting) {
        // Typing
        setCurrentText((prevText) => {
          return currentPhrase.substring(0, prevText.length + 1);
        });
      } else {
        // Deleting
        setCurrentText((prevText) => {
          return prevText.substring(0, prevText.length - 1);
        });
      }
    };

    intervalId = setInterval(() => {
      typeText();

      if (!isDeleting && currentText === currentPhrase) {
        // Finished typing current phrase, start deleting
        setIsDeleting(true);
        clearInterval(intervalId);
        setTimeout(() => {
          setIsDeleting(false); // Reset to typing mode
        }, 4000); // Delay before deleting
      } else if (isDeleting && currentText === '') {
        // Finished deleting, switch to next phrase
        setIndex((prevIndex) => (prevIndex + 1) % phrases.length);
        setIsDeleting(false); // Reset to typing mode
        clearInterval(intervalId);
      }
    }, 120); // Typing speed (150ms per character)

    return () => clearInterval(intervalId); // Clean up interval on component unmount
  }, [index, phrases, currentText, isDeleting]);

  return (
    <div className="banner-wrap">
      <div className='bannerImg-sm'>
        <div className='container'>
          <div className="text-overlay">
            <h1>{currentText}<span id="cursor">|</span></h1>
          </div>
          <div className="heart-shape"></div>
          <div className="biodata-images">
            <img src="images/biodata_templates/marathi/marathi-biodata-format.jpg" alt="girl biodata" className="card left" />
            <img src="images/biodata_templates/marathi/marriage-biodata-format-in-marathi.jpg" alt="boy biodata" className="card right" />
          </div>

          <div className="dotted-line-container">
            <svg className="dotted-line-svg" viewBox="0 0 200 100" xmlns="http://www.w3.org/2000/svg">
              <defs>
                <marker id="arrow" markerWidth="10" markerHeight="10" refX="0" refY="3" orient="auto" markerUnits="strokeWidth">
                  <path d="M0,0 L0,6 L9,3 z" fill="#000" />
                </marker>
              </defs>
              {/* Dotted line path with arrowhead --> */}
              <path className="dotted-line" d="M 0 98 Q 150 106 90 60 Q 50 15 160 12" stroke="#000" fill="transparent" strokeWidth="4" strokeDasharray="5,5" markerEnd="url(#arrow)" />
            </svg>
          </div>

          <div className="image-container">
            <img src="bride-groom-getting-married.jpeg" alt="Bride and Groom" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default BannerImg;
