import React, { useState, forwardRef, useImperativeHandle, useCallback } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import GodIconModal from './GodIconModal';
import Modal from '@mui/material/Modal';
import "./styles/heading.css";


export default forwardRef(function Heading({ templateData, transliterationData }, ref) {
    const [heading, setHeading] = useState({ ...templateData.current.heading });
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    // The component instance will be extended
    // with whatever you return from the callback passed
    // as the second argument
    useImperativeHandle(ref, () => ({

        set(heading) {
            setHeading(heading);
        },

        reset(heading) {
            setHeading(heading);
        }

    }));

    const handleChange = useCallback(
        (property) => (event) => {
            const value = event.target.value;

            templateData.current.heading[property] = value;
            setHeading((prevState) => ({ ...prevState, [property]: value }));
        },
        [templateData]
    );

    const handleIconChange = (iconPath) => {
        templateData.current.heading["iconGodPhoto"] = iconPath;
        setHeading((prevState) => ({ ...prevState, iconGodPhoto: iconPath }));
    };

    const handleDeleteIcon = () => {
        templateData.current.heading["iconGodPhoto"] = null;
        setHeading((prevState) => ({ ...prevState, iconGodPhoto: null }));
    };
    return (
        <React.Fragment>
            <div className="row">
                <div className="col text-center pt-4">
                    <div className='pb-3'>
                        {transliterationData.changeGodInfoText}
                    </div>
                    <div className="text-center" id="myrkid">
                        <img
                            id="gid1"
                            width="100"
                            height="100"
                            className="img-fluid"
                            src={heading.iconGodPhoto ? `images/godIcons/${heading.iconGodPhoto}` : null}
                            alt="GodIcon"
                            loading="lazy"
                        />
                    </div>
                </div>
            </div>
            <div className="row pt-2">
                <div className="god-image-button">
                    <button 
                        type="button" 
                        className='god-edit-button'
                        onClick={handleOpen}
                    >
                        <EditIcon />
                    </button>
                    <button
                        type="button"
                        className="remove-god-button"
                        onClick={handleDeleteIcon}
                    >
                        < DeleteIcon />
                    </button>
                </div>
            </div>
            {/* Textual fields */}
            <div className="row">
                <div className="col"></div>
                <div className="col-md-4 text-center">
                    <input
                        required
                        className="form-control text-center mt-2 fw-bold"
                        name="hh1"
                        id="ihh1"
                        maxLength="70"
                        value={heading.textGodName}
                        onChange={handleChange('textGodName')}
                    />
                    <select
                        className="form-select text-center mt-3 fw-bold"
                        aria-label="Heading 2"
                        name="hh2"
                        id="ihh2"
                        value={heading.titleText}
                        onChange={handleChange('titleText')}
                    >
                        {transliterationData.titleTextOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="col"></div>
            </div>
            {/* Modal for Ganesha */}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="changeganesha"
                tabIndex="-1"
                aria-hidden="true"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',

                }}
            >
                <div>
                    <GodIconModal 
                        onClose={handleClose} 
                        transliterationData={ transliterationData }
                        onIconChange={handleIconChange}
                    />
                </div>
            </Modal>
        </React.Fragment>
    );
})