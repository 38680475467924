
export const Marathi = {
    heading: {
        changeGodInfoText: "गणेशाच्या फोटो वितिरिक्त तुम्हाला अन्य फोटो निवडायचा असल्यास, पेन्सिलच्या बटनावर क्लिक करून योग्य धर्माचे चिन्ह निवडू शकता.",
        titleTextOptions: [
            { value: 'बायोडाटा', label: 'बायोडाटा' },
            { value: 'परिचय पत्र', label: 'परिचय पत्र' },
            { value: 'वैयक्तिक माहिती', label: 'वैयक्तिक माहिती' },
            
        ],
        godNameKeyDefaultValue: "।। श्री गणेशाय नमः ।।",
        titleTextDefaultValue: "बायोडाटा",
        godIconModal: {
            hindu: "हिंदू",
            jain: "जैन",
            buddhist: "बौद्ध",
            christian: "ख्रिश्चन",
            muslim: "मुस्लिम"
        }
    },
    personalDetails: {
        nameKeyOptions: [
            { value: 'नाव', label: 'नाव' },
            { value: 'मुलाचे नाव', label: 'मुलाचे नाव' },
            { value: 'मुलीचे नाव', label: 'मुलीचे नाव' },
        ],
        nameValuePlaceHolder: 'संपूर्ण नाव',
        dobKeyOptions: [
            { value: 'जन्मदिनांक', label: 'जन्मदिनांक' },
            { value: 'जन्मतारीख', label: 'जन्मतारीख' },
            
        ],
        dobDayOptions: [
            { value: "तारीख", label: "तारीख" }, { value: "1", label: "1" }, { value: "2", label: "2" },
            { value: "3", label: "3" }, { value: "4", label: "4" }, { value: "5", label: "5" },
            { value: "6", label: "6" }, { value: "7", label: "7" }, { value: "8", label: "8" },
            { value: "9", label: "9" }, { value: "10", label: "10" }, { value: "11", label: "11" },
            { value: "12", label: "12" }, { value: "13", label: "13" }, { value: "14", label: "14" },
            { value: "15", label: "15" }, { value: "16", label: "16" }, { value: "17", label: "17" },
            { value: "18", label: "18" }, { value: "19", label: "19" }, { value: "20", label: "20" },
            { value: "21", label: "21" }, { value: "22", label: "22" }, { value: "23", label: "23" },
            { value: "24", label: "24" }, { value: "25", label: "25" }, { value: "26", label: "26" },
            { value: "27", label: "27" }, { value: "28", label: "28" }, { value: "29", label: "29" }, 
            { value: "30", label: "30" }, { value: "31", label: "31" },
        ],
        dobMonthOptions: [
            { value: "महिना", label: "महिना" },
            { value: "जानेवारी", label: "जानेवारी" },
            { value: "फेब्रुवारी", label: "फेब्रुवारी" },
            { value: "मार्च", label: "मार्च" },
            { value: "एप्रिल", label: "एप्रिल" },
            { value: "मे", label: "मे" },
            { value: "जून", label: "जून" },
            { value: "जुलै", label: "जुलै" },
            { value: "ऑगस्ट", label: "ऑगस्ट" },
            { value: "सप्टेंबर", label: "सप्टेंबर" },
            { value: "ऑक्टोबर", label: "ऑक्टोबर" },
            { value: "नोव्हेंबर", label: "नोव्हेंबर" },
            { value: "डिसेंबर", label: "डिसेंबर" }
        ],
        dobYearOptions:  [
            { value: "वर्ष", label: "वर्ष" },
            { value: "2010", label: "2010" }, { value: "2009", label: "2009" }, { value: "2008", label: "2008" },
            { value: "2007", label: "2007" }, { value: "2006", label: "2006" }, { value: "2005", label: "2005" },
            { value: "2004", label: "2004" }, { value: "2003", label: "2003" }, { value: "2002", label: "2002" },
            { value: "2001", label: "2001" }, { value: "2000", label: "2000" }, { value: "1999", label: "1999" },
            { value: "1998", label: "1998" }, { value: "1997", label: "1997" }, { value: "1996", label: "1996" },
            { value: "1995", label: "1995" }, { value: "1994", label: "1994" }, { value: "1993", label: "1993" },
            { value: "1992", label: "1992" }, { value: "1991", label: "1991" }, { value: "1990", label: "1990" },
            { value: "1989", label: "1989" }, { value: "1988", label: "1988" }, { value: "1987", label: "1987" },
            { value: "1986", label: "1986" }, { value: "1985", label: "1985" }, { value: "1984", label: "1984" },
            { value: "1983", label: "1983" }, { value: "1982", label: "1982" }, { value: "1981", label: "1981" }
        ],
        birthTimeKeyOption: [
            { value: 'जन्म वेळ', label: 'जन्म वेळ' },
            { value: 'जन्म वार', label: 'जन्म वार' },
            { value: 'जन्म वार व वेळ', label: 'जन्म वार व वेळ' },
        ],
        birthTimeValuePlaceHolder: 'जन्माची वेळ',
        birthPlaceKeyOptions: [
            { value: 'जन्म स्थळ', label: 'जन्म स्थळ' },
            { value: 'जन्म ठिकाण', label: 'जन्म ठिकाण' },
        ],
        birthPlaceValuePlaceHolder: 'जन्म स्थळ',
        heightKey: 'उंची',
        heightValueOptions: [
            { value: "4 फूट 1 इंच", label: "4 फूट 1 इंच" },
            { value: "4 फूट 2 इंच", label: "4 फूट 2 इंच" }, { value: "4 फूट 3 इंच", label: "4 फूट 3 इंच" },
            { value: "4 फूट 4 इंच", label: "4 फूट 4 इंच" }, { value: "4 फूट 5 इंच", label: "4 फूट 5 इंच" },
            { value: "4 फूट 6 इंच", label: "4 फूट 6 इंच" }, { value: "4 फूट 7 इंच", label: "4 फूट 7 इंच" },
            { value: "4 फूट 8 इंच", label: "4 फूट 8 इंच" }, { value: "4 फूट 9 इंच", label: "4 फूट 9 इंच" },
            { value: "4 फूट 10 इंच", label: "4 फूट 10 इंच" }, { value: "4 फूट 11 इंच", label: "4 फूट 11 इंच" },
            { value: "5 फूट", label: "5 फूट" }, { value: "5 फूट 1 इंच", label: "5 फूट 1 इंच" },
            { value: "5 फूट 2 इंच", label: "5 फूट 2 इंच" }, { value: "5 फूट 3 इंच", label: "5 फूट 3 इंच" },
            { value: "5 फूट 4 इंच", label: "5 फूट 4 इंच" }, { value: "5 फूट 5 इंच", label: "5 फूट 5 इंच" },
            { value: "5 फूट 6 इंच", label: "5 फूट 6 इंच" }, { value: "5 फूट 7 इंच", label: "5 फूट 7 इंच" },
            { value: "5 फूट 8 इंच", label: "5 फूट 8 इंच" }, { value: "5 फूट 9 इंच", label: "5 फूट 9 इंच" },
            { value: "5 फूट 10 इंच", label: "5 फूट 10 इंच" }, { value: "5 फूट 11 इंच", label: "5 फूट 11 इंच" },
            { value: "6 फूट", label: "6 फूट" }, { value: "6 फूट 1 इंच", label: "6 फूट 1 इंच" },
            { value: "6 फूट 2 इंच", label: "6 फूट 2 इंच" }, { value: "6 फूट 3 इंच", label: "6 फूट 3 इंच" },
            { value: "6 फूट 4 इंच", label: "6 फूट 4 इंच" }, { value: "6 फूट 5 इंच", label: "6 फूट 5 इंच" },
            { value: "6 फूट 6 इंच", label: "6 फूट 6 इंच" }, { value: "6 फूट 7 इंच", label: "6 फूट 7 इंच" }
          ],
        colorKeyOptions: [
            { value: 'रंग', label: 'रंग' },
            { value: 'वर्ण', label: 'वर्ण' },
        ],
        colorValueOptions: [
            { value: 'गोरा', label: 'गोरा' },
            { value: 'निमगोरा', label: 'निमगोरा' },
            { value: 'गव्हाळ', label: 'गव्हाळ' },
            { value: 'सावळा', label: 'सावळा' },
            { value: 'काळा सावळा', label: 'काळा सावळा' },
        ],
        bloodGroupKey: 'रक्तगट',
        // Error messages.
        nameValueError: 'नावात फक्त अक्षरं आणि रिकाम्या जागा असाव्यात.',
        dobDateError: 'कृपया वैध तारीख निवडा.',
        dobMonthError: 'कृपया वैध महिना निवडा.',
        dobYearError: 'कृपया वैध वर्ष निवडा.',
        birthTimeError: 'कृपया जन्माची वेळ भरा.',
        birthPlaceError: 'कृपया जन्म स्थान भरा.',
        heightError: 'कृपया उंची निवडा.',
        bloodGroupError: 'कृपया रक्तगट निवडा.'
    },

    horoscopeDetails: {

        // Horoscopic Info
        signNameKeyOptions: [
            { value: 'नावरस नाव', label: 'नावरस नाव' },
            { value: 'रास नाव', label: 'रास नाव' },
            { value: 'जन्म नाव', label: 'जन्म नाव' }
        ],
        signNameValuePlaceHolder: 'नावरस नाव/जन्म नाव',
        religionCasteKeyOptions: [
            { value: 'धर्म-जात', label: 'धर्म-जात' },
            { value: 'धर्म', label: 'धर्म' },
            { value: 'जात', label: 'जात' }
        ],
        religionCasteValuePlaceHolder: 'धर्म-जात',
        signValueOptions: [
            { value: "मेष", label: "मेष" }, { value: "वृषभ", label: "वृषभ" },
            { value: "मिथुन", label: "मिथुन" }, { value: "कर्क", label: "कर्क" },
            { value: "सिंह", label: "सिंह" }, { value: "कन्या", label: "कन्या" },
            { value: "तूळ", label: "तूळ" }, { value: "वृश्चिक", label: "वृश्चिक" },
            { value: "धनु", label: "धनु" }, { value: "मकर", label: "मकर" },
            { value: "कुंभ", label: "कुंभ" }, { value: "मीन", label: "मीन" }
        ],
        ganValueOptions: [
            { value: "देव", label: "देव" }, { value: "मनुष्य", label: "मनुष्य" },
            { value: "राक्षस", label: "राक्षस" }
        ],
        naadiValueOptions: [
            { value: "आद्य", label: "आद्य" }, { value: "मध्य", label: "मध्य" },
            { value: "अंत्य", label: "अंत्य" }
        ],
        optionalFieldsInfoText : "खालील शीर्षक तुम्ही रिकामे ठेवू शकता किंवा तुमच्या आवश्यकतेनुसार बदलू शकता आणि हवे ते शीर्षक देऊ शकता.",
        gotraValueOptions: [
            { value: "कश्यप", label: "कश्यप" }, { value: "भारद्वाज", label: "भारद्वाज" },
            { value: "वशिष्ठ", label: "वशिष्ठ" }, { value: "विश्वामित्र", label: "विश्वामित्र" },
            { value: "गौतम", label: "गौतम" }, { value: "अंगिरा", label: "अंगिरा" },
            { value: "अत्रि", label: "अत्रि" }, { value: "क्रतू", label: "क्रतू" },
            { value: "जमदग्नी", label: "जमदग्नी" }, { value: "पुलास्ती", label: "पुलास्ती" },
            { value: "पुलाह", label: "पुलाह" }, { value: "अगस्त्य", label: "अगस्त्य" },
            { value: "कौंडिन्य", label: "कौंडिन्य" }, { value: "कौशिक", label: "कौशिक" },
            { value: "गार्ग्य", label: "गार्ग्य" }, { value: "दुर्वास", label: "दुर्वास" },
            { value: "पारेख", label: "पारेख" }, { value: "भार्गव", label: "भार्गव" },
            { value: "भृगु", label: "भृगु" }, { value: "शाण्डिल्य", label: "शाण्डिल्य" },
            { value: "सुपरनस्य", label: "सुपरनस्य" },
            { value: "लिस्ट मध्ये नसेल तर टाइप करा", label: "लिस्ट मध्ये नसेल तर टाइप करा" }
        ],
        nakshatraValueOptions: [
            { value: "अनुराधा", label: "अनुराधा" }, { value: "अश्विनी", label: "अश्विनी" },
            { value: "आर्द्रा", label: "आर्द्रा" }, { value: "आश्लेषा", label: "आश्लेषा" },
            { value: "उत्तरा फाल्गुनी", label: "उत्तरा फाल्गुनी" }, { value: "उत्तरा भाद्रपदा", label: "उत्तरा भाद्रपदा" },
            { value: "उत्तराषाढा", label: "उत्तराषाढा" }, { value: "कृत्तिका", label: "कृत्तिका" },
            { value: "चित्रा", label: "चित्रा" }, { value: "ज्येष्ठा", label: "ज्येष्ठा" },
            { value: "धनिष्ठा", label: "धनिष्ठा" }, { value: "पुनर्वसू", label: "पुनर्वसू" },
            { value: "पुष्य", label: "पुष्य" }, { value: "पूर्वा फाल्गुनी", label: "पूर्वा फाल्गुनी" },
            { value: "पूर्वा भाद्रपदा", label: "पूर्वा भाद्रपदा" }, { value: "पूर्वाषाढा", label: "पूर्वाषाढा" },
            { value: "भरणी", label: "भरणी" }, { value: "मघा", label: "मघा" },
            { value: "मूळ", label: "मूळ" }, { value: "मृगशीर्ष", label: "मृगशीर्ष" },
            { value: "रेवती", label: "रेवती" }, { value: "रोहिणी", label: "रोहिणी" },
            { value: "विशाखा", label: "विशाखा" }, { value: "शततारका", label: "शततारका" },
            { value: "श्रवण", label: "श्रवण" }, { value: "स्वाती", label: "स्वाती" },
            { value: "हस्त", label: "हस्त" }
        ],
        additionalKeyPlaceHolder: 'येथे अतिरिक्त शीर्षक टाकू शकता'
    },
    educationAndIncome: {
        qualificationValuePlaceHolder: 'शिक्षण',
        workValuePlaceHolder: '(Optional) कंपनी किंवा व्यवसायाचे नाव, पोस्ट',
        earningValuePlaceHolder: '(Optional) वेतन/उत्पन्न',
        additionalKeyPlaceHolder: 'येथे अतिरिक्त शीर्षक टाकू शकता',
    },
    familyDetails: {

        familyDetailHeadingOptions: [
            { value: 'कौटुंबिक माहिती', label: 'कौटुंबिक माहिती' },
            { value: "कौटुंबिक परिचय", label: "कौटुंबिक परिचय" }
        ],
        fatherNameValuePlaceHolder: 'वडिलांचे संपूर्ण नाव',
        fatherOccupationValuePlaceHolder: 'वडिलांचा व्यवसाय किंवा नोकरी',
        motherNameValuePlaceHolder: 'आईचे संपूर्ण नाव',
        noSisterValuePlaceHolder: '1 बहीण(विवाहित)',
        noBrotherValuePlaceHolder: '2 भाऊ/1 विवाहित',
        maternalUncleValuePlaceHolder: 'मामाचे नाव',
        relativesNamesValuePlaceHolder: 'नातेवाईकांची आडनावे',
        additionalKeyPlaceHolder: 'येथे अतिरिक्त शीर्षक टाकू शकता'
    },
    contactDetails: {

        contactHeadingOptions: [
            { value: 'संपर्क', label: 'संपर्क' },
            { value: "संदेशवहन", label: "संदेशवहन" }
        ],
        addressValuePlaceHolder: 'स्थायी/कायमचा  पत्ता',
        addressLine2ValuePlaceHolder: '(Optional) Addess Line 2',
        mobileNoValuePlaceHolder: 'संपर्कासाठी मोबाईल नंबर',
        additionalKeyPlaceHolder: 'येथे अतिरिक्त शीर्षक टाकू शकता'

    },
    userPhoto: {
        userPhotoHeading: "फोटो",
        photoInfoText: "खालील बटणाचा वापर करून फोटो अपलोड केला, तरच बायोडाटावर फोटो येईल. अन्यथा बायोडाटा बिना तुमच्या फोटोचा बनेल.",
        photoLoadingText: "हाय क्वालिटी फोटो रूपांतरित केला जात आहे..."
    },
    errorMessage: 'सर्व आवश्यक फील्ड्स भरा किंवा त्यांचे अटी पूर्ण करा.'

}
export const English = {
    heading: {
        changeGodInfoText: "If you want to select other photo apart from Ganesha photo, you can click on the pencil button to select the appropriate religion symbol.",
        titleTextOptions: [
            { value: 'Biodata', label: 'Biodata' },
            { value: 'Resume', label: 'Resume' },
            { value: 'Personal Information', label: 'Personal Information' },
            
        ],
        godNameKeyDefaultValue: "।। Shree Ganeshay Namh: ।।",
        titleTextDefaultValue: "Biodata",
        godIconModal: {
            hindu: "Hindu",
            jain: "Jain",
            buddhist: "Buddhist",
            christian: "Christian",
            muslim: "Muslim"
        }
    },
    personalDetails: {
        nameKey: 'Name',
        nameKeyOptions: [
            { value: 'Name', label: 'Name' },
            { value: "Boy's Name", label: "Boy's Name" },
            { value: "Girl's Name", label: "Girl's Name" }
        ],
        nameValuePlaceHolder: 'Full Name',
        dobKeyOptions:  [
            { value: "Birth Date", label: "Birth Date" },
            { value: 'Date of Birth', label: 'Date of Birth' },
        ],
        dobDayOptions: [
            { value: "Day", label: "Day" }, { value: "1", label: "1" }, { value: "2", label: "2" },
            { value: "3", label: "3" }, { value: "4", label: "4" }, { value: "5", label: "5" },
            { value: "6", label: "6" }, { value: "7", label: "7" }, { value: "8", label: "8" },
            { value: "9", label: "9" }, { value: "10", label: "10" }, { value: "11", label: "11" },
            { value: "12", label: "12" }, { value: "13", label: "13" }, { value: "14", label: "14" },
            { value: "15", label: "15" }, { value: "16", label: "16" }, { value: "17", label: "17" },
            { value: "18", label: "18" }, { value: "19", label: "19" }, { value: "20", label: "20" },
            { value: "21", label: "21" }, { value: "22", label: "22" }, { value: "23", label: "23" },
            { value: "24", label: "24" }, { value: "25", label: "25" }, { value: "26", label: "26" },
            { value: "27", label: "27" }, { value: "28", label: "28" }, { value: "29", label: "29" }, 
            { value: "30", label: "30" }, { value: "31", label: "31" },
        ],
        dobMonthOptions: [
            { value: "Month", label: "Month" }, 
            { value: "January", label: "January" }, 
            { value: "February", label: "February" }, 
            { value: "March", label: "March" }, 
            { value: "April", label: "April" }, 
            { value: "May", label: "May" }, 
            { value: "June", label: "June" }, 
            { value: "July", label: "July" }, 
            { value: "August", label: "August" }, 
            { value: "September", label: "September" }, 
            { value: "October", label: "October" }, 
            { value: "November", label: "November" }, 
            { value: "December", label: "December" }
        ],
          
        dobYearOptions:  [
            { value: "Year", label: "Year" },
            { value: "2010", label: "2010" }, { value: "2009", label: "2009" }, { value: "2008", label: "2008" },
            { value: "2007", label: "2007" }, { value: "2006", label: "2006" }, { value: "2005", label: "2005" },
            { value: "2004", label: "2004" }, { value: "2003", label: "2003" }, { value: "2002", label: "2002" },
            { value: "2001", label: "2001" }, { value: "2000", label: "2000" }, { value: "1999", label: "1999" },
            { value: "1998", label: "1998" }, { value: "1997", label: "1997" }, { value: "1996", label: "1996" },
            { value: "1995", label: "1995" }, { value: "1994", label: "1994" }, { value: "1993", label: "1993" },
            { value: "1992", label: "1992" }, { value: "1991", label: "1991" }, { value: "1990", label: "1990" },
            { value: "1989", label: "1989" }, { value: "1988", label: "1988" }, { value: "1987", label: "1987" },
            { value: "1986", label: "1986" }, { value: "1985", label: "1985" }, { value: "1984", label: "1984" },
            { value: "1983", label: "1983" }, { value: "1982", label: "1982" }, { value: "1981", label: "1981" }
          ],
        birthTimeKeyOption: [
            { value: 'Birth Time', label: 'Birth Time' },
            { value: "Birth Day", label: "Birth Day" },
            { value: "Birth Day & Time", label: "Birth Day & Time" }
        ],
        birthTimeValuePlaceHolder: 'Birth Time, Day',
        birthPlaceKeyOptions: [
            { value: 'Birth Place', label: 'Birth Place' },
        ],
        birthPlaceValuePlaceHolder: 'Birth Place',
        heightKey: 'Height',
        heightValueOptions: [
            { value: "4 Feet 1 Inch", label: "4 Feet 1 Inch" },
            { value: "4 Feet 2 Inches", label: "4 Feet 2 Inches" }, { value: "4 Feet 3 Inches", label: "4 Feet 3 Inches" },
            { value: "4 Feet 4 Inches", label: "4 Feet 4 Inches" }, { value: "4 Feet 5 Inches", label: "4 Feet 5 Inches" },
            { value: "4 Feet 6 Inches", label: "4 Feet 6 Inches" }, { value: "4 Feet 7 Inches", label: "4 Feet 7 Inches" },
            { value: "4 Feet 8 Inches", label: "4 Feet 8 Inches" }, { value: "4 Feet 9 Inches", label: "4 Feet 9 Inches" },
            { value: "4 Feet 10 Inches", label: "4 Feet 10 Inches" }, { value: "4 Feet 11 Inches", label: "4 Feet 11 Inches" },
            { value: "5 Feet", label: "5 Feet" }, { value: "5 Feet 1 Inch", label: "5 Feet 1 Inch" },
            { value: "5 Feet 2 Inches", label: "5 Feet 2 Inches" }, { value: "5 Feet 3 Inches", label: "5 Feet 3 Inches" },
            { value: "5 Feet 4 Inches", label: "5 Feet 4 Inches" }, { value: "5 Feet 5 Inches", label: "5 Feet 5 Inches" },
            { value: "5 Feet 6 Inches", label: "5 Feet 6 Inches" }, { value: "5 Feet 7 Inches", label: "5 Feet 7 Inches" },
            { value: "5 Feet 8 Inches", label: "5 Feet 8 Inches" }, { value: "5 Feet 9 Inches", label: "5 Feet 9 Inches" },
            { value: "5 Feet 10 Inches", label: "5 Feet 10 Inches" }, { value: "5 Feet 11 Inches", label: "5 Feet 11 Inches" },
            { value: "6 Feet", label: "6 Feet" }, { value: "6 Feet 1 Inch", label: "6 Feet 1 Inch" },
            { value: "6 Feet 2 Inches", label: "6 Feet 2 Inches" }, { value: "6 Feet 3 Inches", label: "6 Feet 3 Inches" },
            { value: "6 Feet 4 Inches", label: "6 Feet 4 Inches" }, { value: "6 Feet 5 Inches", label: "6 Feet 5 Inches" },
            { value: "6 Feet 6 Inches", label: "6 Feet 6 Inches" }, { value: "6 Feet 7 Inches", label: "6 Feet 7 Inches" }
        ],
        colorKeyOptions: [
            { value: 'Complexion', label: 'Complexion' },
            { value: 'Color', label: 'Color' },
        ],
        colorValueOptions:  [
            { value: 'Fair', label: 'Fair' },
            { value: 'Light Medium', label: 'Light Medium' },
            { value: 'Wheatish', label: 'Wheatish' },
            { value: 'Dusky', label: 'Dusky' },
            { value: 'Dark', label: 'Dark' },
        ],
        bloodGroupKey: 'Blood Group',
        // Error messages.
        nameValueError: 'Name should contain only letters and spaces.',
        dobDateError: 'Please select valid date.',
        dobMonthError: 'Please select valid month.',
        dobYearError: 'Please select valid year.',
        birthTimeError: 'Please fill birth time.',
        birthPlaceError: 'Please fill birth place',
        heightError: 'Please select height.',
        bloodGroupError: 'Please select blood group.'
    },

    horoscopeDetails: {

        // Horoscopic Info
        signNameKeyOptions: [
            { value: 'Navaras Name', label: 'Navaras Name' },
            { value: 'Zodiac Name', label: 'Zodiac Name' },
            { value: 'Birth Name', label: 'Birth Name' }
        ],
        signNameValuePlaceHolder: 'Navaras or Zodiac Name',
        religionCasteKeyOptions: [
            { value: 'Religion-Caste', label: 'Religion-Caste' },
            { value: 'Religion', label: 'Religion' },
            { value: 'Caste', label: 'Caste' }
        ],
        religionCasteValuePlaceHolder: 'Religion-Caste',
        signValueOptions: [
            { value: "Aries", label: "Aries" }, { value: "Taurus", label: "Taurus" },
            { value: "Gemini", label: "Gemini" }, { value: "Cancer", label: "Cancer" },
            { value: "Leo", label: "Leo" }, { value: "Virgo", label: "Virgo" },
            { value: "Libra", label: "Libra" }, { value: "Scorpio", label: "Scorpio" },
            { value: "Sagittarius", label: "Sagittarius" }, { value: "Capricorn", label: "Capricorn" },
            { value: "Aquarius", label: "Aquarius" }, { value: "Pisces", label: "Pisces" }
        ],
        ganValueOptions: [
            { value: "Deva", label: "Deva" }, { value: "Manushya ", label: "Manushya " },
            { value: "Rakshasa", label: "Rakshasa" }
        ],
        naadiValueOptions: [
            { value: "Adya", label: "Adya" }, { value: "Madhya", label: "Madhya" },
            { value: "Antya", label: "Antya" }
        ],
        optionalFieldsInfoText : "You can leave the following title blank or change it according to your requirement and give any title you want.",
        gotraValueOptions: [
            { value: "Kashyap", label: "Kashyap" }, { value: "Bharadwaj", label: "Bharadwaj" },
            { value: "Vashishtha", label: "Vashishtha" }, { value: "Vishwamitra", label: "Vishwamitra" },
            { value: "Gautam", label: "Gautam" }, { value: "Angira", label: "Angira" },
            { value: "Atri", label: "Atri" }, { value: "Kratu", label: "Kratu" },
            { value: "Jamadagni", label: "Jamadagni" }, { value: "Pulasti", label: "Pulasti" },
            { value: "Pulaha", label: "Pulaha" }, { value: "Agastya", label: "Agastya" },
            { value: "Kaundinya", label: "Kaundinya" }, { value: "Kaushika", label: "Kaushika" },
            { value: "Gargya", label: "Gargya" }, { value: "Durvasa", label: "Durvasa" },
            { value: "Pareekh", label: "Pareekh" }, { value: "Bhargava", label: "Bhargava" },
            { value: "Bhrigu", label: "Bhrigu" }, { value: "Shandilya", label: "Shandilya" },
            { value: "Suparnasya", label: "Suparnasya" },
            { value: "Type if not in list", label: "Type if not in list" }
        ],
        nakshatraValueOptions: [
            { value: "Anuradha", label: "Anuradha" }, { value: "Ashwini", label: "Ashwini" },
            { value: "Ardra", label: "Ardra" }, { value: "Ashlesha", label: "Ashlesha" },
            { value: "Uttara Phalguni", label: "Uttara Phalguni" }, { value: "Uttara Bhadrapada", label: "Uttara Bhadrapada" },
            { value: "Uttarashadha", label: "Uttarashadha" }, { value: "Krittika", label: "Krittika" },
            { value: "Chitra", label: "Chitra" }, { value: "Jyeshtha", label: "Jyeshtha" },
            { value: "Dhanishta", label: "Dhanishta" }, { value: "Punarvasu", label: "Punarvasu" },
            { value: "Pushya", label: "Pushya" }, { value: "Purva Phalguni", label: "Purva Phalguni" },
            { value: "Purva Bhadrapada", label: "Purva Bhadrapada" }, { value: "Purva Ashadha", label: "Purva Ashadha" },
            { value: "Bharani", label: "Bharani" }, { value: "Magha", label: "Magha" },
            { value: "Moola", label: "Moola" }, { value: "Mrigashirsha", label: "Mrigashirsha" },
            { value: "Revati", label: "Revati" }, { value: "Rohini", label: "Rohini" },
            { value: "Vishakha", label: "Vishakha" }, { value: "Shatabhisha", label: "Shatabhisha" },
            { value: "Shravana", label: "Shravana" }, { value: "Swati", label: "Swati" },
            { value: "Hasta", label: "Hasta" }
        ],
        additionalKeyPlaceHolder: 'For an additional heading/title.',
    },
    educationAndIncome: {
        qualificationValuePlaceHolder: 'Education',
        workValuePlaceHolder: '(Optional) Company or Business Name, Post',
        earningValuePlaceHolder: '(Optional) Salary/Income',
        additionalKeyPlaceHolder: 'For an additional heading/title.',
    },
    familyDetails: {

        familyDetailHeadingOptions: [
            { value: 'Family Details', label: 'Family Details' },
            { value: "Family Information", label: "Family Information" },
            { value: "Family Background", label: "Family Background" }
        ],
        fatherNameValuePlaceHolder: 'Father\'s full name',
        fatherOccupationValuePlaceHolder: 'Father\'s Occupation or Job',
        motherNameValuePlaceHolder: 'Mother\'s full name',
        noSisterValuePlaceHolder: '1 Sister(Married)',
        noBrotherValuePlaceHolder: '1 Brother(Un-Married)',
        maternalUncleValuePlaceHolder: 'Maternal uncle name',
        relativesNamesValuePlaceHolder: 'Relatives Surnames',
        additionalKeyPlaceHolder: 'For an additional heading/title.'
    },
    contactDetails: {
        
        contactHeadingOptions: [
            { value: 'Contact Details', label: 'Contact Details' },
            { value: "Contact Information", label: "Contact Information" }
        ],
        addressValuePlaceHolder: 'Addess Line 1',
        addressLine2ValuePlaceHolder: '(Optional) Addess Line 2',
        mobileNoValuePlaceHolder: 'Mobile No. for Contact',
        additionalKeyPlaceHolder: 'For an additional heading/title.'

    },
    userPhoto: {
        userPhotoHeading: "Photo",
        photoInfoText: "Photo will appear on biodata only if photo is uploaded using below button. Otherwise the biodata will be made without your photo.",
        photoLoadingText: "Converting image, please wait..."
    },
    errorMessage: 'Fill in all required fields or meet their conditions.'

}
export const Hindi = {
    heading: {
        changeGodInfoText: "यदि आप गणेश फोटो के अलावा अन्य फोटो का चयन करना चाहते हैं, तो आप उपयुक्त धर्म प्रतीक का चयन करने के लिए पेंसिल बटन पर क्लिक कर सकते हैं।",
        titleTextOptions: [
            { value: 'बायोडाटा', label: 'बायोडाटा' },
            { value: 'रिज्यूमे', label: 'रिज्यूमे' },
            { value: 'व्यक्तिगत जानकारी', label: 'व्यक्तिगत जानकारी' },
        ],
        godNameKeyDefaultValue: "।। श्री गणेशाय नमः ।।",
        titleTextDefaultValue: "बायोडाटा",
        godIconModal: {
            hindu: "हिंदू",
            jain: "जैन",
            buddhist: "बौद्ध",
            christian: "ईसाई",
            muslim: "मुस्लिम"
        }
    },
    personalDetails: {
        nameKey: 'नाम',
        nameKeyOptions: [
            { value: 'नाम', label: 'नाम' },
            { value: "लड़के का नाम", label: "लड़के का नाम" },
            { value: "लड़की का नाम", label: "लड़की का नाम" }
        ],
        nameValuePlaceHolder: 'पूरा नाम',
        dobKeyOptions: [
            { value: "जन्म तिथि", label: "जन्म तिथि" },
            { value: 'जन्म की तारीख', label: 'जन्म की तारीख' },
        ],
        dobDayOptions: [
            { value: "दिन", label: "दिन" }, { value: "1", label: "1" }, { value: "2", label: "2" },
            { value: "3", label: "3" }, { value: "4", label: "4" }, { value: "5", label: "5" },
            { value: "6", label: "6" }, { value: "7", label: "7" }, { value: "8", label: "8" },
            { value: "9", label: "9" }, { value: "10", label: "10" }, { value: "11", label: "11" },
            { value: "12", label: "12" }, { value: "13", label: "13" }, { value: "14", label: "14" },
            { value: "15", label: "15" }, { value: "16", label: "16" }, { value: "17", label: "17" },
            { value: "18", label: "18" }, { value: "19", label: "19" }, { value: "20", label: "20" },
            { value: "21", label: "21" }, { value: "22", label: "22" }, { value: "23", label: "23" },
            { value: "24", label: "24" }, { value: "25", label: "25" }, { value: "26", label: "26" },
            { value: "27", label: "27" }, { value: "28", label: "28" }, { value: "29", label: "29" },
            { value: "30", label: "30" }, { value: "31", label: "31" },
        ],
        dobMonthOptions: [
            { value: "महीना", label: "महीना" }, { value: "जनवरी", label: "जनवरी" },
            { value: "फ़रवरी", label: "फ़रवरी" }, { value: "मार्च", label: "मार्च" },
            { value: "अप्रैल", label: "अप्रैल" }, { value: "मई", label: "मई" },
            { value: "जून", label: "जून" }, { value: "जुलाई", label: "जुलाई" },
            { value: "अगस्त", label: "अगस्त" }, { value: "सितंबर", label: "सितंबर" },
            { value: "अक्टूबर", label: "अक्टूबर" }, { value: "नवंबर", label: "नवंबर" },
            { value: "दिसंबर", label: "दिसंबर" }
        ],
        dobYearOptions: [
            { value: "वर्ष", label: "वर्ष" },
            { value: "2010", label: "2010" }, { value: "2009", label: "2009" }, { value: "2008", label: "2008" },
            { value: "2007", label: "2007" }, { value: "2006", label: "2006" }, { value: "2005", label: "2005" },
            { value: "2004", label: "2004" }, { value: "2003", label: "2003" }, { value: "2002", label: "2002" },
            { value: "2001", label: "2001" }, { value: "2000", label: "2000" }, { value: "1999", label: "1999" },
            { value: "1998", label: "1998" }, { value: "1997", label: "1997" }, { value: "1996", label: "1996" },
            { value: "1995", label: "1995" }, { value: "1994", label: "1994" }, { value: "1993", label: "1993" },
            { value: "1992", label: "1992" }, { value: "1991", label: "1991" }, { value: "1990", label: "1990" },
            { value: "1989", label: "1989" }, { value: "1988", label: "1988" }, { value: "1987", label: "1987" },
            { value: "1986", label: "1986" }, { value: "1985", label: "1985" }, { value: "1984", label: "1984" },
            { value: "1983", label: "1983" }, { value: "1982", label: "1982" }, { value: "1981", label: "1981" }
        ],
        birthTimeKeyOption: [
            { value: 'जन्म समय', label: 'जन्म समय' },
            { value: "जन्म दिन", label: "जन्म दिन" },
            { value: "जन्म दिन और समय", label: "जन्म दिन और समय" }
        ],
        birthTimeValuePlaceHolder: 'जन्म समय, दिन',
        birthPlaceKeyOptions: [
            { value: 'जन्म स्थान', label: 'जन्म स्थान' },
        ],
        birthPlaceValuePlaceHolder: 'जन्म स्थान',
        heightKey: 'लम्बाई',
        heightValueOptions: [
            { value: "4 फीट 1 इंच", label: "4 फीट 1 इंच" }, { value: "4 फीट 2 इंच", label: "4 फीट 2 इंच" },
            { value: "4 फीट 3 इंच", label: "4 फीट 3 इंच" }, { value: "4 फीट 4 इंच", label: "4 फीट 4 इंच" },
            { value: "4 फीट 5 इंच", label: "4 फीट 5 इंच" }, { value: "4 फीट 6 इंच", label: "4 फीट 6 इंच" },
            { value: "4 फीट 7 इंच", label: "4 फीट 7 इंच" }, { value: "4 फीट 8 इंच", label: "4 फीट 8 इंच" },
            { value: "4 फीट 9 इंच", label: "4 फीट 9 इंच" }, { value: "4 फीट 10 इंच", label: "4 फीट 10 इंच" },
            { value: "4 फीट 11 इंच", label: "4 फीट 11 इंच" }, { value: "5 फीट", label: "5 फीट" },
            { value: "5 फीट 1 इंच", label: "5 फीट 1 इंच" }, { value: "5 फीट 2 इंच", label: "5 फीट 2 इंच" },
            { value: "5 फीट 3 इंच", label: "5 फीट 3 इंच" }, { value: "5 फीट 4 इंच", label: "5 फीट 4 इंच" },
            { value: "5 फीट 5 इंच", label: "5 फीट 5 इंच" }, { value: "5 फीट 6 इंच", label: "5 फीट 6 इंच" },
            { value: "5 फीट 7 इंच", label: "5 फीट 7 इंच" }, { value: "5 फीट 8 इंच", label: "5 फीट 8 इंच" },
            { value: "5 फीट 9 इंच", label: "5 फीट 9 इंच" }, { value: "5 फीट 10 इंच", label: "5 फीट 10 इंच" },
            { value: "5 फीट 11 इंच", label: "5 फीट 11 इंच" }, { value: "6 फीट", label: "6 फीट" },
            { value: "6 फीट 1 इंच", label: "6 फीट 1 इंच" }, { value: "6 फीट 2 इंच", label: "6 फीट 2 इंच" },
            { value: "6 फीट 3 इंच", label: "6 फीट 3 इंच" }, { value: "6 फीट 4 इंच", label: "6 फीट 4 इंच" },
            { value: "6 फीट 5 इंच", label: "6 फीट 5 इंच" }, { value: "6 फीट 6 इंच", label: "6 फीट 6 इंच" },
            { value: "6 फीट 7 इंच", label: "6 फीट 7 इंच" }
        ],
        colorKeyOptions: [
            { value: 'रंग', label: 'रंग' },
        ],
        colorValueOptions:  [
            { value: 'सफेद', label: 'सफेद' },
            { value: 'गोरी', label: 'गोरी' },
            { value: 'सावला', label: 'सावला' },
            { value: 'भूरा', label: 'भूरा' },
            { value: 'गहरा', label: 'गहरा' },
        ],
        bloodGroupKey: 'रक्त समूह',
        // Error messages.
        nameValueError: 'नाम में केवल अक्षर और रिक्त स्थान होने चाहिए।',
        dobDateError: 'कृपया एक वैध तारीख चुनें।',
        dobMonthError: 'कृपया एक वैध माह चुनें।',
        dobYearError: 'कृपया एक वैध वर्ष चुनें।',
        birthTimeError: 'कृपया जन्म का समय भरें।',
        birthPlaceError: 'कृपया जन्म स्थान भरें।',
        heightError: 'कृपया ऊँचाई चुनें।',
        bloodGroupError: 'कृपया रक्त समूह चुनें।'
    },
    horoscopeDetails: {

        // Horoscopic Info
        signNameKeyOptions: [
            { value: 'नवरस नाम', label: 'नवरस नाम' },
            { value: 'राशि नाम', label: 'राशि नाम' },
            { value: 'जन्म नाम', label: 'जन्म नाम' }
        ],
        signNameValuePlaceHolder: 'नवरस या राशि का नाम',
        religionCasteKeyOptions: [
            { value: 'धर्म-जाति', label: 'धर्म-जाति' },
            { value: 'धर्म', label: 'धर्म' },
            { value: 'जाति', label: 'जाति' }
        ],
        religionCasteValuePlaceHolder: 'धर्म-जाति',
        signValueOptions: [
            { value: "मेष", label: "मेष" }, { value: "वृषभ", label: "वृषभ" },
            { value: "मिथुन", label: "मिथुन" }, { value: "कर्क", label: "कर्क" },
            { value: "सिंह", label: "सिंह" }, { value: "कन्या", label: "कन्या" },
            { value: "तुला", label: "तुला" }, { value: "वृश्चिक", label: "वृश्चिक" },
            { value: "धनु", label: "धनु" }, { value: "मकर", label: "मकर" },
            { value: "कुम्भ", label: "कुम्भ" }, { value: "मीन", label: "मीन" }
        ],
        ganValueOptions: [
            { value: "देव", label: "देव" }, { value: "मनुष्य ", label: "मनुष्य " },
            { value: "राक्षस", label: "राक्षस" }
        ],
        naadiValueOptions: [
            { value: "आद्य", label: "आद्य" }, { value: "मध्य", label: "मध्य" },
            { value: "अंत्य", label: "अंत्य" }
        ],
        optionalFieldsInfoText : "आप निम्नलिखित शीर्षक को खाली छोड़ सकते हैं या अपनी आवश्यकता के अनुसार इसे बदल सकते हैं और अपनी इच्छानुसार कोई भी शीर्षक दे सकते हैं।",
        gotraValueOptions: [
            { value: "कश्यप", label: "कश्यप" }, { value: "भारद्वाज", label: "भारद्वाज" },
            { value: "वसिष्ठ", label: "वसिष्ठ" }, { value: "विश्वामित्र", label: "विश्वामित्र" },
            { value: "गौतम", label: "गौतम" }, { value: "अंगिरा", label: "अंगिरा" },
            { value: "अत्रि", label: "अत्रि" }, { value: "क्रतु", label: "क्रतु" },
            { value: "जमदग्नि", label: "जमदग्नि" }, { value: "पुलस्ति", label: "पुलस्ति" },
            { value: "पुलह", label: "पुलह" }, { value: "अगस्त्य", label: "अगस्त्य" },
            { value: "कौण्डिन्य", label: "कौण्डिन्य" }, { value: "कौशिक", label: "कौशिक" },
            { value: "गार्ग्य", label: "गार्ग्य" }, { value: "दुर्वासा", label: "दुर्वासा" },
            { value: "परीख", label: "परीख" }, { value: "भार्गव", label: "भार्गव" },
            { value: "भृगु", label: "भृगु" }, { value: "शांडिल्य", label: "शांडिल्य" },
            { value: "सुपर्णस्य", label: "सुपर्णस्य" },
            { value: "सूची में नहीं होने पर प्रकार", label: "सूची में नहीं होने पर प्रकार" }
        ],
        nakshatraValueOptions: [
            { value: "अनुराधा", label: "अनुराधा" }, { value: "अश्विनी", label: "अश्विनी" },
            { value: "आर्द्रा", label: "आर्द्रा" }, { value: "अश्लेषा", label: "अश्लेषा" },
            { value: "उत्तरा फाल्गुनी", label: "उत्तरा फाल्गुनी" }, { value: "उत्तरा भाद्रपदा", label: "उत्तरा भाद्रपदा" },
            { value: "उत्तराषाढ़ा", label: "उत्तराषाढ़ा" }, { value: "कृत्तिका", label: "कृत्तिका" },
            { value: "चित्रा", label: "चित्रा" }, { value: "ज्येष्ठा", label: "ज्येष्ठा" },
            { value: "धनिष्ठा", label: "धनिष्ठा" }, { value: "पुनर्वसु", label: "पुनर्वसु" },
            { value: "पुष्य", label: "पुष्य" }, { value: "पूर्व फाल्गुनी", label: "पूर्व फाल्गुनी" },
            { value: "पूर्व भाद्रपदा", label: "पूर्व भाद्रपदा" }, { value: "पूर्व आषाढ़ा", label: "पूर्व आषाढ़ा" },
            { value: "भरणी", label: "भरणी" }, { value: "मघा", label: "मघा" },
            { value: "मूल", label: "मूल" }, { value: "मृगशीर्षा", label: "मृगशीर्षा" },
            { value: "रेवती", label: "रेवती" }, { value: "रोहिणी", label: "रोहिणी" },
            { value: "विशाखा", label: "विशाखा" }, { value: "शतभिषा", label: "शतभिषा" },
            { value: "श्रवण", label: "श्रवण" }, { value: "स्वाति", label: "स्वाति" },
            { value: "हस्त", label: "हस्त" }
        ],
        additionalKeyPlaceHolder: 'यहाँ अतिरिक्त शीर्षक जोड़ा जा सकता है।',
    },
    educationAndIncome: {
        qualificationValuePlaceHolder: 'शिक्षा',
        workValuePlaceHolder: '(वैकल्पिक) कंपनी या व्यवसाय का नाम, पद',
        earningValuePlaceHolder: '(वैकल्पिक) वेतन/आय',
        additionalKeyPlaceHolder: 'यहाँ अतिरिक्त शीर्षक जोड़ा जा सकता है।',
    },
    familyDetails: {
        familyDetailHeadingOptions: [
            { value: 'परिवार का विवरण', label: 'परिवार का विवरण' },
            { value: "परिवारिक जानकारी", label: "परिवारिक जानकारी" },
            { value: "परिवार की पृष्ठभूमि", label: "परिवार की पृष्ठभूमि" }
        ],
        fatherNameValuePlaceHolder: 'पिता का पूरा नाम',
        fatherOccupationValuePlaceHolder: 'पिता का व्यावसाय या नौकरी',
        motherNameValuePlaceHolder: 'माँ का पूरा नाम',
        noSisterValuePlaceHolder: '1 बहन (विवाहित)',
        noBrotherValuePlaceHolder: '1 भाई (अविवाहित)',
        maternalUncleValuePlaceHolder: 'मामा का नाम',
        relativesNamesValuePlaceHolder: 'रिश्तेदारों के उपनाम',
        additionalKeyPlaceHolder: 'यहाँ अतिरिक्त शीर्षक जोड़ा जा सकता है।'
    },
    contactDetails: {
        contactHeadingOptions: [
            { value: 'संपर्क विवरण', label: 'संपर्क विवरण' },
            { value: "संपर्क सूचना", label: "संपर्क सूचना" }
        ],
        addressValuePlaceHolder: 'पता लाइन 1',
        addressLine2ValuePlaceHolder: '(वैकल्पिक) पता लाइन 2',
        mobileNoValuePlaceHolder: 'संपर्क के लिए मोबाइल नंबर',
        additionalKeyPlaceHolder: 'यहाँ अतिरिक्त शीर्षक जोड़ा जा सकता है।'
    },
    userPhoto: {
        userPhotoHeading: "फोटो",
        photoInfoText: "फोटो बायोडाटा पर तभी दिखाई देगा जब फोटो नीचे दिए गए बटन का उपयोग करके अपलोड किया गया हो। नहीं तो बायोडाटा आपकी फोटो के बिना ही बन जाएगा।",
        photoLoadingText: "हाई क्वालिटी फोटो रूपांतरित किया जा रहा है..."
    },
    errorMessage: 'सभी आवश्यक फ़ील्ड्स भरें या उनकी शर्तें पूरी करें।'
}       