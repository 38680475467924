import { NavBarNew, Footer } from '../components';
import { LinkView } from '../BiodataPreview';


function LinkViewer() {
  return (
    <div className='PreviewBiodata'>
      <NavBarNew />
      <LinkView />
      <Footer />
    </div>
  );
}

export default LinkViewer;
