import React, { useRef } from 'react';
import "./styles/blogs.css";

import { BlogsList } from '../Blogs';

function BlogsComponent() {
    const targetRef = useRef();

    const handleScroll = () => {
        if (targetRef.current) {
        targetRef.current.scrollTo();
        }
    };

    return (
        <div className="components-wrapper">
            <section className="blogs-page-component-section">
                <div className="container">
                    <div className="component-row gap">
                        <div className="textImgCol col">
                            <h1 className="page-title">Blogs</h1>
                            <h2 className="blog-heading-description"> Knowledge for the Curious Mind 🧠
                            </h2>
                            <p className="blogs-para">
                                Discover our insightful blogs on creating the perfect marriage biodata. 
                                Learn step-by-step guides and tips on crafting impressive biodata profiles.
                                Explore our user-friendly biodata making tool that effortlessly generates 
                                professional PDF biodata, ensuring you present yourself in the best light. 
                                Your journey to a perfect match starts here!
                            </p>
                            <button
                                className="xplore-blogs xplore-blogs-style"
                                onClick={handleScroll}
                            >
                                Explore Blogs
                            </button>
                        </div>
                        <div className="textImgCol col">
                            <div className="blogs-clip-art">
                                <picture>
                                    <img decoding="async" loading="lazy"
                                        src="blogs.png"
                                        className="blogs-clip-art"
                                        alt="biodata couple"
                                    />
                                </picture>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <BlogsList ref={targetRef}/>
        </div>
    );
}

export default BlogsComponent;